import './MapBar.svelte.css';
/* src/MapBar.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data,
	set_store_value,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	map,
	selections,
	radarMetadata,
	scans,
	DEALIAS_STATE,
	INT_STATE,
	SPLIT_STATE,
	MAP_STATE
} from './store.js';

import Colorbar from './Colorbar.svelte.js';
import { UpdateData } from './UpdateClass.js';
import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import { MAP_OBJECT, DEALIAS_OBJECT, INT_OBJECT, DOUBLE } from './enums.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[34] = list[i];
	return child_ctx;
}

// (160:16) {#each options as option}
function create_each_block(ctx) {
	let option;
	let t0_value = /*option*/ ctx[34].text + "";
	let t0;
	let t1;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t0 = text(t0_value);
			t1 = space();
			option.__value = option_value_value = /*option*/ ctx[34].value;
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t0);
			append(option, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*options*/ 64 && t0_value !== (t0_value = /*option*/ ctx[34].text + "")) set_data(t0, t0_value);

			if (dirty[0] & /*options*/ 64 && option_value_value !== (option_value_value = /*option*/ ctx[34].value)) {
				option.__value = option_value_value;
				option.value = option.__value;
			}
		},
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

// (171:8) {#if $SPLIT_STATE === 'horizontal' || $MAP_STATE != DOUBLE}
function create_if_block_2(ctx) {
	let div;
	let if_block = /*height*/ ctx[2] && /*over*/ ctx[3] && /*$INT_STATE*/ ctx[9] === /*READING*/ ctx[10] && create_if_block_3(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "right svelte-pmj2im");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, dirty) {
			if (/*height*/ ctx[2] && /*over*/ ctx[3] && /*$INT_STATE*/ ctx[9] === /*READING*/ ctx[10]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (173:16) {#if height && over && $INT_STATE === READING}
function create_if_block_3(ctx) {
	let table;
	let tbody;
	let tr0;
	let t3;
	let tr1;
	let td0;

	let t4_value = (/*height*/ ctx[2]
	? ' ' + /*height*/ ctx[2].toFixed(1) + ' km'
	: '') + "";

	let t4;
	let t5;
	let td1;

	let t6_value = (/*mouseoverValue*/ ctx[1] === undefined || isNaN(/*mouseoverValue*/ ctx[1])
	? ''
	: `${/*mouseoverValue*/ ctx[1]} ${/*selected*/ ctx[4] > -1
		? /*units*/ ctx[11][/*selected*/ ctx[4]]
		: ''}`) + "";

	let t6;

	return {
		c() {
			table = element("table");
			tbody = element("tbody");
			tr0 = element("tr");

			tr0.innerHTML = `<th class="file svelte-pmj2im">Beam Height</th> 
                                <th class="vcp svelte-pmj2im">Gate Value</th>`;

			t3 = space();
			tr1 = element("tr");
			td0 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td1 = element("td");
			t6 = text(t6_value);
			attr(tr0, "class", "svelte-pmj2im");
			attr(td0, "class", "content file svelte-pmj2im");
			attr(td1, "class", "content vcp svelte-pmj2im");
			attr(tr1, "class", "svelte-pmj2im");
			attr(table, "class", "svelte-pmj2im");
		},
		m(target, anchor) {
			insert(target, table, anchor);
			append(table, tbody);
			append(tbody, tr0);
			append(tbody, t3);
			append(tbody, tr1);
			append(tr1, td0);
			append(td0, t4);
			append(tr1, t5);
			append(tr1, td1);
			append(td1, t6);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*height*/ 4 && t4_value !== (t4_value = (/*height*/ ctx[2]
			? ' ' + /*height*/ ctx[2].toFixed(1) + ' km'
			: '') + "")) set_data(t4, t4_value);

			if (dirty[0] & /*mouseoverValue, selected*/ 18 && t6_value !== (t6_value = (/*mouseoverValue*/ ctx[1] === undefined || isNaN(/*mouseoverValue*/ ctx[1])
			? ''
			: `${/*mouseoverValue*/ ctx[1]} ${/*selected*/ ctx[4] > -1
				? /*units*/ ctx[11][/*selected*/ ctx[4]]
				: ''}`) + "")) set_data(t6, t6_value);
		},
		d(detaching) {
			if (detaching) detach(table);
		}
	};
}

// (196:8) {#if $SPLIT_STATE === 'vertical' && $MAP_STATE === DOUBLE}
function create_if_block(ctx) {
	let div;
	let div_class_value;
	let if_block = /*height*/ ctx[2] && /*over*/ ctx[3] && /*$INT_STATE*/ ctx[9] === /*READING*/ ctx[10] && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();

			attr(div, "class", div_class_value = "right " + (/*$SPLIT_STATE*/ ctx[7] === 'horizontal'
			? ''
			: 'right-vertical') + " svelte-pmj2im");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, dirty) {
			if (/*height*/ ctx[2] && /*over*/ ctx[3] && /*$INT_STATE*/ ctx[9] === /*READING*/ ctx[10]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*$SPLIT_STATE*/ 128 && div_class_value !== (div_class_value = "right " + (/*$SPLIT_STATE*/ ctx[7] === 'horizontal'
			? ''
			: 'right-vertical') + " svelte-pmj2im")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (198:16) {#if height && over && $INT_STATE === READING}
function create_if_block_1(ctx) {
	let div;
	let table;
	let tbody;
	let tr0;
	let t3;
	let tr1;
	let td0;

	let t4_value = (/*height*/ ctx[2]
	? ' ' + /*height*/ ctx[2].toFixed(1) + ' km'
	: '') + "";

	let t4;
	let t5;
	let td1;

	let t6_value = (/*mouseoverValue*/ ctx[1] === undefined || isNaN(/*mouseoverValue*/ ctx[1])
	? ''
	: `${/*mouseoverValue*/ ctx[1]} ${/*selected*/ ctx[4] > -1
		? /*units*/ ctx[11][/*selected*/ ctx[4]]
		: ''}`) + "";

	let t6;

	return {
		c() {
			div = element("div");
			table = element("table");
			tbody = element("tbody");
			tr0 = element("tr");

			tr0.innerHTML = `<th class="file svelte-pmj2im">Beam Height</th> 
                                <th class="vcp svelte-pmj2im">Gate Value</th>`;

			t3 = space();
			tr1 = element("tr");
			td0 = element("td");
			t4 = text(t4_value);
			t5 = space();
			td1 = element("td");
			t6 = text(t6_value);
			attr(tr0, "class", "svelte-pmj2im");
			attr(td0, "class", "content file svelte-pmj2im");
			attr(td1, "class", "content vcp svelte-pmj2im");
			attr(tr1, "class", "svelte-pmj2im");
			attr(table, "class", "svelte-pmj2im");
			attr(div, "class", "table-padding svelte-pmj2im");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, table);
			append(table, tbody);
			append(tbody, tr0);
			append(tbody, t3);
			append(tbody, tr1);
			append(tr1, td0);
			append(td0, t4);
			append(tr1, t5);
			append(tr1, td1);
			append(td1, t6);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*height*/ 4 && t4_value !== (t4_value = (/*height*/ ctx[2]
			? ' ' + /*height*/ ctx[2].toFixed(1) + ' km'
			: '') + "")) set_data(t4, t4_value);

			if (dirty[0] & /*mouseoverValue, selected*/ 18 && t6_value !== (t6_value = (/*mouseoverValue*/ ctx[1] === undefined || isNaN(/*mouseoverValue*/ ctx[1])
			? ''
			: `${/*mouseoverValue*/ ctx[1]} ${/*selected*/ ctx[4] > -1
				? /*units*/ ctx[11][/*selected*/ ctx[4]]
				: ''}`) + "")) set_data(t6, t6_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div4;
	let div2;
	let div1;
	let select;
	let t0;
	let div0;
	let p0;

	let t1_value = (/*exacts*/ ctx[5]
	? `${/*exacts*/ ctx[5].station} ${/*exacts*/ ctx[5].elevation}° elevation`
	: '') + "";

	let t1;
	let t2;
	let p1;

	let t3_value = (/*exacts*/ ctx[5]
	? /*exacts*/ ctx[5].date.toUTCString().replace('GMT', 'UTC')
	: '') + "";

	let t3;
	let t4;
	let t5;
	let div3;
	let colorbar;
	let t6;
	let current;
	let mounted;
	let dispose;
	let each_value = /*options*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = (/*$SPLIT_STATE*/ ctx[7] === 'horizontal' || /*$MAP_STATE*/ ctx[8] != DOUBLE) && create_if_block_2(ctx);
	colorbar = new Colorbar({ props: { field: /*field*/ ctx[0] } });
	let if_block1 = /*$SPLIT_STATE*/ ctx[7] === 'vertical' && /*$MAP_STATE*/ ctx[8] === DOUBLE && create_if_block(ctx);

	return {
		c() {
			div4 = element("div");
			div2 = element("div");
			div1 = element("div");
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			div0 = element("div");
			p0 = element("p");
			t1 = text(t1_value);
			t2 = space();
			p1 = element("p");
			t3 = text(t3_value);
			t4 = space();
			if (if_block0) if_block0.c();
			t5 = space();
			div3 = element("div");
			create_component(colorbar.$$.fragment);
			t6 = space();
			if (if_block1) if_block1.c();
			attr(select, "class", "s svelte-pmj2im");
			if (/*selected*/ ctx[4] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[15].call(select));
			attr(p0, "class", "metap svelte-pmj2im");
			attr(p1, "class", "metap svelte-pmj2im");
			attr(div0, "class", "meta svelte-pmj2im");
			attr(div1, "class", "left svelte-pmj2im");
			attr(div2, "class", "barContainer svelte-pmj2im");
			attr(div3, "class", "colorbar svelte-pmj2im");
			attr(div4, "class", "main-container svelte-pmj2im");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div2);
			append(div2, div1);
			append(div1, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*selected*/ ctx[4]);
			append(div1, t0);
			append(div1, div0);
			append(div0, p0);
			append(p0, t1);
			append(div0, t2);
			append(div0, p1);
			append(p1, t3);
			append(div2, t4);
			if (if_block0) if_block0.m(div2, null);
			append(div4, t5);
			append(div4, div3);
			mount_component(colorbar, div3, null);
			append(div3, t6);
			if (if_block1) if_block1.m(div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(select, "change", /*select_change_handler*/ ctx[15]),
					listen(select, "change", /*handleChange*/ ctx[12])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*options*/ 64) {
				each_value = /*options*/ ctx[6];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty[0] & /*selected, options*/ 80) {
				select_option(select, /*selected*/ ctx[4]);
			}

			if ((!current || dirty[0] & /*exacts*/ 32) && t1_value !== (t1_value = (/*exacts*/ ctx[5]
			? `${/*exacts*/ ctx[5].station} ${/*exacts*/ ctx[5].elevation}° elevation`
			: '') + "")) set_data(t1, t1_value);

			if ((!current || dirty[0] & /*exacts*/ 32) && t3_value !== (t3_value = (/*exacts*/ ctx[5]
			? /*exacts*/ ctx[5].date.toUTCString().replace('GMT', 'UTC')
			: '') + "")) set_data(t3, t3_value);

			if (/*$SPLIT_STATE*/ ctx[7] === 'horizontal' || /*$MAP_STATE*/ ctx[8] != DOUBLE) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div2, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			const colorbar_changes = {};
			if (dirty[0] & /*field*/ 1) colorbar_changes.field = /*field*/ ctx[0];
			colorbar.$set(colorbar_changes);

			if (/*$SPLIT_STATE*/ ctx[7] === 'vertical' && /*$MAP_STATE*/ ctx[8] === DOUBLE) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div3, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(colorbar.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(colorbar.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div4);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			destroy_component(colorbar);
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $selections;
	let $map;
	let $DEALIAS_STATE;
	let $SPLIT_STATE;
	let $MAP_STATE;
	let $INT_STATE;
	component_subscribe($$self, selections, $$value => $$invalidate(14, $selections = $$value));
	component_subscribe($$self, map, $$value => $$invalidate(16, $map = $$value));
	component_subscribe($$self, DEALIAS_STATE, $$value => $$invalidate(17, $DEALIAS_STATE = $$value));
	component_subscribe($$self, SPLIT_STATE, $$value => $$invalidate(7, $SPLIT_STATE = $$value));
	component_subscribe($$self, MAP_STATE, $$value => $$invalidate(8, $MAP_STATE = $$value));
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(9, $INT_STATE = $$value));
	const { DEALIASED, RAW } = DEALIAS_OBJECT;
	const { READING } = INT_OBJECT;

	//Enums for map fields
	const { REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED, CC, PHI, ZDR, SW } = MAP_OBJECT;

	const updateInstance = UpdateData();
	const dispatch = createEventDispatcher();
	let exacts;
	let { mouseoverValue } = $$props;
	let { field } = $$props;
	let { idx } = $$props;
	let { height } = $$props;
	let { over } = $$props;

	//export let lat;
	//export let lon;
	//start with selected of -1
	let selected, options;

	if ($selections[idx] && $selections[idx].type) {
		selected = $selections[idx].type;
	} else {
		selected = -1;
		options = [{ value: -1, text: "No Scan Selected" }];
	}

	const units = {
		0: 'dBZ',
		1: 'kt',
		9: '',
		10: 'deg km-1',
		5: 'dB',
		2: 'kt'
	};

	const valid = [0, 1, 9, 10, 2, 5];

	const maps = {
		0: "Reflectivity",
		1: "Velocity",
		2: "Spectrum Width",
		5: "Differential Reflectivity",
		9: "Correlation Coefficient",
		10: "Specific Differential Phase"
	};

	const SymbolMap = {
		0: REFLECTIVITY,
		1: VELOCITY,
		9: CC,
		10: PHI,
		5: ZDR,
		2: SW
	};

	function handleChange(e) {
		if (selected === 1 && $DEALIAS_STATE === DEALIASED) {
			$$invalidate(0, field = VELOCITY_DEALIASED);
		} else {
			$$invalidate(0, field = SymbolMap[selected]);
		}

		//dispatch event to change field in Map component
		dispatch('message', { value: "setField", idx, field });

		set_store_value(map, $map[idx].field = field, $map);
		updateInstance.logStore($selections[idx].scan);
	}

	function handleSelections() {
		if (!$selections[idx] || !$selections[idx].scan) return;
		const scanArray = $selections[idx].scan.scan;
		let allOptions = [];

		for (let i = 0; i < scanArray.length; i++) {
			const subScan = scanArray[i].fields;

			for (let j = 0; j < subScan.length; j++) {
				allOptions.push(subScan[j]);
			}
		}

		const scanSet = new Set(allOptions);

		const optionsNums = Array.from(scanSet).sort(function (a, b) {
			return a - b;
		});

		$$invalidate(6, options = optionsNums.map(x => {
			return { value: x, text: maps[x] };
		}).filter(x => valid.indexOf(x.value) >= 0));

		if ($map[idx] === undefined) return;

		if ($map[idx].field === VELOCITY_DEALIASED || $map[idx].field === VELOCITY) {
			$$invalidate(4, selected = 1);
		} else {
			$$invalidate(4, selected = $selections[idx].type);
		}
	}

	function exactInfo(selected, util) {
		if ($selections[idx] === undefined || $selections[idx].scan === null) return;
		let date, elevation;
		const scan = $selections[idx].scan.scan;

		const station = $selections[idx].scan.station
		? $selections[idx].scan.station
		: '';

		for (let i = 0; i < scan.length; i++) {
			if (scan[i].fields.indexOf(selected) > -1) {
				date = scan[i].date;
				elevation = scan[i].elevation.toFixed(1);
				break;
			}
		}

		if (date) {
			return { date, elevation, station };
		} else {
			return undefined;
		}
	}

	function select_change_handler() {
		selected = select_value(this);
		$$invalidate(4, selected);
		$$invalidate(6, options);
	}

	$$self.$$set = $$props => {
		if ('mouseoverValue' in $$props) $$invalidate(1, mouseoverValue = $$props.mouseoverValue);
		if ('field' in $$props) $$invalidate(0, field = $$props.field);
		if ('idx' in $$props) $$invalidate(13, idx = $$props.idx);
		if ('height' in $$props) $$invalidate(2, height = $$props.height);
		if ('over' in $$props) $$invalidate(3, over = $$props.over);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$selections*/ 16384) {
			$: ($selections, handleSelections());
		}

		if ($$self.$$.dirty[0] & /*selected, $selections*/ 16400) {
			$: $$invalidate(5, exacts = exactInfo(selected, $selections));
		}
	};

	return [
		field,
		mouseoverValue,
		height,
		over,
		selected,
		exacts,
		options,
		$SPLIT_STATE,
		$MAP_STATE,
		$INT_STATE,
		READING,
		units,
		handleChange,
		idx,
		$selections,
		select_change_handler
	];
}

class MapBar extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance,
			create_fragment,
			safe_not_equal,
			{
				mouseoverValue: 1,
				field: 0,
				idx: 13,
				height: 2,
				over: 3
			},
			null,
			[-1, -1]
		);
	}
}

export default MapBar;