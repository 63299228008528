import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

//module to return worker
//could be singleton because only want one ever
import { get } from '../_snowpack/pkg/svelte/store.js';

function makeWorker() {
    const worker = new Worker(new URL('./worker.js', import.meta.url));
    const setStore = function(scans, vertexData, radarMetadata, selections, texture_3d, sites, error, CLEAR, socket, queue) {
        worker.onmessage = function(e) {
    
            const action = e.data.action;
            const payload = e.data.data;
            //console.log("actino", action)
            if (action === "error") {
                error.update(d => {
                    d.error = true;
                    d.message = payload.message;
                    d.header = payload.header;
                    return d;
                })
            }

            if (action === "noop") {
                socket.handleQueue({
                    "message":"noop",
                    "fileName":payload.fileName
                })
            }

            if (action === "initialize") {
                scans.update(d=> {
                    d.loading = payload.file;
                    d.finished = 0;
                    d[payload.file] = {
                        fileName: payload.fileName,
                        fileNumber:payload.file
                    }
                    return d;
                })

                selections.update(d=> {
                    for (let i=0; i<d.length; i++) {
                        if (d[i].file === null) {
                            d[i].file = payload.file;
                        }
                    }
                    return d;
                })

               
            }

            if (action === "update") {
                
                scans.update(d=> {
                    d.loading = payload.file;
                    d.finished = 0;
                    if (!d[payload.file]) return d;
                    Object.assign(d[payload.file], {station:payload.station, scans:[], idxs:{}, percent:0, vcp:payload.vcp, radarMetadata:{}});
                    return d;
                })
            }

            if (action === "archive2") {
                scans.update(d=> {
                    if (!d[payload.file]) return d;
                    d[payload.file].vcp = payload.vcp;
                    return d;
                })
            }

            //set progress bar back to 0;
            if (action ==="contentFinished") {
                scans.update(d=>{
                    d.loading = -1;
                    d.finished = 1;
                    if (!d[payload.file]) return d;
                    d[payload.file].percent = -1;
                    return d;
                })

                //let it know we have information to delete a file
                socket.handleQueue({
                    "message":"initialize",
                    "fileNumber": payload.file,
                    "fileName":payload.fileName
                })
            }

            if (action === "metadata") {
                //for file view
                scans.update(d=> {
                    d.loading = payload.file;
                    d.finished = 0;
                    if (!d[payload.file]) return d;
                    const fixed = payload.elevation.toFixed(1);
                    //elevation has not been processed yet
                    if (!(fixed in d[payload.file].idxs)) {
                        //add index to object
                        d[payload.file].idxs[fixed] = d[payload.file].scans.length;
                        //create array of objects
                        d[payload.file].scans.push([
                            [{
                                elevation:payload.elevation,
                                idx:payload.idx,
                                date:payload.date,
                                percent:payload.percent,
                                fields:payload.fields,
                                fileNumber:payload.file,
                                rounded:fixed,
                                fileName:payload.fileName
                            }]
                        ])
                    } else { //need to push into existing array
                        //existing array
                        const array = d[payload.file].scans[d[payload.file].idxs[fixed]];
                        //check if should be pushed into existing subarray
                        for (let i=0; i<array.length; i++) {
                            const subarray = array[i];
                            for (let j=0; j<subarray.length; j++) {
                                if (payload.idx - subarray[j].idx === 1) {
                                    subarray.push({
                                        elevation:payload.elevation,
                                        idx:payload.idx,
                                        date:payload.date,
                                        percent:payload.percent,
                                        fields:payload.fields,
                                        fileNumber:payload.file,
                                        rounded:fixed,
                                        fileName:payload.fileName
                                    })
                                    d[payload.file].percent = Math.max(d[payload.file].percent, payload.percent);
                                    return d;
                                }
                            } 
                        }
                        d[payload.file].scans[d[payload.file].idxs[fixed]].push([{
                            elevation:payload.elevation,
                            idx:payload.idx,
                            date:payload.date,
                            percent:payload.percent,
                            fields:payload.fields,
                            fileNumber:payload.file,
                            rounded:fixed,
                            fileName:payload.fileName
                        }])
                    }

                    d[payload.file].percent = Math.max(d[payload.file].percent, payload.percent);
                    
                    return d;
                }
                )

                //for elevation view
                // sites.update(d=> {
                //     const localScans = get(scans);
                    
                //     Object.assign(d, {fileNames:[], elevations:[], stations:[], scans:[]});
                //     d.fileNames = [];
                //     d.stations = [];
                //     d.scans = {};
                //     d.elevations = {};
                //     const siteSets = {};
                //     const scanArrays = {};

                //     //loop through scans (files)
                //     for (let i=0; i<localScans.length; i++) {
                //         //only process those that are defined
                //         if (localScans[i] != undefined) {
                //             //want all filenames, they're unique (or you'd think)
                //             d.fileNames.push(localScans[i].fileName);

                //             //only want to save unique stations
                //             if (d.stations.indexOf(localScans[i].station) < 0) d.stations.push(localScans[i].station);

                //             //elevations (keys for later)
                //             if (localScans[i].station in siteSets) {
                //                 //concatenate if already exists
                //                 siteSets[localScans[i].station] = siteSets[localScans[i].station].concat(Object.keys(localScans[i].idxs));
                //             } else {
                //                 siteSets[localScans[i].station] = Object.keys(localScans[i].idxs);
                //             }

                //             //scans
                //             if (localScans[i].station in scanArrays) {
                //                 scanArrays[localScans[i].station] = scanArrays[localScans[i].station].concat(localScans[i].scans);
                //             } else {
                //                 scanArrays[localScans[i].station] = localScans[i].scans;
                //             }
                //         }
                //     }

                //     //loop through each station
                //     for (let i=0; i<d.stations.length; i++) {
                //         const key = d.stations[i];

                //         //reset all properties
                //         d.elevations[key] = [];
                //         d.scans[key] = {};

                //         //get unique elevations sorted ascending
                //         d.elevations[key] = Array.from(new Set(siteSets[key])).sort(function(a,b) {
                //             return (+a)-(+b);
                //         });

                //         const allScans = scanArrays[key];
                //         //initialize object with all keys necessary
                //         for (let j=0; j<d.elevations[key].length; j++) {
                //             d.scans[key][d.elevations[key][j]] = [];
                //         }

                //         //loop through scan array
                //         for (let j=0; j<allScans.length; j++) {
                //             d.scans[key][allScans[j][0][0].rounded].push(...allScans[j]);
                //         }

                //         //final loop to sort each elevation by time
                //         for (let k in d.scans[key]) {
                //             d.scans[key][k] = d.scans[key][k].sort(function(a,b) {
                //                 return a[0].date - b[0].date;
                //             })
                //         }
                //     }
                    
                //     return d;
                // })

                //upate radar metadata on first scan
                if (payload.idx === 0) {
                    if (!get(scans)[payload.file]) return;
                    if (get(radarMetadata).station != get(scans)[payload.file].station) {
                        radarMetadata.update(d=>{
                            //if (!get(scans)[payload.file]) return;
                            return {
                                "lat":payload.lat,
                                "lon":payload.lon,
                                "station":get(scans)[payload.file].station
                            }
                        })
                    }
                    
                    //need to copy metadata to sites and scans
                    // sites.update(d=> {
                    //     d[payload.file].radarMetadata = {
                    //         "lat":payload.lat,
                    //         "lon":payload.lon
                    //     }
                    // })

                    scans.update(d=> {
                        if (!d[payload.file]) return d;
                        d[payload.file].radarMetadata = {
                            "lat":payload.lat,
                            "lon":payload.lon,
                            "station":get(scans)[payload.file].station
                        }
                        return d;
                    })

                }
            }

            if (action === "loadData") {

                const localScans = get(scans);

                const fileNames = localScans.filter(d => d != undefined).map(d => d.fileName);

                //file no longer exists, so don't update vertexData with the data
                if (fileNames.indexOf(payload.fileName) < 0) {
                    //delete and return
                    const key = `${payload.fileName}_${payload.type}_${payload.elevation}`;
                    if (queue.deleteItem(key) === 0) queue.dequeue();
                    return;
                }
               
                vertexData.update(d=> {
                    //some data has already been generated
                    if (d[payload.file]) {
                        d[payload.file][payload.type][payload.elevation] = payload.float;
                    } else {
                        d[payload.file] = {
                            0:Array.apply(null, Array(30)).map(function () {}), //ref
                            1:Array.apply(null, Array(30)).map(function () {}), //raw velocity
                            255:Array.apply(null, Array(30)).map(function () {}),  //dealiased velocity
                            9:Array.apply(null, Array(30)).map(function () {}), //cc
                            10:Array.apply(null, Array(30)).map(function () {}), //phi
                            5:Array.apply(null, Array(30)).map(function () {}), //zdr
                            2:Array.apply(null, Array(30)).map(function () {}) //sw
                        }
                        d[payload.file][payload.type][payload.elevation] = payload.float;
                    }

                   
                   
                    return d;
                })

                const key = `${payload.fileName}_${payload.type}_${payload.elevation}`;
                
                if (queue.deleteItem(key) === 0) queue.dequeue();
                
            }

            if (action === "didNotExist") {
                const key = `${payload.fileName}_${payload.type}_${payload.elevation}`;
                if (queue.deleteItem(key) === 0) queue.dequeue();
            }

            if (action === "loadTexture") {

                const localScans = get(scans);

                const fileNames = localScans.filter(d => d != undefined).map(d => d.fileName);
                
                //file no longer exists, so don't update vertexData with the data
                if (fileNames.indexOf(payload.fileName) < 0) {
                    //delete and return
                    const key = `${payload.fileName}_${payload.type}_${payload.elevation}`;

                    if (queue.deleteItem(key) === 0) queue.dequeue();
                    return;
                }

                texture_3d.update(d=>{
                    const toUpdate = {};
                   
                    toUpdate["float"] = payload.float;
                    toUpdate["size"] = payload.size;
                    toUpdate["scale"] = payload.scale;
                    toUpdate["offset"] = payload.offset;
                    toUpdate["bin1"] = payload.bin1;
                    toUpdate["binMax"] = payload.binMax;
                    toUpdate["ngates"] = payload.ngates/(1000./payload.gate_size);
                    

                    d[payload.fileNum] = toUpdate;

                    return d;
                })

                const key = `${payload.fileName}_${payload.type}_${payload.elevation}`;
                
                if (queue.deleteItem(key) === 0) queue.dequeue();
            }

            //delete
            if (action === "clearMap") {
                socket.handleQueue({
                    "message":"deleted",
                    "fileNumber": payload.fileNum,
                    "fileName":payload.fileName
                })
                //clear if currently selected is now undefined
                if (get(scans)[get(selections)[0].file] === undefined) CLEAR.update(d=>true);
            }

            if (action === "try_again") {
                socket.handleQueue({
                    "message":"try_again",
                    //"fileNumber": payload.file,
                    "fileName":payload.fileName,
                    "buffer":e.data.buffer
                })
            }
        }
    }
    return {
        worker:worker,
        setOnMessage:setStore
    }
}

export default makeWorker;