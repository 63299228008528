import { get } from '../_snowpack/pkg/svelte/store.js';
import { selections, vertexData, radarMetadata, scans, map, MAP_STATE, INT_STATE, texture_3d, LOADING_3D, currentFile, queue, socket, DEALIAS_STATE } from './store.js';
import { MAP_OBJECT, DOUBLE, INT_OBJECT, DEALIAS_OBJECT } from './enums.js';


function UpdateData(f=null) {

    const toUpdate = f ? f.f : function() {
        return;
    };

    const {REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED, CC, PHI, ZDR, SW} = MAP_OBJECT;

    const idMap = {
        [REFLECTIVITY]:0,
        [VELOCITY]:1,
        [VELOCITY_DEALIASED]:255,
        [CC]:9,
        [PHI]:10,
        [ZDR]:5,
        [SW]:2
    };

    const {READING, MODE3D, CROSS} = INT_OBJECT;

    const {DEALIASED} = DEALIAS_OBJECT;

    function returnSelection(elevation, file, type, scan) {
        return {
            elevation:elevation,
            file:file,
            type:type,
            dealiased:0,
            scan: scan
        };
    }

    function loadTexture(file, fileName) {
        if (get(texture_3d)[file] === undefined) {

            const status = queue.enqueue({
                fileNum:file,
                field:254,
                fileName:fileName,
                idx:255,
                selections:get(selections),
                scans:get(scans)
            })

            // get(worker).postMessage({
            //     message:"set3d",
            //     data:{
            //         selections:get(selections),
            //         scans:get(scans)
            //     }
            // })

            LOADING_3D.update(d=>{
                return true;
            });
        }
    }

    function loadData(field, elevation, file, idx, fileName) {
        //console.log("loading", field, elevation, file, idx, fileName);
       
        if (get(vertexData)[file] && get(vertexData)[file][field][elevation]) {
            return;
        }

        let existed;
        const scan = get(selections)[idx].scan.scan;

        let matchField;

        if (field === 255) {
            matchField = 1;
        } else {
            matchField = field;
        }
      
        for (let i=0; i<scan.length; i++) {
            if (scan[i].fields.indexOf(matchField) > -1) {
              
                existed = true;
                break;
            }
        }

        //need to check that field exists before starting job
        if (existed) {
            const status = queue.enqueue({
                fileNum:file,
                idx:elevation,
                field:field,
                fileName:fileName
            })
        } else {
            if (get(vertexData)[file]) get(vertexData)[file][field][elevation] = new Float32Array([]);
        }
        

        // if (field === 255) {
        //     get(worker).postMessage({
        //         message:"dealiasVelocity",
        //         data:{
        //             fileNum:file,
        //             idx:elevation,
        //             field:field
        //         }
        //     })
        // } 
        // else if (field == 1) {
        //     get(worker).postMessage({
        //         message:"loadData",
        //         data:{
        //             fileNum:file,
        //             idx:elevation,
        //             field:field
        //         }
        //     })
        // }
        // else {
        //     get(worker).postMessage({
        //         message:"loadData",
        //         data:{
        //             fileNum:file,
        //             idx:elevation,
        //             field:field
        //         }
        //     })
        // }

        get(map)[idx].setLoading();
        
    }

    function getElevation(arrays, toLoad) {
        let elevation;
        //for elevation purposes, looking for 1 when dealiased
        if (toLoad === 255) toLoad = 1;
        for (let i=0; i<arrays.length; i++) {
            if (arrays[i].fields.indexOf(toLoad) > -1) {
                elevation = arrays[i].idx;
                break;
            }
        }
        return elevation;
    };

    function logStore(scan) {
        //const startTime = performance.now();
        //not the best solution
        if (scan === null) return;

        let maps;
        if (get(MAP_STATE) === DOUBLE) {
            maps = [0, 1];
        } else {
            maps = [0];
        }

        let needsLoading = [null, null];

        //get available fields in scan
        let allFields = [];
        for (let i=0; i<scan.scan.length; i++) {
            allFields = allFields.concat(scan.scan[i].fields);
        }

        const setFields = new Set(allFields);

        //need to map to integer from Symbol because 
        //Symbol can't be posted to worker
        let toLoad = get(map).map((x,i) => {
            if (x && maps.indexOf(i) > -1) {
                return idMap[x.field];
            } 
        }).filter(x => x != undefined);

        const mapObj = get(map);

        toLoad = toLoad.map((x, i)=> {

            let toTest;
            if (x === 255) {
                toTest = 1;
            } else {
                toTest = x;
            }

            if (setFields.has(toTest)) {
                return x;
            } else { //selected field from old file not in this one
                //go back to defaults
                if (i == 0) {
                    mapObj[i].updateField(REFLECTIVITY);
                    return 0;
                } 
                if (i == 1) {
                    if (get(DEALIAS_STATE) === DEALIASED) {
                        mapObj[i].updateField(VELOCITY_DEALIASED);
                        return 255;
                    } else {
                        mapObj[i].updateField(VELOCITY);
                        return 1;
                    }  
                }
            }
        })

        const arrays = scan.scan;
        const oldSelectionString = JSON.stringify(get(selections));
        const oldFile = get(selections)[0].file;
       
        const newSelection = toLoad.map(x => returnSelection(getElevation(arrays, x), scan.fileNumber, x, scan));
        const newSelectionString = JSON.stringify(newSelection);
        const newFile = newSelection[0].file;

        if (oldSelectionString === newSelectionString) {
            return;
        } else {

            selections.update(d => {
                return toLoad.map(x => returnSelection(getElevation(arrays, x), scan.fileNumber, x, scan));
                }
            )

            //update radarMetadata
            if (get(selections)[0].scan.station != get(radarMetadata).station) {
                radarMetadata.update(d=> {
                    const newMetadata = get(scans)[get(selections)[0].file].radarMetadata;
                    return newMetadata;
                })
            }
        };

        //load new data
        //relying on toLoad always being [0] or [0,1]. 
        //can be just [1]? hope not
        for (let i=0; i<toLoad.length; i++) {
            
            loadData(toLoad[i], getElevation(arrays, toLoad[i]), scan.fileNumber, i, scan.fileName)
        }

        if (newFile != get(currentFile)) {
            if (get(INT_STATE) === MODE3D || get(INT_STATE) === CROSS) {
                loadTexture(scan.fileNumber, scan.fileName);
            }
            currentFile.update(d=>newFile);
        }
        
        //const endTime = performance.now();

        //console.log("elapsed", endTime - startTime, endTime, startTime)
    }

    return {
        logStore
    }
}

export { UpdateData }