import { trendingData } from './store.js';

function Trending() {
    async function get() {
        await fetch('https://radar.quadweather.com/socket/trending')
        .then(d => d.json())
        .then(d => {
            trendingData.update(e => {
                e = d;
                return e;
            })
        });
    }

    let interval;

    function clear() {
        if (interval) clearInterval(interval);
        interval = null;
        trendingData.update(e => {
            e = {
                "type":"FeatureCollection",
                "features":[]
            };
            return e;
        })
    }

    async function start() {
        await get();
        interval = setInterval(get, 30000);
    }

    return {
        start,
        clear
    }

}

export { Trending }