import './Message.svelte.css';
/* src/Message.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text
} from "../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import { modal, auth } from './store.js';

function create_fragment(ctx) {
	let div4;
	let div0;
	let p0;
	let t0;
	let t1;
	let div2;
	let t3;
	let div3;
	let button0;
	let t5;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			div4 = element("div");
			div0 = element("div");
			p0 = element("p");
			t0 = text(/*header*/ ctx[0]);
			t1 = space();
			div2 = element("div");
			div2.innerHTML = `<div class="body-text svelte-tefax4"><p>A free account is needed to view live radar.</p></div>`;
			t3 = space();
			div3 = element("div");
			button0 = element("button");
			button0.textContent = "Create Account";
			t5 = space();
			button1 = element("button");
			button1.textContent = "Close";
			attr(p0, "class", "header-text svelte-tefax4");
			attr(div0, "class", "notice-header svelte-tefax4");
			attr(div2, "class", "notice-body svelte-tefax4");
			attr(button0, "class", "svelte-tefax4");
			attr(button1, "class", "svelte-tefax4");
			attr(div3, "class", "close svelte-tefax4");
			attr(div4, "class", "notice-wrapper svelte-tefax4");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div0);
			append(div0, p0);
			append(p0, t0);
			append(div4, t1);
			append(div4, div2);
			append(div4, t3);
			append(div4, div3);
			append(div3, button0);
			append(div3, t5);
			append(div3, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*createAccount*/ ctx[1]),
					listen(button1, "click", /*handleClick*/ ctx[2])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*header*/ 1) set_data(t0, /*header*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $modal;
	component_subscribe($$self, modal, $$value => $$invalidate(4, $modal = $$value));
	let { header } = $$props;
	let { body } = $$props;
	const dispatch = createEventDispatcher();

	async function createAccount() {
		const authenticated = await auth.isAuthenticated();

		if (!authenticated) {
			await auth.login();
		}

		handleClick();
	}

	function handleClick() {
		set_store_value(modal, $modal.error = false, $modal);
		dispatch('message', { value: "hideModal" });
	}

	$$self.$$set = $$props => {
		if ('header' in $$props) $$invalidate(0, header = $$props.header);
		if ('body' in $$props) $$invalidate(3, body = $$props.body);
	};

	return [header, createAccount, handleClick, body];
}

class Message extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { header: 0, body: 3 });
	}
}

export default Message;