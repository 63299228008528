import { MAP_STATE, sites, selections, MODE_STATE, socket, LOADING_LIVE, map as m, MOST_RECENT, ANIMATING } from './store.js';
import { UpdateData } from './UpdateClass.js';
import { closest } from './utils.js';
import { MODE_OBJECT, DOUBLE, SINGLE_HIDDEN } from './enums.js';
import { get } from '../_snowpack/pkg/svelte/store.js';

function Animation() {

    const { LIVE } = MODE_OBJECT;

    const updateInstance = UpdateData();

    let value = 0;
    let animateId;
    let start;
    let station_state;
    let elevation_state;
    let selectedScans;
    let fileIdx;
    let idx;
    let max;

    //const socket = get(s);
    const map = get(m);

    function setValues() {
        return {
            max,
            fileIdx,
            station_state,
            elevation_state,
            idx,
            currentIdx: findIdx(selectedScans)
        }
    }

    function findIdx(selectedScans) {
        //make sure initialized
        if (selectedScans === undefined || !(get(selections)[0].scan)) return;

        //get date of selected scan
        const selectionsDate = get(selections)[0].scan.scan[0].date.getTime();

        //find scan from elevation array with same date, there's your index
        for (let i=0; i<selectedScans.length; i++) {
           
            if (selectedScans[i][0].date.getTime() === selectionsDate) {
                return i;
            }
        }
    }

    function increment(toLoad) {
       
        const localSelections = get(selections);
        const localSites = get(sites);
       
        value = toLoad;

        if (value === 0) {

            LOADING_LIVE.set(false);

            //no selections
            if (!localSelections[0].scan && !socket.site) {
                
                handleClick(
                {
                    elevation: localSites.elevations[localSites.stations[0]][0], 
                    station: localSites.stations[0]
                }
                );
            }

            //had deleted previously selected because 5 files in live mode (rarer)
            else if (!localSelections[0].scan && socket.site) {
                if (localSites.elevations[socket.site]) {
                    
                    handleClick(
                        {
                            elevation: localSites.elevations[socket.site][0], 
                            station: socket.site
                        }
                    );
                }
            }

            //selections different from socket site
            else if (localSelections[0].scan && localSelections[0].scan.station != socket.site) {

                if (localSites.elevations[socket.site]) {
                   
                    handleClick(
                        {
                            elevation: localSites.elevations[socket.site][0], 
                            station: socket.site
                        }
                        );
                }
 
            }

            //change selections to new, more recent file
            if (get(MOST_RECENT)) {
                //console.log("MOST_RECENT", selectedScans)
                if (selectedScans) {
                    const localScan = {
                        fileNumber: selectedScans[selectedScans.length - 1][0].fileNumber,
                        scan:selectedScans[selectedScans.length - 1],
                        fileName:selectedScans[selectedScans.length - 1][0].fileName,
                        station:socket.site
                    }
                   

                    updateInstance.logStore(localScan);

                    idx = selectedScans.length - 1;
                } 
            }

        } else {
            LOADING_LIVE.set(true);
        }
    }

    function cancelAnimation() {
        ANIMATING.set(false);
        window.cancelAnimationFrame(animateId);

        //trigger render for framebuffer reading
        map[0].triggerRepaint();

        if (get(MAP_STATE) === DOUBLE && map[1]) {
            map[1].triggerRepaint();
        }
       
    }

    function render(timestamp) {

        if (start === undefined) {
            start = timestamp;
        }

        const elapsed = timestamp - start;

        //step every fraction of second
        if (elapsed >= 125) {
            advance();
            start = timestamp;
            if (idx === max - 1) {
                window.cancelAnimationFrame(animateId);
                setTimeout(function() {
                    

                    if (get(ANIMATING)) {
                        idx = 0;
                        start = undefined;
                        const localScan = {
                            fileNumber: selectedScans[idx][0].fileNumber,
                            scan:selectedScans[idx],
                            fileName:selectedScans[idx][0].fileName,
                            station:station_state
                        }
                        updateInstance.logStore(localScan);
                        animateId = window.requestAnimationFrame(render);
                    }
                }, 1000)
            } else {
                animateId = window.requestAnimationFrame(render);
            } 
        } else {
            animateId = window.requestAnimationFrame(render);
        }
    }

    function handleAnimateClick() {
        if (get(ANIMATING)) {
            ANIMATING.set(false);
            window.cancelAnimationFrame(animateId);
            //trigger render for framebuffer reading
            map[0].triggerRepaint();

            if (get(MAP_STATE) === DOUBLE && map[1]) {
                map[1].triggerRepaint();
            }
        } else {
            ANIMATING.set(true);
            //start fresh every time
            idx = 0;
            start = undefined;
            const localScan = {
                fileNumber: selectedScans[idx][0].fileNumber,
                scan:selectedScans[idx],
                fileName:selectedScans[idx][0].fileName,
                station:station_state
            }
            updateInstance.logStore(localScan);
            animateId = window.requestAnimationFrame(render);
        } 
    }

    socket.setTrigger(increment);
    socket.setCancel(cancelAnimation);

    function setStates() {
        const localSelections = get(selections);
        const localSites = get(sites);

        station_state = localSelections[0].scan ? localSelections[0].scan.station : undefined;
        elevation_state = localSelections[0].scan ? localSelections[0].scan.scan[0].rounded : undefined;
        selectedScans = localSites.scans && localSelections[0].scan ? localSites.scans[station_state][elevation_state] : undefined;
        fileIdx = localSelections[0].scan ? localSelections[0].scan.fileNumber : undefined;
        //console.log("setStates", selectedScans);
        if (selectedScans) max = selectedScans.length;
    }

    function setSites() {
        //console.log("UPDATING SITES IN ELEVATIONVIEW", station_state, elevation_state)
        if (!get(sites).scans || !station_state || !elevation_state) return;
        //update max
        selectedScans = get(sites).scans[station_state][elevation_state];
        //console.log("setSites", selectedScans);
        max = selectedScans.length;
        idx = findIdx(selectedScans);
    }


    function handleClick(e) {
        //temporary bug fix
        if (get(LOADING_LIVE)) return;

        idx = findIdx(selectedScans);

        let currentTime = 0;
        let currentFile;

        if (selectedScans) {
            currentTime = selectedScans[idx][0].date.getTime();
            currentFile = selectedScans[idx][0].fileNumber;
        }
        
        selectedScans = get(sites).scans[e.station][e.elevation];
       
        const possibleTimes = selectedScans.map(d=>d[0].date.getTime());
        max = selectedScans.length;

        idx = closest(currentTime, possibleTimes);

        //determine appropriate index here
        if (selectedScans[idx][0].fileNumber != currentFile) {
            idx = 0;
        }

        //get data into format expected by updateInstance
        const localScan = {
            fileNumber: selectedScans[idx][0].fileNumber,
            scan:selectedScans[idx],
            fileName:selectedScans[idx][0].fileName,
            station:e.station
        }

        if (get(MODE_STATE) === LIVE) {
           
            socket.setSite(e.station);
            map[0].setLayoutProperty('sites', 'icon-image', [
                       "case",
                        ["==", ["slice",['get', 'STATION_ID'],7], socket.site],
                        'selected',
                        'unselected'
            ]);

            if ((get(MAP_STATE) === DOUBLE || get(MAP_STATE) === SINGLE_HIDDEN) && map[1]) {
                map[1].setLayoutProperty('sites', 'icon-image', [
                    "case",
                     ["==", ["slice",['get', 'STATION_ID'],7], socket.site],
                     'selected',
                     'unselected'
                ]);
            }
        }

        updateInstance.logStore(localScan);
    }

    function advance(){
       
        if (idx === undefined) return;
       
        idx = (idx + 1) < max ? idx + 1 : idx;

        if (idx > max -1) {
            idx = max -1;
        }

        if (idx === max - 1) {
            MOST_RECENT.set(true);
        } else {
            MOST_RECENT.set(false);
        }

        if (selectedScans[idx] === undefined) {
            return;
        }
       
        const localScan = {
            fileNumber: selectedScans[idx][0].fileNumber,
            scan:selectedScans[idx],
            fileName:selectedScans[idx][0].fileName,
            station:station_state
        }

        updateInstance.logStore(localScan);
    }

    function previous() {
     
        if (idx === undefined ) return;
 
        idx = (idx - 1) >= 0 ? idx - 1 : idx;

        if (idx === max - 1) {
            MOST_RECENT.set(true);
        } else {
            MOST_RECENT.set(false);
        }

        if (selectedScans[idx] === undefined) {
            return;
        }

        const localScan = {
            fileNumber: selectedScans[idx][0].fileNumber,
            scan:selectedScans[idx],
            fileName:selectedScans[idx][0].fileName,
            station:station_state
        }

        updateInstance.logStore(localScan);
    }

    function handleLoadClick() {
        if (get(LOADING_LIVE)) return;
        LOADING_LIVE.set(true);
        socket.loadLoop();
    }

    

    return {
        handleClick,
        setSites,
        setStates,
        handleLoadClick,
        setValues,
        previous,
        advance,
        handleAnimateClick
    }

}

export { Animation }