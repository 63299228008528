import './ElevationView.svelte.css';
/* src/ElevationView.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import {
	sites,
	selections,
	scans,
	MODE_STATE,
	animation,
	ANIMATING
} from './store.js';

import { MODE_OBJECT } from './enums.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[13] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

// (27:4) {#if $selections[0].scan}
function create_if_block_2(ctx) {
	let div1;
	let table;
	let tbody;
	let tr0;
	let t5;
	let tr1;
	let td3;

	let t6_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
	? ''
	: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].fileName) + "";

	let t6;
	let t7;
	let td4;

	let t8_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
	? ''
	: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].vcp) + "";

	let t8;
	let t9;
	let td5;

	let t10_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
	? ''
	: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].station) + "";

	let t10;
	let t11;
	let div0;
	let button0;
	let t12;
	let button1;
	let t13_value = (/*$ANIMATING*/ ctx[8] ? "Stop" : "Animate") + "";
	let t13;
	let t14;
	let button2;
	let t15;
	let mounted;
	let dispose;
	let if_block = /*max*/ ctx[5] > 0 && create_if_block_3(ctx);

	return {
		c() {
			div1 = element("div");
			table = element("table");
			tbody = element("tbody");
			tr0 = element("tr");

			tr0.innerHTML = `<td class="file svelte-hbmvhi">File</td> 
                    <td class="vcp svelte-hbmvhi">VCP</td> 
                    <td class="station svelte-hbmvhi">Radar</td>`;

			t5 = space();
			tr1 = element("tr");
			td3 = element("td");
			t6 = text(t6_value);
			t7 = space();
			td4 = element("td");
			t8 = text(t8_value);
			t9 = space();
			td5 = element("td");
			t10 = text(t10_value);
			t11 = space();
			div0 = element("div");
			button0 = element("button");
			button0.innerHTML = `<svg class="arrow svelte-hbmvhi" viewBox="0 0 48 102" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_15_133)"><path d="M4.09662 46.9999L43.9302 0.305731L43.7629 93.8363L4.09662 46.9999Z" fill="white"></path></g><defs><filter id="filter0_d_15_133" x="0.0966797" y="0.305725" width="47.8336" height="101.531" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_133"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_133" result="shape"></feBlend></filter></defs></svg>`;
			t12 = space();
			button1 = element("button");
			t13 = text(t13_value);
			t14 = space();
			button2 = element("button");
			button2.innerHTML = `<svg class="arrow svelte-hbmvhi" viewBox="0 0 48 102" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_15_134)"><path d="M44 47L4.25 0.234627L4.25 93.7654L44 47Z" fill="white"></path></g><defs><filter id="filter0_d_15_134" x="0.25" y="0.234619" width="47.75" height="101.531" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_134"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_134" result="shape"></feBlend></filter></defs></svg>`;
			t15 = space();
			if (if_block) if_block.c();
			attr(td3, "class", "content file svelte-hbmvhi");
			attr(td4, "class", "content vcp svelte-hbmvhi");
			attr(td5, "class", "content station svelte-hbmvhi");
			attr(table, "class", "svelte-hbmvhi");
			attr(button0, "class", "arrowbutton svelte-hbmvhi");
			attr(button1, "class", "loopButton svelte-hbmvhi");
			attr(button2, "class", "arrowbutton svelte-hbmvhi");
			attr(div0, "class", "pncontainer svelte-hbmvhi");
			attr(div1, "class", "tc svelte-hbmvhi");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, table);
			append(table, tbody);
			append(tbody, tr0);
			append(tbody, t5);
			append(tbody, tr1);
			append(tr1, td3);
			append(td3, t6);
			append(tr1, t7);
			append(tr1, td4);
			append(td4, t8);
			append(tr1, t9);
			append(tr1, td5);
			append(td5, t10);
			append(div1, t11);
			append(div1, div0);
			append(div0, button0);
			append(div0, t12);
			append(div0, button1);
			append(button1, t13);
			append(div0, t14);
			append(div0, button2);
			append(div0, t15);
			if (if_block) if_block.m(div0, null);

			if (!mounted) {
				dispose = [
					listen(button0, "click", animation.previous),
					listen(button1, "click", animation.handleAnimateClick),
					listen(button2, "click", animation.advance)
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*fileIdx, $scans*/ 132 && t6_value !== (t6_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
			? ''
			: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].fileName) + "")) set_data(t6, t6_value);

			if (dirty & /*fileIdx, $scans*/ 132 && t8_value !== (t8_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
			? ''
			: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].vcp) + "")) set_data(t8, t8_value);

			if (dirty & /*fileIdx, $scans*/ 132 && t10_value !== (t10_value = (/*fileIdx*/ ctx[2] === undefined || /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]] === undefined
			? ''
			: /*$scans*/ ctx[7][/*fileIdx*/ ctx[2]].station) + "")) set_data(t10, t10_value);

			if (dirty & /*$ANIMATING*/ 256 && t13_value !== (t13_value = (/*$ANIMATING*/ ctx[8] ? "Stop" : "Animate") + "")) set_data(t13, t13_value);

			if (/*max*/ ctx[5] > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (87:12) {#if max > 0}
function create_if_block_3(ctx) {
	let div;
	let t0_value = /*currentIdx*/ ctx[6] + 1 + "";
	let t0;
	let t1;
	let t2;

	return {
		c() {
			div = element("div");
			t0 = text(t0_value);
			t1 = text("/");
			t2 = text(/*max*/ ctx[5]);
			attr(div, "class", "idx svelte-hbmvhi");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
			append(div, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*currentIdx*/ 64 && t0_value !== (t0_value = /*currentIdx*/ ctx[6] + 1 + "")) set_data(t0, t0_value);
			if (dirty & /*max*/ 32) set_data(t2, /*max*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (97:16) {#if station}
function create_if_block(ctx) {
	let div2;
	let div0;
	let p;
	let t0_value = /*station*/ ctx[13] + "";
	let t0;
	let t1;
	let t2;
	let t3;
	let div1;
	let t4;
	let if_block = /*$MODE_STATE*/ ctx[9] === /*LIVE*/ ctx[10] && create_if_block_1(ctx);
	let each_value_1 = /*$sites*/ ctx[0].elevations[/*station*/ ctx[13]];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			p = element("p");
			t0 = text(t0_value);
			t1 = text(" elevations");
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			attr(p, "class", "buttonlabel svelte-hbmvhi");
			attr(div0, "class", "site-header svelte-hbmvhi");
			attr(div1, "class", "buttoncontainer svelte-hbmvhi");
			attr(div2, "class", "stationContainer svelte-hbmvhi");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, p);
			append(p, t0);
			append(p, t1);
			append(div0, t2);
			if (if_block) if_block.m(div0, null);
			append(div2, t3);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			append(div2, t4);
		},
		p(ctx, dirty) {
			if (dirty & /*$sites*/ 1 && t0_value !== (t0_value = /*station*/ ctx[13] + "")) set_data(t0, t0_value);

			if (/*$MODE_STATE*/ ctx[9] === /*LIVE*/ ctx[10]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div0, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$sites, elevation_state, station_state, animation*/ 25) {
				each_value_1 = /*$sites*/ ctx[0].elevations[/*station*/ ctx[13]];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
		}
	};
}

// (101:24) {#if $MODE_STATE === LIVE}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Load last five";
			attr(button, "class", "loadButton svelte-hbmvhi");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", animation.handleLoadClick);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (108:24) {#each $sites.elevations[station] as elevation}
function create_each_block_1(ctx) {
	let button;
	let t0_value = /*elevation*/ ctx[16] + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			t0 = text(t0_value);
			t1 = text("°");
			attr(button, "class", "ebutton svelte-hbmvhi");

			toggle_class(button, "activebutton", /*elevation*/ ctx[16] === /*elevation_state*/ ctx[3] && /*station_state*/ ctx[4] === /*station*/ ctx[13]
			? true
			: false);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, t0);
			append(button, t1);

			if (!mounted) {
				dispose = listen(button, "click", function () {
					if (is_function(animation.handleClick({
						elevation: /*elevation*/ ctx[16],
						station: /*station*/ ctx[13]
					}))) animation.handleClick({
						elevation: /*elevation*/ ctx[16],
						station: /*station*/ ctx[13]
					}).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$sites*/ 1 && t0_value !== (t0_value = /*elevation*/ ctx[16] + "")) set_data(t0, t0_value);

			if (dirty & /*$sites, elevation_state, station_state*/ 25) {
				toggle_class(button, "activebutton", /*elevation*/ ctx[16] === /*elevation_state*/ ctx[3] && /*station_state*/ ctx[4] === /*station*/ ctx[13]
				? true
				: false);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (96:12) {#each $sites.stations as station}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = /*station*/ ctx[13] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*station*/ ctx[13]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let div;
	let if_block = /*$selections*/ ctx[1][0].scan && create_if_block_2(ctx);
	let each_value = /*$sites*/ ctx[0].stations;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "scroll svelte-hbmvhi");
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (/*$selections*/ ctx[1][0].scan) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$sites, elevation_state, station_state, animation, $MODE_STATE, LIVE*/ 1561) {
				each_value = /*$sites*/ ctx[0].stations;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $sites;
	let $selections;
	let $scans;
	let $ANIMATING;
	let $MODE_STATE;
	component_subscribe($$self, sites, $$value => $$invalidate(0, $sites = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(1, $selections = $$value));
	component_subscribe($$self, scans, $$value => $$invalidate(7, $scans = $$value));
	component_subscribe($$self, ANIMATING, $$value => $$invalidate(8, $ANIMATING = $$value));
	component_subscribe($$self, MODE_STATE, $$value => $$invalidate(9, $MODE_STATE = $$value));
	const { LIVE } = MODE_OBJECT;
	let fileIdx, elevation_state, station_state, max, idx, currentIdx;

	function setValues() {
		const values = animation.setValues();
		$$invalidate(2, fileIdx = values.fileIdx);
		$$invalidate(5, max = values.max);
		idx = values.idx;
		$$invalidate(3, elevation_state = values.elevation_state);
		$$invalidate(4, station_state = values.station_state);
		$$invalidate(6, currentIdx = values.currentIdx);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$selections*/ 2) {
			//whenever selections change, update component state
			$: ($selections, animation.setStates(), setValues());
		}

		if ($$self.$$.dirty & /*$sites*/ 1) {
			$: ($sites, animation.setSites(), setValues());
		}
	};

	return [
		$sites,
		$selections,
		fileIdx,
		elevation_state,
		station_state,
		max,
		currentIdx,
		$scans,
		$ANIMATING,
		$MODE_STATE,
		LIVE
	];
}

class ElevationView extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ElevationView;