import './Menu3D.svelte.css';
/* src/Menu3D.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	binding_callbacks,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../_snowpack/pkg/svelte.js';
import { drag } from '../_snowpack/pkg/d3-drag.js';
import { select as d3select } from '../_snowpack/pkg/d3-selection.js';
import { colorbarGenerator } from './colorbars.js';
import { MAP_OBJECT } from './enums.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

// (290:16) {#each options as option}
function create_each_block(ctx) {
	let option;
	let t0_value = /*option*/ ctx[27] + "";
	let t0;
	let t1;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t0 = text(t0_value);
			t1 = space();
			option.__value = option_value_value = /*option*/ ctx[27];
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t0);
			append(option, t1);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

function create_fragment(ctx) {
	let div5;
	let div2;
	let p0;
	let t1;
	let div0;
	let button0;
	let t3;
	let button1;
	let t5;
	let div1;
	let span;
	let t7;
	let select;
	let t8;
	let div4;
	let div3;
	let canvas0;
	let t9;
	let p1;
	let t11;
	let p2;
	let t13;
	let canvas1;
	let mounted;
	let dispose;
	let each_value = /*options*/ ctx[4];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div5 = element("div");
			div2 = element("div");
			p0 = element("p");
			p0.textContent = "Edit transparency";
			t1 = space();
			div0 = element("div");
			button0 = element("button");
			button0.textContent = "Reset";
			t3 = space();
			button1 = element("button");
			button1.textContent = "Close";
			t5 = space();
			div1 = element("div");
			span = element("span");
			span.textContent = "Presets";
			t7 = space();
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t8 = space();
			div4 = element("div");
			div3 = element("div");
			canvas0 = element("canvas");
			t9 = space();
			p1 = element("p");
			p1.textContent = "opaque";
			t11 = space();
			p2 = element("p");
			p2.textContent = "transparent";
			t13 = space();
			canvas1 = element("canvas");
			attr(p0, "class", "title svelte-171bwmt");
			attr(button0, "class", "reset svelte-171bwmt");
			attr(button1, "class", "close svelte-171bwmt");
			attr(div0, "class", "button-container svelte-171bwmt");
			attr(select, "class", "s svelte-171bwmt");
			if (/*selected*/ ctx[3] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[9].call(select));
			attr(div1, "class", "preset-container svelte-171bwmt");
			attr(div2, "class", "top svelte-171bwmt");
			attr(canvas0, "class", "window svelte-171bwmt");
			attr(p1, "class", "opaque svelte-171bwmt");
			attr(p2, "class", "transparent svelte-171bwmt");
			attr(div3, "class", "canvas-wrapper");
			attr(canvas1, "class", "colorbar svelte-171bwmt");
			attr(div4, "class", "window-wrapper svelte-171bwmt");
			attr(div5, "class", "menu3d-wrapper svelte-171bwmt");
			toggle_class(div5, "hidden", /*showTrans*/ ctx[0] ? false : true);
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div2);
			append(div2, p0);
			append(div2, t1);
			append(div2, div0);
			append(div0, button0);
			append(div0, t3);
			append(div0, button1);
			append(div2, t5);
			append(div2, div1);
			append(div1, span);
			append(div1, t7);
			append(div1, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*selected*/ ctx[3]);
			append(div5, t8);
			append(div5, div4);
			append(div4, div3);
			append(div3, canvas0);
			/*canvas0_binding*/ ctx[10](canvas0);
			append(div3, t9);
			append(div3, p1);
			append(div3, t11);
			append(div3, p2);
			append(div4, t13);
			append(div4, canvas1);
			/*canvas1_binding*/ ctx[11](canvas1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*reset*/ ctx[5]),
					listen(button1, "click", /*close*/ ctx[6]),
					listen(select, "change", /*select_change_handler*/ ctx[9]),
					listen(select, "change", /*handleChange*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*options*/ 16) {
				each_value = /*options*/ ctx[4];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*selected, options*/ 24) {
				select_option(select, /*selected*/ ctx[3]);
			}

			if (dirty & /*showTrans*/ 1) {
				toggle_class(div5, "hidden", /*showTrans*/ ctx[0] ? false : true);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div5);
			destroy_each(each_blocks, detaching);
			/*canvas0_binding*/ ctx[10](null);
			/*canvas1_binding*/ ctx[11](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const { REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED } = MAP_OBJECT;
	let canvas, canvasColor;
	let ctx, ctxColor;
	let opacityArray = new Array(350);
	let previous = [];
	let generator;
	let selected = "Default";
	const options = ["Default", "Hail", "Hurricane", "Snow"];
	let { imageData = undefined } = $$props;
	let { showTrans } = $$props;

	function start({ x, y }) {
		if (x < 10) x = 10;
		if (y < 10) y = 10;
		if (x > 360) x = 360;
		if (y > 210) y = 210;
		previous = [x, y];
	}

	function dragged({ x, y }) {
		let b = false;

		if (x < 10) {
			x = 10;
			b = true;
		}

		;

		if (y < 10) {
			y = 10;
		}

		;

		if (x > 360) {
			x = 360;
			b = true;
		}

		if (y > 210) {
			y = 210;
		}

		const py = previous[1];
		const px = previous[0];
		const range = Math.abs(x - px);

		if (b) {
			previous = [x, y];
			return;
		}

		//moving left
		if (x - px < 0) {
			for (let i = x; i < px; i++) {
				let int = y * ((px - i) / range) + py * ((i - x) / range);
				opacityArray[i - 10] = (Math.min(Math.max(int, 10), 210) - 10) / 200;
			}
		} else {
			//moving right
			for (let i = px; i < x; i++) {
				let int = py * ((x - i) / range) + y * ((i - px) / range);
				opacityArray[i - 10] = (Math.min(Math.max(int, 10), 210) - 10) / 200;
			}
		}

		if (x >= 10 && x <= 360) {
			opacityArray[x - 10] = (Math.min(Math.max(y, 10), 210) - 10) / 200;
		}

		previous = [x, y];
	}

	function render() {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = 'black';
		ctx.fillRect(10, 10, 350, 200);
		ctx.strokeStyle = 'white';
		ctx.lineWidth = 3.0;
		ctx.lineJoin = 'round';
		ctx.lineCap = 'round';
		ctx.beginPath();
		ctx.moveTo(10, opacityArray[0] * 200 + 10);

		for (let i = 1; i < opacityArray.length; i++) {
			ctx.lineTo(i + 10, opacityArray[i] * 200 + 10);
		}

		ctx.stroke();
	}

	function onResize() {
		if (!canvas) return;
		const sscale = window.devicePixelRatio || 1;
		$$invalidate(1, canvas.style.width = "370px", canvas);
		$$invalidate(1, canvas.style.height = "220px", canvas);
		$$invalidate(1, canvas.width = canvas.clientWidth * sscale, canvas);
		$$invalidate(1, canvas.height = canvas.clientHeight * sscale, canvas);
		ctx.scale(sscale, sscale);
		generator.render(REFLECTIVITY);
		render();
	} //if (ctxColor.canvas.width === 0) return;

	function onResizeColor() {
		if (!canvasColor) return;
		$$invalidate(2, canvasColor.style.width = "350px", canvasColor);
		$$invalidate(2, canvasColor.style.height = "20px", canvasColor);
		$$invalidate(2, canvasColor.width = canvasColor.clientWidth, canvasColor);
		$$invalidate(2, canvasColor.height = canvasColor.clientHeight, canvasColor);
		generator.render(REFLECTIVITY);

		//console.log("setting imageData");
		$$invalidate(8, imageData = ctxColor.getImageData(0, 0, 350, 1));

		for (let i = 0; i < opacityArray.length; i++) {
			$$invalidate(8, imageData.data[4 * i + 3] = 255 * (1 - opacityArray[i]), imageData);
		}
	}

	function dragend() {
		generator.render(REFLECTIVITY);
		if (ctxColor.canvas.width === 0) return;
		$$invalidate(8, imageData = ctxColor.getImageData(0, 0, 350, 1));

		for (let i = 0; i < opacityArray.length; i++) {
			$$invalidate(8, imageData.data[4 * i + 3] = 255 * (1 - opacityArray[i]), imageData);
		}
	}

	function preset(type) {
		if (type === "Snow") {
			for (let i = 0; i < 15; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 15; i < 90; i++) {
				opacityArray[i] = 1 - Math.pow((i - 15) / 200, 1.5);
			}

			for (let i = 90; i < 105; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 105; i < 350; i++) {
				opacityArray[i] = 1 - Math.min(1, Math.pow((i - 105) / 200, 0.35));
			}
		}

		if (type === "Default") {
			for (let i = 0; i < 25; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 25; i < opacityArray.length; i++) {
				opacityArray[i] = Math.min(1 - Math.pow(i / 350, 1.0), 1);
			}
		}

		if (type === "Hail") {
			for (let i = 0; i < 15; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 15; i < 90; i++) {
				opacityArray[i] = 1 - Math.pow((i - 15) / 200, 1.5);
			}

			for (let i = 90; i < 200; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 200; i < 270; i++) {
				opacityArray[i] = 1 - Math.pow((i - 200) / 175, 2);
			}

			for (let i = 270; i < 280; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 280; i < 350; i++) {
				opacityArray[i] = 1 - Math.min(1, Math.pow((i - 280) / 200, 0.15));
			}
		}

		if (type === "Hurricane") {
			for (let i = 0; i < 15; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 15; i < 90; i++) {
				opacityArray[i] = 1 - Math.pow((i - 15) / 200, 1.5);
			}

			for (let i = 90; i < 150; i++) {
				opacityArray[i] = 1;
			}

			for (let i = 150; i < 350; i++) {
				opacityArray[i] = 1 - Math.min(1, Math.pow((i - 150) / 200, 0.35));
			}
		}
	}

	onMount(() => {
		const observer = new IntersectionObserver(onResize);
		observer.observe(canvas);
		const observerColor = new IntersectionObserver(onResizeColor);
		observerColor.observe(canvasColor);

		//default opacity values
		preset("Default");

		ctx = canvas.getContext('2d');
		ctxColor = canvasColor.getContext('2d', { willReadFrequently: true });
		const sscale = window.devicePixelRatio || 1;
		$$invalidate(1, canvas.style.width = "370px", canvas);
		$$invalidate(1, canvas.style.height = "220px", canvas);
		$$invalidate(1, canvas.width = canvas.clientWidth * sscale, canvas);
		$$invalidate(1, canvas.height = canvas.clientHeight * sscale, canvas);
		ctx.scale(sscale, sscale);
		$$invalidate(2, canvasColor.style.width = "350px", canvasColor);
		$$invalidate(2, canvasColor.style.height = "20px", canvasColor);
		$$invalidate(2, canvasColor.width = canvasColor.clientWidth, canvasColor);
		$$invalidate(2, canvasColor.height = canvasColor.clientHeight, canvasColor);
		generator = colorbarGenerator(canvasColor, ctxColor, sscale);
		generator.render(REFLECTIVITY);
		d3select(canvas).call(drag().container(canvas).on("start", start).on("drag", dragged).on("end", dragend).on("start.render drag.render", render));
		render();
		dragend();
	});

	function reset() {
		preset(selected);
		render();
		dragend();
	}

	function close() {
		$$invalidate(0, showTrans = false);
	}

	function handleChange() {
		preset(selected);
		render();
		dragend();
	}

	function select_change_handler() {
		selected = select_value(this);
		$$invalidate(3, selected);
		$$invalidate(4, options);
	}

	function canvas0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvas = $$value;
			$$invalidate(1, canvas);
		});
	}

	function canvas1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvasColor = $$value;
			$$invalidate(2, canvasColor);
		});
	}

	$$self.$$set = $$props => {
		if ('imageData' in $$props) $$invalidate(8, imageData = $$props.imageData);
		if ('showTrans' in $$props) $$invalidate(0, showTrans = $$props.showTrans);
	};

	return [
		showTrans,
		canvas,
		canvasColor,
		selected,
		options,
		reset,
		close,
		handleChange,
		imageData,
		select_change_handler,
		canvas0_binding,
		canvas1_binding
	];
}

class Menu3D extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { imageData: 8, showTrans: 0 });
	}
}

export default Menu3D;