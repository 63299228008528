import './App.svelte.css';
/* src/App.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Menu from './Menu.svelte.js';
import Map from './Map.svelte.js';
import Bar from './Bar.svelte.js';
import Message from './Message.svelte.js';
import '../_snowpack/pkg/mapbox-gl/dist/mapbox-gl.css';
import State from './State.svelte.js';
import { DOUBLE, SINGLE_HIDDEN } from './enums.js';
import { MAP_OBJECT } from './enums.js';

import {
	MAP_STATE,
	error,
	VIEW_STATE,
	animation,
	modal,
	SPLIT_STATE
} from './store.js';

import Notice from './Notice.svelte.js';
import { VIEW_OBJECT, INT_OBJECT } from './enums.js';

function create_if_block_4(ctx) {
	let hr;
	let hr_class_value;

	return {
		c() {
			hr = element("hr");

			attr(hr, "class", hr_class_value = "line " + (/*$SPLIT_STATE*/ ctx[12] === 'horizontal'
			? ''
			: 'vertical-line') + " svelte-1bo28as");
		},
		m(target, anchor) {
			insert(target, hr, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$SPLIT_STATE*/ 4096 && hr_class_value !== (hr_class_value = "line " + (/*$SPLIT_STATE*/ ctx[12] === 'horizontal'
			? ''
			: 'vertical-line') + " svelte-1bo28as")) {
				attr(hr, "class", hr_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(hr);
		}
	};
}

// (150:4) {#if $MAP_STATE === DOUBLE || $MAP_STATE === SINGLE_HIDDEN}
function create_if_block_3(ctx) {
	let map;
	let current;
	let map_props = { show: /*showSecond*/ ctx[1], idx: 1 };
	map = new Map({ props: map_props });
	/*map_binding_1*/ ctx[18](map);
	map.$on("message", /*handleMessage*/ ctx[13]);

	return {
		c() {
			create_component(map.$$.fragment);
		},
		m(target, anchor) {
			mount_component(map, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const map_changes = {};
			if (dirty & /*showSecond*/ 2) map_changes.show = /*showSecond*/ ctx[1];
			map.$set(map_changes);
		},
		i(local) {
			if (current) return;
			transition_in(map.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(map.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			/*map_binding_1*/ ctx[18](null);
			destroy_component(map, detaching);
		}
	};
}

// (157:2) {#if loading}
function create_if_block_2(ctx) {
	let div3;

	return {
		c() {
			div3 = element("div");
			div3.innerHTML = `<div class="lds-ripple svelte-1bo28as"><div class="svelte-1bo28as"></div><div class="svelte-1bo28as"></div></div>`;
			attr(div3, "class", "loading svelte-1bo28as");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
		},
		d(detaching) {
			if (detaching) detach(div3);
		}
	};
}

// (163:1) {#if showError}
function create_if_block_1(ctx) {
	let notice;
	let current;

	notice = new Notice({
			props: {
				header: /*header*/ ctx[4],
				body: /*body*/ ctx[5],
				className: /*className*/ ctx[6]
			}
		});

	notice.$on("message", /*handleMessage*/ ctx[13]);

	return {
		c() {
			create_component(notice.$$.fragment);
		},
		m(target, anchor) {
			mount_component(notice, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const notice_changes = {};
			if (dirty & /*header*/ 16) notice_changes.header = /*header*/ ctx[4];
			if (dirty & /*body*/ 32) notice_changes.body = /*body*/ ctx[5];
			if (dirty & /*className*/ 64) notice_changes.className = /*className*/ ctx[6];
			notice.$set(notice_changes);
		},
		i(local) {
			if (current) return;
			transition_in(notice.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(notice.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(notice, detaching);
		}
	};
}

// (166:1) {#if showModal}
function create_if_block(ctx) {
	let message;
	let current;

	message = new Message({
			props: {
				header: /*modalHeader*/ ctx[7],
				body: /*modalBody*/ ctx[8]
			}
		});

	message.$on("message", /*handleMessage*/ ctx[13]);

	return {
		c() {
			create_component(message.$$.fragment);
		},
		m(target, anchor) {
			mount_component(message, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const message_changes = {};
			if (dirty & /*modalHeader*/ 128) message_changes.header = /*modalHeader*/ ctx[7];
			if (dirty & /*modalBody*/ 256) message_changes.body = /*modalBody*/ ctx[8];
			message.$set(message_changes);
		},
		i(local) {
			if (current) return;
			transition_in(message.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(message.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(message, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let bar;
	let t0;
	let div1;
	let menu;
	let updating_elevationFunctions;
	let updating_canvas;
	let t1;
	let div0;
	let map;
	let t2;
	let t3;
	let div0_class_value;
	let t4;
	let state;
	let t5;
	let t6;
	let t7;
	let current;
	let mounted;
	let dispose;
	bar = new Bar({});
	bar.$on("message", /*handleMessage*/ ctx[13]);

	function menu_elevationFunctions_binding(value) {
		/*menu_elevationFunctions_binding*/ ctx[15](value);
	}

	function menu_canvas_binding(value) {
		/*menu_canvas_binding*/ ctx[16](value);
	}

	let menu_props = {};

	if (/*elevationFunctions*/ ctx[9] !== void 0) {
		menu_props.elevationFunctions = /*elevationFunctions*/ ctx[9];
	}

	if (/*canvas*/ ctx[10] !== void 0) {
		menu_props.canvas = /*canvas*/ ctx[10];
	}

	menu = new Menu({ props: menu_props });
	binding_callbacks.push(() => bind(menu, 'elevationFunctions', menu_elevationFunctions_binding));
	binding_callbacks.push(() => bind(menu, 'canvas', menu_canvas_binding));
	let map_props = { show: true, idx: 0 };
	map = new Map({ props: map_props });
	/*map_binding*/ ctx[17](map);
	map.$on("message", /*handleMessage*/ ctx[13]);
	let if_block0 = /*$MAP_STATE*/ ctx[0] === DOUBLE && create_if_block_4(ctx);
	let if_block1 = (/*$MAP_STATE*/ ctx[0] === DOUBLE || /*$MAP_STATE*/ ctx[0] === SINGLE_HIDDEN) && create_if_block_3(ctx);
	state = new State({});
	state.$on("message", /*handleMessage*/ ctx[13]);
	let if_block2 = loading && create_if_block_2(ctx);
	let if_block3 = /*showError*/ ctx[2] && create_if_block_1(ctx);
	let if_block4 = /*showModal*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			create_component(bar.$$.fragment);
			t0 = space();
			div1 = element("div");
			create_component(menu.$$.fragment);
			t1 = space();
			div0 = element("div");
			create_component(map.$$.fragment);
			t2 = space();
			if (if_block0) if_block0.c();
			t3 = space();
			if (if_block1) if_block1.c();
			t4 = space();
			create_component(state.$$.fragment);
			t5 = space();
			if (if_block2) if_block2.c();
			t6 = space();
			if (if_block3) if_block3.c();
			t7 = space();
			if (if_block4) if_block4.c();

			attr(div0, "class", div0_class_value = "map-display " + (/*$SPLIT_STATE*/ ctx[12] === 'horizontal'
			? ''
			: 'vertical') + " svelte-1bo28as");

			attr(div1, "class", "main-display svelte-1bo28as");
			attr(div2, "class", "parent svelte-1bo28as");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			mount_component(bar, div2, null);
			append(div2, t0);
			append(div2, div1);
			mount_component(menu, div1, null);
			append(div1, t1);
			append(div1, div0);
			mount_component(map, div0, null);
			append(div0, t2);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t3);
			if (if_block1) if_block1.m(div0, null);
			append(div1, t4);
			mount_component(state, div1, null);
			append(div1, t5);
			if (if_block2) if_block2.m(div1, null);
			append(div2, t6);
			if (if_block3) if_block3.m(div2, null);
			append(div2, t7);
			if (if_block4) if_block4.m(div2, null);
			current = true;

			if (!mounted) {
				dispose = listen(window, "keydown", /*handleKeydown*/ ctx[14]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const menu_changes = {};

			if (!updating_elevationFunctions && dirty & /*elevationFunctions*/ 512) {
				updating_elevationFunctions = true;
				menu_changes.elevationFunctions = /*elevationFunctions*/ ctx[9];
				add_flush_callback(() => updating_elevationFunctions = false);
			}

			if (!updating_canvas && dirty & /*canvas*/ 1024) {
				updating_canvas = true;
				menu_changes.canvas = /*canvas*/ ctx[10];
				add_flush_callback(() => updating_canvas = false);
			}

			menu.$set(menu_changes);
			const map_changes = {};
			map.$set(map_changes);

			if (/*$MAP_STATE*/ ctx[0] === DOUBLE) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_4(ctx);
					if_block0.c();
					if_block0.m(div0, t3);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*$MAP_STATE*/ ctx[0] === DOUBLE || /*$MAP_STATE*/ ctx[0] === SINGLE_HIDDEN) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*$MAP_STATE*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_3(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div0, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*$SPLIT_STATE*/ 4096 && div0_class_value !== (div0_class_value = "map-display " + (/*$SPLIT_STATE*/ ctx[12] === 'horizontal'
			? ''
			: 'vertical') + " svelte-1bo28as")) {
				attr(div0, "class", div0_class_value);
			}

			if (/*showError*/ ctx[2]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);

					if (dirty & /*showError*/ 4) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_1(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(div2, t7);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (/*showModal*/ ctx[3]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty & /*showModal*/ 8) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(div2, null);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(bar.$$.fragment, local);
			transition_in(menu.$$.fragment, local);
			transition_in(map.$$.fragment, local);
			transition_in(if_block1);
			transition_in(state.$$.fragment, local);
			transition_in(if_block3);
			transition_in(if_block4);
			current = true;
		},
		o(local) {
			transition_out(bar.$$.fragment, local);
			transition_out(menu.$$.fragment, local);
			transition_out(map.$$.fragment, local);
			transition_out(if_block1);
			transition_out(state.$$.fragment, local);
			transition_out(if_block3);
			transition_out(if_block4);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(bar);
			destroy_component(menu);
			/*map_binding*/ ctx[17](null);
			destroy_component(map);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			destroy_component(state);
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			mounted = false;
			dispose();
		}
	};
}

let loading = false;

function instance($$self, $$props, $$invalidate) {
	let $VIEW_STATE;
	let $modal;
	let $error;
	let $MAP_STATE;
	let $SPLIT_STATE;
	component_subscribe($$self, VIEW_STATE, $$value => $$invalidate(20, $VIEW_STATE = $$value));
	component_subscribe($$self, modal, $$value => $$invalidate(21, $modal = $$value));
	component_subscribe($$self, error, $$value => $$invalidate(22, $error = $$value));
	component_subscribe($$self, MAP_STATE, $$value => $$invalidate(0, $MAP_STATE = $$value));
	component_subscribe($$self, SPLIT_STATE, $$value => $$invalidate(12, $SPLIT_STATE = $$value));
	const { ELEVATION } = VIEW_OBJECT;
	const { MODE3D, CROSS } = INT_OBJECT;
	let showSecond = false;
	let showError = false;
	let showModal = false;
	let mapSet = new Set([0]);
	let header, body, className;
	let modalHeader, modalBody;
	let elevationFunctions;
	let canvas;

	//object to hold child map methods. Pretty slick and important for 
	//calling child methods
	let childFunctions = {};

	//multiple bindings to propagate nested child functions to this top level
	//needed <svelte:options accessors/> in Menu for this
	//let canvasFunctions;
	//handling dispatched events
	function handleMessage(event) {
		//code to update box of the map that WASN'T drawn on
		if (event.detail.value === "setBox") {
			const idxSet = new Set([event.detail.idx]);
			const difference = new Set([...mapSet].filter(x => !idxSet.has(x)));
			difference.forEach(x => childFunctions[x].handleMessage(event));
		} else if (event.detail.value === 'removeBox') {
			const mapArray = Array.from(mapSet);
			mapArray.forEach(x => childFunctions[x].handleMessage(event));
			canvas.removeMeshes(MODE3D);
		} else if (event.detail.value === "setLine") {
			const idxSet = new Set([event.detail.idx]);
			const difference = new Set([...mapSet].filter(x => !idxSet.has(x)));
			difference.forEach(x => childFunctions[x].handleMessage(event));
		} else if (event.detail.value === 'removeLine') {
			const mapArray = Array.from(mapSet);
			mapArray.forEach(x => childFunctions[x].handleMessage(event));
			canvas.removeMeshes(CROSS);
		} else if (event.detail.value === "readValue") {
			const idxSet = new Set([event.detail.idx]);
			const difference = new Set([...mapSet].filter(x => !idxSet.has(x)));
			difference.forEach(x => childFunctions[x].handleMessage(event));
		} else if (event.detail.value === "showHide") {
			const idxSet = new Set([event.detail.idx]);
			const difference = new Set([...mapSet].filter(x => !idxSet.has(x)));
			difference.forEach(x => childFunctions[x].handleMessage(event));
		} else if (event.detail.value === 'showSites' || event.detail.value === 'showAlerts' || event.detail.value === 'showStatements' || event.detail.value === 'showLightning') {
			for (const key in childFunctions) {
				childFunctions[key].handleMessage(event);
			}
		} else if (event.detail.value === "setDealiasState") {
			const mapArray = Array.from(mapSet);
			mapArray.forEach(x => childFunctions[x].handleMessage(event));
		} else if (event.detail.value === "loadTexture") {
			canvas.loadTexture();
		} else if (event.detail.value === "showError") {
			$$invalidate(4, header = $error.header);
			$$invalidate(5, body = $error.message);

			if ($error.color) {
				$$invalidate(6, className = "header-message");
			} else {
				$$invalidate(6, className = "header-error");
			}

			$$invalidate(2, showError = true);
		} else if (event.detail.value === "showModal") {
			$$invalidate(7, modalHeader = $modal.header);
			$$invalidate(8, modalBody = $modal.message);
			$$invalidate(3, showModal = true);
		} else if (event.detail.value === "hideError") {
			$$invalidate(2, showError = false);
		} else if (event.detail.value === "hideModal") {
			$$invalidate(3, showModal = false);
		} else {
			//pass message back down to relevant map instance
			childFunctions[event.detail.idx].handleMessage(event);
		}
	}

	function handleKeydown(e) {
		if (e.keyCode === 37) {
			if ($VIEW_STATE === ELEVATION) {
				animation.previous();
			}
		} else if (e.keyCode === 39) {
			if ($VIEW_STATE === ELEVATION) {
				animation.advance();
			}
		}
	}

	function menu_elevationFunctions_binding(value) {
		elevationFunctions = value;
		$$invalidate(9, elevationFunctions);
	}

	function menu_canvas_binding(value) {
		canvas = value;
		$$invalidate(10, canvas);
	}

	function map_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			childFunctions[0] = $$value;
			$$invalidate(11, childFunctions);
		});
	}

	function map_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			childFunctions[1] = $$value;
			$$invalidate(11, childFunctions);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$MAP_STATE*/ 1) {
			//on map state change, determine whether second map should be shown
			//and change mapSet accordingly
			$: if ($MAP_STATE === SINGLE_HIDDEN) {
				$$invalidate(1, showSecond = false);
				mapSet = new Set([0]);
			} else if ($MAP_STATE === DOUBLE) {
				$$invalidate(1, showSecond = true);
				mapSet = new Set([0, 1]);
			}
		}
	};

	return [
		$MAP_STATE,
		showSecond,
		showError,
		showModal,
		header,
		body,
		className,
		modalHeader,
		modalBody,
		elevationFunctions,
		canvas,
		childFunctions,
		$SPLIT_STATE,
		handleMessage,
		handleKeydown,
		menu_elevationFunctions_binding,
		menu_canvas_binding,
		map_binding,
		map_binding_1
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;