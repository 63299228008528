const shaders = {};

shaders.fsSource = `
    precision mediump float;
    varying float color;
    uniform sampler2D u_texture;
    uniform float u_min;
    uniform float u_max;
    uniform float u_opacity;

    void main() {
        float calcolor = (color-u_min)/(u_max - u_min);
        vec4 color = texture2D(u_texture,vec2(min(max(calcolor,0.0),1.0),0.0)).xyzw;
        //if below threshold, don't render (make transparent)
        gl_FragColor = vec4(color.x*u_opacity, color.y*u_opacity, color.z*u_opacity, color.w*u_opacity*step(0.0, calcolor));
    }
`
shaders.fsSourceFramebuffer = `
    precision highp float;
    varying float color;
    uniform float u_min;
    uniform float u_max;

    vec4 EncodeFloatRGBA( float v ) {
        vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
        enc = fract(enc);
        enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
        return enc;
    }

    void main() {
        vec4 encoded;

        //get on 0-1 scale
        float calcolor = min(1.0, max((color - u_min)/(u_max - u_min), 0.0));

        //if upper end, need this check because [0,1)
        if (abs(calcolor - 1.0) < 0.00001) {
            encoded = vec4(1.0,0.0,0.0,1.0);
        } else {
            encoded = EncodeFloatRGBA(calcolor);
            encoded.a = 1.0;
        }
       
        gl_FragColor = encoded;

    }

`

shaders.vsSource = `
    //x: azimuth
    //y: range
    //z: value
    attribute vec3 aPosition;
    attribute float aColor;
    
    uniform mat4 u_matrix;
    varying float color;

    void main() {
        color = aPosition.z;
        gl_Position = u_matrix * vec4(aPosition.x,aPosition.y,0.0,1.0);
    }
`

export { shaders };