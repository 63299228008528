import { worker, scans } from './store.js';
import { get } from '../_snowpack/pkg/svelte/store.js';

function Queue() {
   
    const processMap = new Map();
    let processing = false;

    function exists(key) {
        return processMap.has(key);
    }

    function deleteItem(key) {
        const returned = processMap.delete(key);
        if (returned) {
            processing = false;
            return 0;
        } else {
            return 1;
        }
    }

    function dequeue() {

        const localScans = get(scans);
        //console.log("dequeue scans", localScans);


        const fileNames = localScans.filter(d => d != undefined).map(d => d.fileName);
        //console.log("fileList", fileNames);

        //console.log("dquee", processMap, processMap.size);
        if (processMap.size === 0) {
            processing = false;
            return;
        }

        let firstKey, item;

        //prioritize non dealias fields
        for (const [key, value] of processMap) {
            if (value.item.field != 255) {
                firstKey = key;
                item = value;
                break;
            }
        }

        if (!firstKey) {
            //get first key (FIFO)
            [firstKey] = processMap.keys();
            item = processMap.get(firstKey);
        }

        //need to know if fileName still loaded
        //console.log("item dque", item);

        if (fileNames.indexOf(item.item.fileName) < 0) {
            //delete and return
            if (deleteItem(firstKey) === 0) dequeue();
            return;
        }

        const field = item.item.field;

        processing = true;

        if (field === 255) {
            get(worker).postMessage({
                message:"dealiasVelocity",
                data:item.item
            })
        } 
        else if (field === 1) {
            get(worker).postMessage({
                message:"loadVelocity",
                data:item.item
                }
            )
        }
        else if (field === 254) {
            //check if scans
            const fileNum = item.item.selections[0].file;
            if (item.item.scans[fileNum] === undefined) {
                //delete and return
                if (deleteItem(firstKey) === 0) dequeue();
                return;
            }


            //3d texture
            get(worker).postMessage({
                message:"set3d",
                data:item.item
            })
        }
        else {
            get(worker).postMessage({
                message:"loadData",
                data:item.item
            })
        }
    }

    function enqueue(item) {
        //console.log("item in queue", item);
        //unique key string
        const key = `${item.fileName}_${item.field}_${item.idx}`;
    
        if (exists(key)) {
            return 1;
        } else {
            processMap.set(key, {
                "status":"QUEUED",
                item:item
            })

            //console.log("before dequeue", processMap.size);

            //start processing if not
            //if (!processing) {
            if (processMap.size === 1) {
                dequeue();
            }

            return 0;
        }
    }

    return {
        enqueue,
        dequeue,
        deleteItem
    }

}

export { Queue }