import './About.svelte.css';
/* src/About.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div5;
	let div0;
	let t0;
	let div1;
	let t2;
	let figure0;
	let t5;
	let div2;
	let t21;
	let figure1;
	let t24;
	let div3;
	let t34;
	let div4;
	let span;
	let t37;
	let a3;
	let t38;
	let a4;
	let div5_class_value;

	return {
		c() {
			div5 = element("div");
			div0 = element("div");
			div0.innerHTML = `<a href="https://quadweather.com" target="_blank"><img class="image svelte-3wvpad" width="200" height="56" src="qwlogo.png" alt="logo"/></a>`;
			t0 = space();
			div1 = element("div");

			div1.innerHTML = `<p class="svelte-3wvpad">Viewing NEXRAD Level II files typically requires downloading software
            that only works on a specific operating system with difficult dependencies. With 
            this application, you can read and view Level II files in the browser, no installation
            necessary. And you can inspect the data in ways that usually require expensive licenses or subscriptions. Use 
            this site to analyze historical data or monitor weather events in real time.</p>`;

			t2 = space();
			figure0 = element("figure");

			figure0.innerHTML = `<img class="heroimage svelte-3wvpad" width="550" height="232" src="cross-hail.png" alt="logo"/> 
        <figcaption class="svelte-3wvpad">Cross section through a supercell in Hondo, Texas on 29 April 2021 that produced a 6.4&quot; hailstone.</figcaption>`;

			t5 = space();
			div2 = element("div");

			div2.innerHTML = `<h3 class="svelte-3wvpad">Steps for Viewing Historical Data</h3> 
        <ol class="svelte-3wvpad"><li class="svelte-3wvpad">Locate NEXRAD Level II archive files. The best source for downloading data is the <a href="https://s3.amazonaws.com/noaa-nexrad-level2/index.html" target="_blank">AWS Explorer</a>. 
                A <a href="/KLWX20120630_030520_V06.gz">sample file</a> is also available for the 29 June 2012 derecho.</li> 
            <li class="svelte-3wvpad">Load the file into the application by either dragging and dropping it into the designated area or choosing files from your file system.</li> 
            <li class="svelte-3wvpad">The default view is the Elevation View. The application will read the file to determine which elevation scans are present. Click
                on an elevation to view scan data. The default field is Reflectivity, but all fields present in the file can be selected in the map dropdown.</li> 
            <li class="svelte-3wvpad">View the Reflectivity in 3D by clicking the 3D View button in the top bar then dragging a bounding box over the map. View a cross section 
                by clicking the Cross Section button and dragging a line across the map.</li> 
            <li class="svelte-3wvpad">The application can load up to five files. Remove a file from the application by entering the File View and clicking the trashcan icon next to the file name.</li></ol>`;

			t21 = space();
			figure1 = element("figure");

			figure1.innerHTML = `<img class="heroimage svelte-3wvpad" width="550" height="238" src="harvey.png" alt="logo"/> 
        <figcaption class="svelte-3wvpad">3D view of Hurricane Harvey as it made landfall in Texas on 26 August 2017.</figcaption>`;

			t24 = space();
			div3 = element("div");

			div3.innerHTML = `<h3 class="svelte-3wvpad">FAQs</h3> 
        <p class="q svelte-3wvpad">The application isn&#39;t working on my computer, why?</p> 
        <p class="a svelte-3wvpad">This application uses modern web elements like WebAssembly and WebGL2. It requires an up-to-date, modern browser, and
            a computer with a good amount of memory. It will not work on mobile.</p> 
        <p class="q svelte-3wvpad">Why does it take so long to load velocity data?</p> 
        <p class="a svelte-3wvpad">You likely have the application in Dealias mode. Level II data has only been lightly post-processed.
            Often, the raw velocity field suffers from a problem called &quot;aliasing&quot;, which means values fold from positive to negative
            and vice versa. Aliasing makes the velocity field impossible to interpret, so a dealiasing step is necessary. The math to
            perform dealiasing is complex, and your computer is doing heavy-duty calculations to correct the velocity field. The calculation
            can take 10 seconds or more at times.</p>`;

			t34 = space();
			div4 = element("div");
			span = element("span");
			span.textContent = `${email1}${email2}`;
			t37 = space();
			a3 = element("a");
			a3.innerHTML = `<img class="twitter svelte-3wvpad" src="logotwitter.png" alt="twitter"/>`;
			t38 = space();
			a4 = element("a");
			a4.textContent = "@QuadWeather";
			attr(div0, "class", "logo svelte-3wvpad");
			attr(div1, "class", "group svelte-3wvpad");
			attr(figure0, "class", "hero svelte-3wvpad");
			attr(div2, "class", "steps svelte-3wvpad");
			attr(figure1, "class", "hero svelte-3wvpad");
			attr(div3, "class", "faq svelte-3wvpad");
			attr(span, "class", "email svelte-3wvpad");
			attr(a3, "class", "twitter svelte-3wvpad");
			attr(a3, "href", "https://twitter.com/QuadWeather");
			attr(a4, "class", "email svelte-3wvpad");
			attr(a4, "href", "https://twitter.com/QuadWeather");
			attr(div4, "class", "footer svelte-3wvpad");
			attr(div5, "class", div5_class_value = "about-container " + (/*show*/ ctx[0] ? '' : 'hide') + " svelte-3wvpad");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div0);
			append(div5, t0);
			append(div5, div1);
			append(div5, t2);
			append(div5, figure0);
			append(div5, t5);
			append(div5, div2);
			append(div5, t21);
			append(div5, figure1);
			append(div5, t24);
			append(div5, div3);
			append(div5, t34);
			append(div5, div4);
			append(div4, span);
			append(div4, t37);
			append(div4, a3);
			append(div4, t38);
			append(div4, a4);
		},
		p(ctx, [dirty]) {
			if (dirty & /*show*/ 1 && div5_class_value !== (div5_class_value = "about-container " + (/*show*/ ctx[0] ? '' : 'hide') + " svelte-3wvpad")) {
				attr(div5, "class", div5_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div5);
		}
	};
}

let email1 = "QuadWx";
let email2 = "@gmail.com";

function instance($$self, $$props, $$invalidate) {
	let { show } = $$props;

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(0, show = $$props.show);
	};

	return [show];
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { show: 0 });
	}
}

export default About;