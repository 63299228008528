import './Appearance.svelte.css';
/* src/Appearance.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	bubble,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_store_value,
	space,
	text,
	to_number,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import {
	STYLE,
	dataSettings,
	error,
	localStorage,
	colorTables,
	SPLIT_STATE
} from "./store.js";

import { createColorbarAppearance } from "./colorbars.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[36] = list[i];
	child_ctx[38] = i;
	return child_ctx;
}

// (304:4) {#if value === "custom"}
function create_if_block_2(ctx) {
	let div;
	let form;
	let label0;
	let t2;
	let input0;
	let input0_value_value;
	let t3;
	let label1;
	let t6;
	let input1;
	let input1_value_value;
	let t7;
	let input2;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			form = element("form");
			label0 = element("label");
			label0.innerHTML = `Mapbox public <a href="https://docs.mapbox.com/api/maps/styles/" target="_blank">style URL</a>`;
			t2 = space();
			input0 = element("input");
			t3 = space();
			label1 = element("label");
			label1.innerHTML = `Layer to render before <span class="optional svelte-11kzt0w"><i>(optional)</i></span>`;
			t6 = space();
			input1 = element("input");
			t7 = space();
			input2 = element("input");
			attr(label0, "class", "man svelte-11kzt0w");
			attr(label0, "for", "styleid");
			attr(input0, "class", "text-input svelte-11kzt0w");
			attr(input0, "type", "text");
			attr(input0, "id", "styleid");
			attr(input0, "name", "styleid");

			input0.value = input0_value_value = /*$STYLE*/ ctx[6].value === "custom"
			? /*$STYLE*/ ctx[6].styleid
			: '';

			attr(label1, "class", "man svelte-11kzt0w");
			attr(label1, "for", "layer");
			attr(input1, "class", "text-input svelte-11kzt0w");
			attr(input1, "type", "text");
			attr(input1, "id", "layer");
			attr(input1, "name", "layer");

			input1.value = input1_value_value = /*$STYLE*/ ctx[6].value === "custom"
			? /*$STYLE*/ ctx[6].layer
			: '';

			attr(input2, "class", "b svelte-11kzt0w");
			attr(input2, "type", "button");
			input2.value = "Load Style";
			attr(form, "autocomplete", "off");
			attr(div, "class", "custom-container svelte-11kzt0w");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, form);
			append(form, label0);
			append(form, t2);
			append(form, input0);
			append(form, t3);
			append(form, label1);
			append(form, t6);
			append(form, input1);
			append(form, t7);
			append(form, input2);

			if (!mounted) {
				dispose = listen(input2, "click", /*handleClick*/ ctx[8]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$STYLE*/ 64 && input0_value_value !== (input0_value_value = /*$STYLE*/ ctx[6].value === "custom"
			? /*$STYLE*/ ctx[6].styleid
			: '') && input0.value !== input0_value_value) {
				input0.value = input0_value_value;
			}

			if (dirty[0] & /*$STYLE*/ 64 && input1_value_value !== (input1_value_value = /*$STYLE*/ ctx[6].value === "custom"
			? /*$STYLE*/ ctx[6].layer
			: '') && input1.value !== input1_value_value) {
				input1.value = input1_value_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (333:4) {#if $colorTables != 'unsupported'}
function create_if_block(ctx) {
	let p0;
	let t1;
	let p1;
	let t8;
	let div0;
	let input0;
	let t9;
	let label;
	let input1;
	let t10;
	let t11;
	let div1;
	let mounted;
	let dispose;
	let each_value = /*canvases*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			p0 = element("p");
			p0.textContent = "Color Tables";
			t1 = space();
			p1 = element("p");

			p1.innerHTML = `Add a custom Reflectivity color table file in the <a href="https://www.grlevelx.com/manuals/color_tables/files_color_table.htm" target="_blank">expected format</a>. 
        Sample files are available at <a href="https://www.wxtools.org/reflectivity" target="_blank">Wx Tools</a>.<br/><br/>Custom color tables are a beta feature, 
        and only Reflectivity is currently supported (the Product in the color table file must be specified as &quot;BR&quot;).`;

			t8 = space();
			div0 = element("div");
			input0 = element("input");
			t9 = space();
			label = element("label");
			input1 = element("input");
			t10 = text("\n            Add Color Table File");
			t11 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(p0, "class", "setting svelte-11kzt0w");
			attr(p1, "class", "about-color svelte-11kzt0w");
			attr(input0, "type", "file");
			attr(input0, "id", "input");
			input0.multiple = true;
			attr(input0, "class", "svelte-11kzt0w");
			attr(input1, "type", "file");
			attr(input1, "class", "svelte-11kzt0w");
			attr(label, "class", "custom-file-upload svelte-11kzt0w");
			attr(div0, "class", "upload-button svelte-11kzt0w");
			attr(div1, "class", "table-div svelte-11kzt0w");
		},
		m(target, anchor) {
			insert(target, p0, anchor);
			insert(target, t1, anchor);
			insert(target, p1, anchor);
			insert(target, t8, anchor);
			insert(target, div0, anchor);
			append(div0, input0);
			append(div0, t9);
			append(div0, label);
			append(label, input1);
			/*input1_binding*/ ctx[25](input1);
			append(label, t10);
			/*label_binding*/ ctx[26](label);
			insert(target, t11, anchor);
			insert(target, div1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			if (!mounted) {
				dispose = listen(input1, "change", /*handleFile*/ ctx[9]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*deleteTable, canvases, $colorTables, selectColor*/ 3108) {
				each_value = /*canvases*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(p0);
			if (detaching) detach(t1);
			if (detaching) detach(p1);
			if (detaching) detach(t8);
			if (detaching) detach(div0);
			/*input1_binding*/ ctx[25](null);
			/*label_binding*/ ctx[26](null);
			if (detaching) detach(t11);
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

// (355:16) {#if canvas.id != 1}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	function click_handler_1() {
		return /*click_handler_1*/ ctx[28](/*canvas*/ ctx[36]);
	}

	return {
		c() {
			button = element("button");

			button.innerHTML = `<svg class="trash svelte-11kzt0w" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 468.36 468.36" style="enable-background:new 0 0 468.36 468.36;" xml:space="preserve"><g><g><path d="M381.048,64.229l-71.396,0.031L309.624,0L158.666,0.064l0.027,64.26l-71.405,0.031l0.024,60.056h293.76L381.048,64.229z
                                    M189.274,30.652l89.759-0.04l0.016,33.66l-89.759,0.04L189.274,30.652z"></path><path d="M87.312,468.36h293.76V139.71H87.312V468.36z M303.042,184.588h15.301v238.891h-15.301V184.588z M226.542,184.588h15.3
                                    v238.891h-15.3V184.588z M150.042,184.588h15.3v238.891h-15.3V184.588z"></path></g></g></svg>`;

			attr(button, "class", "trashbutton svelte-11kzt0w");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (349:8) {#each canvases as canvas, index}
function create_each_block(ctx) {
	let div1;
	let p;
	let t0_value = /*canvas*/ ctx[36].name + "";
	let t0;
	let t1;
	let div0;
	let raw_value = `<img class="colorbar-image" src="${/*canvas*/ ctx[36].image}">` + "";
	let t2;
	let t3;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[27](/*canvas*/ ctx[36]);
	}

	let if_block = /*canvas*/ ctx[36].id != 1 && create_if_block_1(ctx);

	return {
		c() {
			div1 = element("div");
			p = element("p");
			t0 = text(t0_value);
			t1 = space();
			div0 = element("div");
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();
			attr(p, "class", "colorbar-name svelte-11kzt0w");
			attr(div0, "class", "wrapper svelte-11kzt0w");
			toggle_class(div0, "active", /*$colorTables*/ ctx[5].br.current === /*index*/ ctx[38]);
			attr(div1, "class", "colorbar-entry svelte-11kzt0w");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, p);
			append(p, t0);
			append(div1, t1);
			append(div1, div0);
			div0.innerHTML = raw_value;
			append(div1, t2);
			if (if_block) if_block.m(div1, null);
			append(div1, t3);

			if (!mounted) {
				dispose = listen(div0, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*canvases*/ 4 && t0_value !== (t0_value = /*canvas*/ ctx[36].name + "")) set_data(t0, t0_value);
			if (dirty[0] & /*canvases*/ 4 && raw_value !== (raw_value = `<img class="colorbar-image" src="${/*canvas*/ ctx[36].image}">` + "")) div0.innerHTML = raw_value;;

			if (dirty[0] & /*$colorTables*/ 32) {
				toggle_class(div0, "active", /*$colorTables*/ ctx[5].br.current === /*index*/ ctx[38]);
			}

			if (/*canvas*/ ctx[36].id != 1) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div1, t3);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let p0;
	let t1;
	let div0;
	let label0;
	let input0;
	let t2;
	let t3;
	let label1;
	let input1;
	let t4;
	let t5;
	let label2;
	let input2;
	let t6;
	let t7;
	let label3;
	let input3;
	let t8;
	let t9;
	let t10;
	let p1;
	let t12;
	let div1;
	let label4;
	let input4;
	let t13;
	let t14;
	let label5;
	let input5;
	let t15;
	let t16;
	let p2;
	let t18;
	let div2;
	let span0;
	let t20;
	let input6;
	let t21;
	let span1;
	let t23;
	let mounted;
	let dispose;
	let if_block0 = /*value*/ ctx[0] === "custom" && create_if_block_2(ctx);
	let if_block1 = /*$colorTables*/ ctx[5] != 'unsupported' && create_if_block(ctx);

	return {
		c() {
			div3 = element("div");
			p0 = element("p");
			p0.textContent = "Basemap";
			t1 = space();
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t2 = text("\n            Default");
			t3 = space();
			label1 = element("label");
			input1 = element("input");
			t4 = text("\n            Satellite");
			t5 = space();
			label2 = element("label");
			input2 = element("input");
			t6 = text("\n            Traffic");
			t7 = space();
			label3 = element("label");
			input3 = element("input");
			t8 = text("\n            Custom");
			t9 = space();
			if (if_block0) if_block0.c();
			t10 = space();
			p1 = element("p");
			p1.textContent = "Dual Map Split";
			t12 = space();
			div1 = element("div");
			label4 = element("label");
			input4 = element("input");
			t13 = text("\n            Horizontal");
			t14 = space();
			label5 = element("label");
			input5 = element("input");
			t15 = text("\n            Vertical");
			t16 = space();
			p2 = element("p");
			p2.textContent = "Radar Data Opacity";
			t18 = space();
			div2 = element("div");
			span0 = element("span");
			span0.textContent = "transparent";
			t20 = space();
			input6 = element("input");
			t21 = space();
			span1 = element("span");
			span1.textContent = "opaque";
			t23 = space();
			if (if_block1) if_block1.c();
			attr(p0, "class", "setting basemap svelte-11kzt0w");
			attr(input0, "class", "app-radio svelte-11kzt0w");
			attr(input0, "type", "radio");
			input0.__value = "monochrome";
			input0.value = input0.__value;
			/*$$binding_groups*/ ctx[18][0].push(input0);
			attr(label0, "class", "split svelte-11kzt0w");
			attr(input1, "class", "app-radio svelte-11kzt0w");
			attr(input1, "type", "radio");
			input1.__value = "satellite";
			input1.value = input1.__value;
			/*$$binding_groups*/ ctx[18][0].push(input1);
			attr(label1, "class", "split svelte-11kzt0w");
			attr(input2, "class", "app-radio svelte-11kzt0w");
			attr(input2, "type", "radio");
			input2.__value = "traffic";
			input2.value = input2.__value;
			/*$$binding_groups*/ ctx[18][0].push(input2);
			attr(label2, "class", "split svelte-11kzt0w");
			attr(input3, "class", "app-radio svelte-11kzt0w");
			attr(input3, "type", "radio");
			input3.__value = "custom";
			input3.value = input3.__value;
			/*$$binding_groups*/ ctx[18][0].push(input3);
			attr(label3, "class", "split svelte-11kzt0w");
			attr(div0, "class", "nested svelte-11kzt0w");
			attr(p1, "class", "setting svelte-11kzt0w");
			attr(input4, "class", "app-radio svelte-11kzt0w");
			attr(input4, "type", "radio");
			attr(input4, "name", "layout");
			input4.__value = "horizontal";
			input4.value = input4.__value;
			/*$$binding_groups*/ ctx[18][1].push(input4);
			attr(label4, "class", "split svelte-11kzt0w");
			attr(input5, "class", "app-radio split svelte-11kzt0w");
			attr(input5, "type", "radio");
			attr(input5, "name", "layout");
			input5.__value = "vertical";
			input5.value = input5.__value;
			/*$$binding_groups*/ ctx[18][1].push(input5);
			attr(label5, "class", "split svelte-11kzt0w");
			attr(div1, "class", "nested svelte-11kzt0w");
			attr(p2, "class", "setting svelte-11kzt0w");
			attr(span0, "class", "svelte-11kzt0w");
			attr(input6, "type", "range");
			attr(input6, "step", "0.01");
			attr(input6, "class", "slider svelte-11kzt0w");
			attr(input6, "id", "opacity");
			attr(input6, "name", "opacity");
			attr(input6, "min", "0");
			attr(input6, "max", "1");
			attr(span1, "class", "svelte-11kzt0w");
			attr(div2, "class", "slider-container svelte-11kzt0w");
			attr(div3, "class", "container svelte-11kzt0w");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, p0);
			append(div3, t1);
			append(div3, div0);
			append(div0, label0);
			append(label0, input0);
			input0.checked = input0.__value === /*value*/ ctx[0];
			append(label0, t2);
			append(div0, t3);
			append(div0, label1);
			append(label1, input1);
			input1.checked = input1.__value === /*value*/ ctx[0];
			append(label1, t4);
			append(div0, t5);
			append(div0, label2);
			append(label2, input2);
			input2.checked = input2.__value === /*value*/ ctx[0];
			append(label2, t6);
			append(div0, t7);
			append(div0, label3);
			append(label3, input3);
			input3.checked = input3.__value === /*value*/ ctx[0];
			append(label3, t8);
			append(div3, t9);
			if (if_block0) if_block0.m(div3, null);
			append(div3, t10);
			append(div3, p1);
			append(div3, t12);
			append(div3, div1);
			append(div1, label4);
			append(label4, input4);
			input4.checked = input4.__value === /*$SPLIT_STATE*/ ctx[7];
			append(label4, t13);
			append(div1, t14);
			append(div1, label5);
			append(label5, input5);
			input5.checked = input5.__value === /*$SPLIT_STATE*/ ctx[7];
			append(label5, t15);
			append(div3, t16);
			append(div3, p2);
			append(div3, t18);
			append(div3, div2);
			append(div2, span0);
			append(div2, t20);
			append(div2, input6);
			set_input_value(input6, /*opacity*/ ctx[1]);
			append(div2, t21);
			append(div2, span1);
			append(div3, t23);
			if (if_block1) if_block1.m(div3, null);

			if (!mounted) {
				dispose = [
					listen(input0, "keydown", prevent_default(/*keydown_handler*/ ctx[16])),
					listen(input0, "change", /*input0_change_handler*/ ctx[17]),
					listen(input1, "keydown", prevent_default(/*keydown_handler_1*/ ctx[15])),
					listen(input1, "change", /*input1_change_handler*/ ctx[19]),
					listen(input2, "keydown", prevent_default(/*keydown_handler_2*/ ctx[14])),
					listen(input2, "change", /*input2_change_handler*/ ctx[20]),
					listen(input3, "keydown", prevent_default(/*keydown_handler_3*/ ctx[13])),
					listen(input3, "change", /*input3_change_handler*/ ctx[21]),
					listen(input4, "change", /*input4_change_handler*/ ctx[22]),
					listen(input4, "change", /*handleSplit*/ ctx[12]),
					listen(input5, "change", /*input5_change_handler*/ ctx[23]),
					listen(input5, "change", /*handleSplit*/ ctx[12]),
					listen(input6, "change", /*input6_change_input_handler*/ ctx[24]),
					listen(input6, "input", /*input6_change_input_handler*/ ctx[24])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*value*/ 1) {
				input0.checked = input0.__value === /*value*/ ctx[0];
			}

			if (dirty[0] & /*value*/ 1) {
				input1.checked = input1.__value === /*value*/ ctx[0];
			}

			if (dirty[0] & /*value*/ 1) {
				input2.checked = input2.__value === /*value*/ ctx[0];
			}

			if (dirty[0] & /*value*/ 1) {
				input3.checked = input3.__value === /*value*/ ctx[0];
			}

			if (/*value*/ ctx[0] === "custom") {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div3, t10);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty[0] & /*$SPLIT_STATE*/ 128) {
				input4.checked = input4.__value === /*$SPLIT_STATE*/ ctx[7];
			}

			if (dirty[0] & /*$SPLIT_STATE*/ 128) {
				input5.checked = input5.__value === /*$SPLIT_STATE*/ ctx[7];
			}

			if (dirty[0] & /*opacity*/ 2) {
				set_input_value(input6, /*opacity*/ ctx[1]);
			}

			if (/*$colorTables*/ ctx[5] != 'unsupported') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div3, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			/*$$binding_groups*/ ctx[18][0].splice(/*$$binding_groups*/ ctx[18][0].indexOf(input0), 1);
			/*$$binding_groups*/ ctx[18][0].splice(/*$$binding_groups*/ ctx[18][0].indexOf(input1), 1);
			/*$$binding_groups*/ ctx[18][0].splice(/*$$binding_groups*/ ctx[18][0].indexOf(input2), 1);
			/*$$binding_groups*/ ctx[18][0].splice(/*$$binding_groups*/ ctx[18][0].indexOf(input3), 1);
			if (if_block0) if_block0.d();
			/*$$binding_groups*/ ctx[18][1].splice(/*$$binding_groups*/ ctx[18][1].indexOf(input4), 1);
			/*$$binding_groups*/ ctx[18][1].splice(/*$$binding_groups*/ ctx[18][1].indexOf(input5), 1);
			if (if_block1) if_block1.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $colorTables;
	let $error;
	let $dataSettings;
	let $STYLE;
	let $SPLIT_STATE;
	component_subscribe($$self, colorTables, $$value => $$invalidate(5, $colorTables = $$value));
	component_subscribe($$self, error, $$value => $$invalidate(30, $error = $$value));
	component_subscribe($$self, dataSettings, $$value => $$invalidate(31, $dataSettings = $$value));
	component_subscribe($$self, STYLE, $$value => $$invalidate(6, $STYLE = $$value));
	component_subscribe($$self, SPLIT_STATE, $$value => $$invalidate(7, $SPLIT_STATE = $$value));
	let value = $STYLE.value;
	let opacity = $dataSettings.opacity;
	let canvases = [];
	let pButton;
	let fileInput;

	if ($colorTables != 'unsupported') {
		canvases = $colorTables.br.all;
	}

	const reader = new FileReader();

	const styles = {
		"monochrome": "mapbox://styles/quadweather/cksxhy0l281db18nvnnfvdigv",
		"satellite": "mapbox://styles/mapbox/satellite-streets-v12",
		"traffic": "mapbox://styles/mapbox/navigation-night-v1"
	};

	function handleChange() {
		//don't use this function for custom
		if (value === "custom") return;

		let layer;

		if (value === "satellite") {
			layer = 'tunnel-minor-case';
		}

		if (value === "monochrome") {
			layer = 'land-structure-line';
		}

		if (value === "traffic") {
			layer = 'turning-feature-outline-navigation';
		}

		set_store_value(STYLE, $STYLE = { value, styleid: styles[value], layer }, $STYLE);
	}

	function triggerError(styleid) {
		//dialog saying error loading style
		error.update(d => {
			d.error = true;
			d.message = `There was an error loading the style ${styleid}. Please check that the style URL is correct and that the style is public.`;
			d.header = "Style not loaded";
			d.color = "normal";
			return d;
		});
	}

	async function handleClick(e) {
		const styleid = document.getElementById("styleid").value;
		const layer = document.getElementById("layer").value;

		if (styleid) {
			const re = /mapbox:\/\/styles\/([a-zA-Z0-9\-]*)\/([\s\S]*)/i;
			const found = styleid.match(re);

			if (!found || found.length != 3) {
				triggerError(styleid);
				return;
			}

			let success;

			await fetch(`https://api.mapbox.com/styles/v1/${found[1]}/${found[2]}?access_token=pk.eyJ1IjoicXVhZHdlYXRoZXIiLCJhIjoiY2w2YXZ4YjAzMWdmODNkbWx5YmwycjcxaSJ9.DOMnL520hn-dTFcGgkH2BQ`).then(response => {
				if (!response.ok) {
					throw new Error("Network response was not OK");
				}

				success = 1;
			}).catch(error => {
				success = 0;
			});

			if (!success) {
				triggerError(styleid);
				return;
			}

			set_store_value(STYLE, $STYLE = { value, styleid, layer }, $STYLE);
		}
	}

	function handleSlider() {
		const newObject = { ...$dataSettings };
		newObject.opacity = opacity;
		set_store_value(dataSettings, $dataSettings = newObject, $dataSettings);
	}

	async function handleFile(e) {
		if (this.files.length) {
			const file = this.files[0];

			reader.onload = async function (event) {
				const contents = event.target.result;
				const name = file.name;
				const lines = contents.split('\n');
				let product = null;
				let scale = null;
				let units = null;
				const regex = /;.*$/;
				const filteredLines = lines.map(line => line.trim()).filter(line => line && !line.startsWith(';')).map(line => line.replace(regex, '')).map(line => line.split(":"));

				for (let i = 0; i < filteredLines.length; i++) {
					if (filteredLines[i].length === 2 && filteredLines[i][0].trim().toLowerCase() === "product" && filteredLines[i][1].trim().toLowerCase() != "br") {
						//raise error about not accepting color tables other than reflectivity
						set_store_value(
							error,
							$error = {
								error: true,
								message: 'Only Reflectivity (BR) is currently supported',
								header: 'Unsupported product'
							},
							$error
						);

						return;
					}

					if (filteredLines[i].length === 2 && filteredLines[i][0].trim().toLowerCase() === "product") {
						product = filteredLines[i][1].trim().toLowerCase();
					}

					if (filteredLines[i].length === 2 && filteredLines[i][0].trim().toLowerCase() === "scale") {
						scale = parseFloat(filteredLines[i][1]);
					}

					if (filteredLines[i].length === 2 && filteredLines[i][0].trim().toLowerCase() === "units") {
						units = filteredLines[i][1].trim().toLowerCase();
					}
				}

				if (product === null) {
					set_store_value(
						error,
						$error = {
							error: true,
							message: 'The supplied color table file is incomplete',
							header: 'Missing required properties'
						},
						$error
					);

					return;
				}

				let output = [];

				for (let i = 0; i < filteredLines.length; i++) {
					//should be length 2 because key: value setup
					if (filteredLines[i].length === 2) {
						filteredLines[i][0] = filteredLines[i][0].trim().toLowerCase();
						filteredLines[i][1] = filteredLines[i][1].trim();
						const toParse = filteredLines[i][1];

						const parsed = toParse.split(/\s+/).//should be numeric
						filter(value => !isNaN(parseFloat(value))).map(value => parseFloat(value));

						const floatValue = parsed.shift();

						//rgb one stop
						if (filteredLines[i][0] === "color" && parsed.length === 3) {
							output.push([floatValue, parsed]);
						} else if (filteredLines[i][0] === "color" && parsed.length === 6) {
							const first = parsed.slice(0, 3);
							const second = parsed.slice(3);
							output.push([floatValue, first, second]);
						} else if (filteredLines[i][0] === "color4" && parsed.length === 4) {
							parsed[parsed.length - 1] = parsed[parsed.length - 1] / 255.0;
							output.push([floatValue, parsed]);
						} else if (filteredLines[i][0] === "color4" && parsed.length === 8) {
							const first = parsed.slice(0, 4);
							first[first.length - 1] = first[first.length - 1] / 255.0;
							const second = parsed.slice(4);
							second[second.length - 1] = second[second.length - 1] / 255.0;
							output.push([floatValue, first, second]);
						} else if (filteredLines[i][0] === "solidcolor" && parsed.length === 3) {
							output.push([floatValue, parsed, parsed]);
						} else if (filteredLines[i][0] === "solidcolor4" && parsed.length === 4) {
							parsed[parsed.length - 1] = parsed[parsed.length - 1] / 255.0;
							output.push([floatValue, parsed, parsed]);
						}
					}
				}

				//save to indexeddb
				const key = await localStorage.setColorValue('colorTables', output, name.split(".")[0], product, scale, units);

				const colorData = createColorbarAppearance(output, name.split(".")[0], key, product, scale, units);

				//add data
				const newObject = { ...$colorTables };

				newObject[product].all.push(colorData);
				set_store_value(colorTables, $colorTables = newObject, $colorTables);
				$$invalidate(2, canvases = $colorTables[product].all);
			};

			reader.readAsText(file);
		}

		$$invalidate(4, fileInput.value = null, fileInput);
	}

	function selectColor(value) {
		const all = $colorTables[value.product].all;

		for (let i = 0; i < all.length; i++) {
			if (all[i].id === value.id) {
				set_store_value(colorTables, $colorTables[value.product].current = i, $colorTables);
				break;
			}
		}

		localStorage.selectColor('colorTables', value);
	}

	async function deleteTable(value) {
		//check if current
		let current;

		let tables = $colorTables[value.product].all;
		let toRemove;

		for (let i = 0; i < tables.length; i++) {
			if (tables[i].id === value.id) {
				toRemove = i;
				break;
			}
		}

		if ($colorTables[value.product].all[$colorTables[value.product].current].id === value.id) {
			current = true;
		}

		$colorTables[value.product].all.splice(toRemove, 1);

		//make sure you account for splicing
		if ($colorTables[value.product].current >= toRemove) {
			set_store_value(colorTables, $colorTables[value.product].current -= 1, $colorTables);
		}

		$$invalidate(2, canvases = $colorTables[value.product].all);
		await localStorage.deleteColor('colorTables', value);

		if (current) {
			//set to default (always index 0)
			localStorage.selectColor('colorTables', $colorTables[value.product].all[0]);

			set_store_value(colorTables, $colorTables[value.product].current = 0, $colorTables);
		}
	}

	function handleSplit(event) {
		SPLIT_STATE.set(event.target.value);
	}

	const $$binding_groups = [[], []];

	function keydown_handler_3(event) {
		bubble.call(this, $$self, event);
	}

	function keydown_handler_2(event) {
		bubble.call(this, $$self, event);
	}

	function keydown_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function keydown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function input0_change_handler() {
		value = this.__value;
		$$invalidate(0, value);
	}

	function input1_change_handler() {
		value = this.__value;
		$$invalidate(0, value);
	}

	function input2_change_handler() {
		value = this.__value;
		$$invalidate(0, value);
	}

	function input3_change_handler() {
		value = this.__value;
		$$invalidate(0, value);
	}

	function input4_change_handler() {
		$SPLIT_STATE = this.__value;
		SPLIT_STATE.set($SPLIT_STATE);
	}

	function input5_change_handler() {
		$SPLIT_STATE = this.__value;
		SPLIT_STATE.set($SPLIT_STATE);
	}

	function input6_change_input_handler() {
		opacity = to_number(this.value);
		$$invalidate(1, opacity);
	}

	function input1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			fileInput = $$value;
			$$invalidate(4, fileInput);
		});
	}

	function label_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			pButton = $$value;
			$$invalidate(3, pButton);
		});
	}

	const click_handler = canvas => selectColor(canvas);
	const click_handler_1 = canvas => deleteTable(canvas);

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*value*/ 1) {
			$: (value, handleChange());
		}

		if ($$self.$$.dirty[0] & /*opacity*/ 2) {
			$: (opacity, handleSlider());
		}
	};

	return [
		value,
		opacity,
		canvases,
		pButton,
		fileInput,
		$colorTables,
		$STYLE,
		$SPLIT_STATE,
		handleClick,
		handleFile,
		selectColor,
		deleteTable,
		handleSplit,
		keydown_handler_3,
		keydown_handler_2,
		keydown_handler_1,
		keydown_handler,
		input0_change_handler,
		$$binding_groups,
		input1_change_handler,
		input2_change_handler,
		input3_change_handler,
		input4_change_handler,
		input5_change_handler,
		input6_change_input_handler,
		input1_binding,
		label_binding,
		click_handler,
		click_handler_1
	];
}

class Appearance extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

export default Appearance;