function LocalStorage() {

    const oldVersion = 1;
    const version = 1;
    let db;

    async function deleteDb() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", oldVersion);
            request.onerror = function(event) {
                reject("error " + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                db.close();

                const deleteRequest = indexedDB.deleteDatabase("db");
                deleteRequest.onsuccess = async function(event) {
                    //recreate database
                    //const result = await createDb();
                    resolve("deleted and recreated");
                }
                deleteRequest.onerror = function(event) {
                    reject("error deleting db");
                }
                //resolve("Database created successfully");
            }

        })
    }

    function createDb() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", version);
            request.onerror = function(event) {
                reject("error " + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                resolve("Database created successfully");
            }

            request.onupgradeneeded = function(event) {
             
                db = event.target.result;
                //create object store
                const objectStoreName = 'colorTables';
                if (!db.objectStoreNames.contains(objectStoreName)) {
                    const objectStore = db.createObjectStore(objectStoreName, {keyPath:"id", autoIncrement: true});
                    objectStore.createIndex('nameIndex', 'name', {unique:false});

                    const initialObject = {
                        name: 'default',
                        colorStops: [
                            [0, [36,36,36]], //0
                            [10, [20,70,108]], //10
                            [20, [15,203,255]], //20
                            [25, [29,158,0]], //25
                            [30, [149,244,76]], //30
                            [35, [255,247,0]],  //35
                            [40, [255, 159, 15]], //40
                            [45,[235, 121, 0]], //45
                            [50,[255, 31, 31]],//50
                            [60,[173, 17, 0]], //60
                            [65,[255, 184, 245]],//65
                            [70,[202, 87, 255]],//70
                            [80,[74, 0, 158]]//80
                        ],
                        selected:true,
                        product:'br',
                        scale:null,
                        units:"dbz"
                    }

                    objectStore.add(initialObject);

                    resolve("added initial object")
                }
            }
        })
    }

    function setColorValue(objectStoreName, values, name, product, scale, units) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", version);

            request.onerror = function(event) {
                reject("error" + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                const transaction = db.transaction(objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(objectStoreName);

                const addRequest = objectStore.add({name:name, colorStops:values, selected:false, product:product,
                    scale:scale,
                    units:units
                });

                addRequest.onsuccess = function(event) {
                    resolve(event.target.result);
                }
    
                addRequest.onerror = function(event) {
                    reject("error " + event.target.errorCode);
                }
            }

        })
    }

    function getAllColors(objectStoreName) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", version);
            request.onerror = function(event) {
                reject("error" + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                const transaction = db.transaction(objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(objectStoreName);

                const cursorRequest = objectStore.getAll();

                cursorRequest.onsuccess = function(event) {
                    const objects = cursorRequest.result;
                    resolve(objects);
                    // const cursor = event.target.result;
                    // if (cursor) {
                    //     objects.push(cursor.value);
                    //     cursor.continue();
                    // } else {
                    //     resolve(objects);
                    // }
                }

                cursorRequest.onerror = function(event) {
                    reject("error" + event.target.errorCode);
                }
            }
        })
    }

    function selectColor(objectStoreName, value) {
        
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", version);
            request.onerror = function(event) {
                reject("error" + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                const transaction = db.transaction(objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(objectStoreName);

                const cursorRequest = objectStore.getAll();

                cursorRequest.onsuccess = function(event) {
                    const objects = cursorRequest.result;
                    for (let i=0; i<objects.length; i++) {
                        objects[i].selected = false;
                        if (objects[i].id === value.id) {
                            objects[i].selected = true;
                        }
                        objectStore.put(objects[i]);
                    }
                    resolve("set selected color");
                }

                cursorRequest.onerror = function(event) {
                    reject("error" + event.target.errorCode);
                }
            }
        })
    }

    function deleteColor(objectStoreName, value) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("db", version);

            request.onerror = function(event) {
                reject("error" + event.target.errorCode);
            }

            request.onsuccess = function(event) {
                db = event.target.result;
                const transaction = db.transaction(objectStoreName, 'readwrite');
                const objectStore = transaction.objectStore(objectStoreName);

                const deleteRequest = objectStore.delete(value.id);

                deleteRequest.onsuccess = function(event) {
                    resolve("deleted");
                }
    
                deleteRequest.onerror = function(event) {
                    reject("error " + event.target.errorCode);
                }
            }

        })
    }

    return {
        createDb,
        setColorValue,
        getAllColors,
        deleteDb,
        selectColor,
        deleteColor
    }

}

export { LocalStorage }