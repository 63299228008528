import { MAP_OBJECT } from './enums.js';

const {REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED, CC, PHI, ZDR, SW} = MAP_OBJECT;

const vmin=-160;
const vmax=160;

const extentsDecode = {
    [REFLECTIVITY]:[-30,80],
    [VELOCITY]:[vmin, vmax],
    [VELOCITY_DEALIASED]:[vmin, vmax],
    [CC]:[0.2,1.05],
    [PHI]:[-2.0, 10.0],
    [ZDR]:[-8,8],
    [SW]:[0,60]
}

const extents = {
    [REFLECTIVITY]:[0,80],
    [VELOCITY]:[vmin, vmax],
    [VELOCITY_DEALIASED]:[vmin, vmax],
    [CC]:[0.2,1.05],
    [PHI]:[-2.0, 10.0],
    [ZDR]:[-8,8],
    [SW]:[0,60]
}

const colors = {
    [REFLECTIVITY]:['#242424', //0
        '#14466c', //10
        '#0FCBFF',//20
        '#1D9E00',
        //'#68F000',
        '#95F44C',
         //25
        //'#5BFF42',   //30
        '#FFF700',  //35
        '#FF9F0F', //40
        '#EB7900', //45
        '#FF1F1F',//50
        '#AD1100', //60
        '#FFB8F5',//65
        '#CA57FF',//70
        '#4A009E',//80
        ],
    [VELOCITY]:[
        "#ffffff",
        "#FC52FF",
        "#871FFF",
        "#0011CC",
        "#0088CC",
        "#B3F0FF",
        "#42FF42",
        "#009402",
        "#A3A3A3",
        "#8A0000",
        "#FF5269",
        "#FFB3E0",
        "#FFF1C2",
        "#FF9214",
        "#B85C00",
        "#572100",
        "#000000"
    ],
    [VELOCITY_DEALIASED]:[
        "#ffffff",
        "#FC52FF",
        "#871FFF",
        "#0011CC",
        "#0088CC",
        "#B3F0FF",
        "#42FF42",
        "#009402",
        "#A3A3A3",
        "#8A0000",
        "#FF5269",
        "#FFB3E0",
        "#FFF1C2",
        "#FF9214",
        "#B85C00",
        "#572100",
        "#000000"
    ],
    [CC]:[
        "#000000",
        "#949494",
        "#7593FF",
        "#0045BD",
        "#ADF4FF",
        "#00FA32",
        "#FFD53D",
        "#F01000",
        "#C20047",
        "#FFB8D8",
        "#FFEBF2"
    ],
    [PHI]: [
        "#8b898c", //-2.0
        "#403e41", //-0.5
        "#520008", //0
        "#e575bd", //1
        "#a27abf", //1.5
        "#6df2f4", //2
        "#29c042", //2.5
        "#0afb0e", //3.0
        "#fefb06", //4.0
        "#ff820c", //5.0
        // "#fec273", //7.0
        // "#7e2482", //10.0
        // "#fff"
    ],
    [ZDR]: [
        "#242424", //gray/background -8
        "#afafaf", //light gray -0.5
        "#B980FF", //0.5
        "#0000a3", //blue 1.0
        "#6bf0ff", //1.5
        "#3dff64", //light green 2.0
        "#f5ff2e", //yellow 2.5
        "#ffa30f", //orange 3.5
        "#e61300", //red 5.0
        "#ff6bb5", //pink 6.0
        "#ffe0e6", //white 8.0
        //"#68326e", //dark purple
        
    ],
    [SW]: [
        "#242424", //gray/background 0
        "#afafaf", //light gray 10
        "#ff700a", //orange
        "#b30000", //red,
        "#f000ac", //magenta,
        "#8800c2", //purple,
        "#e0fcff", //rblue
        "#b4eb00", //light green
        "#7dd100", //green
    ]
}

const llevss = {
    [REFLECTIVITY]:[0,10,20,30,40,50,60,70, 80],
    [VELOCITY]:[-160, -120, -80, -40, 0, 40, 80, 120,160],
    [VELOCITY_DEALIASED]:[-160, -120, -80, -40, 0, 40, 80, 120,160],
    [CC]:[0.2,1.05],
    [PHI]:[-2, 0,1,2,3,4,5,6,7,8,9,10.0],
    [ZDR]:[-8, 0, 1, 2, 3, 4, 5 ,6, 7, 8],
    [SW]:[0, 10, 15, 20, 25, 30, 35, 40, 60]
}

const labels = {
    [REFLECTIVITY]:"dBZ",
    [VELOCITY]:'kt',
    [VELOCITY_DEALIASED]:"kt",
    [CC]:'',
    [PHI]:"deg km-1",
    [ZDR]:"dB",
    [SW]:'kt'
}

const values = {
    [REFLECTIVITY]:[0,10,20,25,30,35,40,45,50,60,65,70, 80],
    [VELOCITY]:[vmin,-140, -120, -100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100, 120, 140, vmax],
    [VELOCITY_DEALIASED]:[vmin,-140, -120, -100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100, 120, 140, vmax],
    [CC]:[0.2,0.4,.55,.65,.8,.85,.95,.975,1.0,1.04,1.05],
    [PHI]:[-2,-0.5, 0, 1.5, 2.0, 3, 4, 5, 7, 10.0], 
    [ZDR]:[-8, 0, 0.25, 0.5, 1.5, 2.0,2.5,3.5,5.0,6.0,8.0],
    [SW]:[0, 10, 15, 20, 25, 30, 35, 40, 60]
}

function returnColor(array) {
    if (array.length === 4) {
        return `rgba(${array.join(",")})`;
    } else if (array.length === 3) {
        return `rgb(${array.join(",")})`;
    }
}

function createColorbarAppearance(values, name, id, product, scale, units) {
   
    const canvas = document.createElement('canvas');
    const logicalWidth = 200;
    const logicalHeight = 20;
    canvas.width = logicalWidth;
    canvas.height= logicalHeight;
    const ctx = canvas.getContext('2d');
    const sscale=window.devicePixelRatio || 1;

    canvas.width = canvas.width * sscale;
    canvas.height = canvas.height * sscale;
    canvas.style.width = `${logicalWidth}px`;
    canvas.style.height = `${logicalHeight}px`;
    
    ctx.scale(sscale, sscale);
    ctx.clearRect(0,0,canvas.width,canvas.height); 
    const grdt=ctx.createLinearGradient(0,0,logicalWidth,0);

    //texture canvas creation
    const colortcanvas = document.createElement("canvas");
    colortcanvas.width=1200;
    colortcanvas.height=1;
    const ctxt = colortcanvas.getContext('2d');
    ctxt.clearRect(0,0,colortcanvas.width,colortcanvas.height); 
    const grdt2 = ctxt.createLinearGradient(0,0,1200,0);

    let min = 999;
    let max = -999;
    for (let i=0; i<values.length; i++) {
        if (values[i][0] > max) max = values[i][0];
        if (values[i][0] < min) min = values[i][0];
    }

    for (let i=0; i<values.length; i++) {

        //two color values associated with stop
        if (values[i].length === 3) {
            grdt.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
            grdt2.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
            if (values[i+1]) {
                grdt.addColorStop((values[i+1][0]-min)/(max-min),returnColor(values[i][2]));
                grdt2.addColorStop((values[i+1][0]-min)/(max-min),returnColor(values[i][2]));
            } else {
                grdt.addColorStop(1,returnColor(values[i][2]));
                grdt2.addColorStop(1,returnColor(values[i][2]));
            }
        //one color value associated with stop
        } else if (values[i].length === 2) {
            grdt.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
            grdt2.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
        }
    }

    ctx.fillStyle=grdt;
    ctx.fillRect(0,0,logicalWidth, logicalHeight);

    ctxt.fillStyle=grdt2;
    ctxt.fillRect(0,0,1200,1);

    const imageData=ctxt.getImageData(0,0,1200,1);

    return {
        image:canvas.toDataURL(),
        imageData:imageData,
        name:name,
        minMax:[min, max],
        values:values,
        id:id,
        product:product,
        scale:scale,
        units:units
    };

}

function colorbarGenerator(canvasIn, ctxIn, scale) {
    const canvas = canvasIn
    const ctx = ctxIn;

    function render(layertype) {
        const color=colors[layertype];
        const levs=values[layertype];

        ctx.clearRect(0,0,canvas.width,canvas.height); 
        const grdt=ctx.createLinearGradient(0,0,canvas.width,0);
        
        const cmax=extents[layertype][1];
        const cmin=extents[layertype][0];
        const clen=color.length;

        for (let i=0;i<clen;++i) {
            grdt.addColorStop((levs[i]-cmin)/(cmax-cmin),color[i]);
        }
        ctx.fillStyle=grdt;
        ctx.fillRect(0,0,canvas.width,10);
    }

    return {
        render
    }

}

function tableBar(canvas) {
    
    const ctx = canvas.getContext('2d');
    const sscale=window.devicePixelRatio || 1;
    
    canvas.width=canvas.clientWidth*sscale;
    canvas.height=canvas.clientHeight*sscale;

    ctx.scale(sscale,sscale);

    function render(valuesObj) {
        if (!ctx || valuesObj === undefined) return;
       
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const values = valuesObj.values;
        // const color=colors[field];
        // const levs=values[field];
        // const llevs = llevss[field];
        const min = valuesObj.minMax[0];
        const max = valuesObj.minMax[1];
       
        const grdt=ctx.createLinearGradient(25,0,275,0);

        for (let i=0; i<values.length; i++) {
    
            //two color values associated with stop
            if (values[i].length === 3) {
                grdt.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
                //grdt2.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
                if (values[i+1]) {
                    grdt.addColorStop((values[i+1][0]-min)/(max-min),returnColor(values[i][2]));
                    //grdt2.addColorStop((values[i+1][0]-min)/(max-min),returnColor(values[i][2]));
                } else {
                    grdt.addColorStop(1,returnColor(values[i][2]));
                    //grdt2.addColorStop(1,returnColor(values[i][2]));
                }
            //one color value associated with stop
            } else if (values[i].length === 2) {
                grdt.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
                //grdt2.addColorStop((values[i][0]-min)/(max-min),returnColor(values[i][1]));
            }
        }
        // const cmax=extents[field][1];
        // const cmin=extents[field][0];

        // const clen=color.length;

        // for (let i=0;i<clen;++i) {
        //     grdt.addColorStop((levs[i]-cmin)/(cmax-cmin),color[i]);
        // }

        ctx.fillStyle=grdt;
        ctx.fillRect(25,15,250,10);

        ctx.fillStyle="black";
        ctx.strokeStyle="white";
        ctx.textAlign="center";

        const pad=25;

        let startingValue = Math.ceil(min/10) * 10;
        //add colorbar labels
        while (startingValue <= max) {
            const pos=(startingValue-min)/(max-min);
            ctx.lineWidth=3.0;
            ctx.strokeText(startingValue,(pos*(250))+pad,36)
            ctx.lineWidth=1.5;
            ctx.fillText(startingValue,(pos*(250))+pad,36)
            startingValue += 10;
        }
        // for (let i=0;i<llevs.length;i+=1) {
        //     const pos=(llevs[i]-cmin)/(cmax-cmin);
        //     ctx.lineWidth=3.0;
        //     ctx.strokeText(llevs[i],(pos*(250))+pad,36)
        //     ctx.lineWidth=1.5;
        //     ctx.fillText(llevs[i],(pos*(250))+pad,36)
        // }

        ctx.textAlign="left";
        ctx.lineWidth=3.0;
        ctx.strokeText("dBZ",25,10);
        ctx.lineWidth=1.5;
        ctx.fillText("dBZ",25,10);

    }

    return { render }
}

function barBar(canvas) {

    const ctx = canvas.getContext('2d');
    const sscale=window.devicePixelRatio || 1;
    
    canvas.width=canvas.clientWidth*sscale;
    canvas.height=canvas.clientHeight*sscale;

    ctx.scale(sscale,sscale);

    function render(field) {
        if (!ctx || field === undefined) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const color=colors[field];
        const levs=values[field];
        const llevs = llevss[field];
       
        const grdt=ctx.createLinearGradient(25,0,275,0);
        const cmax=extents[field][1];
        const cmin=extents[field][0];

        const clen=color.length;

        for (let i=0;i<clen;++i) {
            grdt.addColorStop((levs[i]-cmin)/(cmax-cmin),color[i]);
        }

        ctx.fillStyle=grdt;
        ctx.fillRect(25,15,250,10);

        ctx.fillStyle="black";
        ctx.strokeStyle="white";
        ctx.textAlign="center";

        const pad=25;

        //add colorbar labels
        for (let i=0;i<llevs.length;i+=1) {
            const pos=(llevs[i]-cmin)/(cmax-cmin);
            ctx.lineWidth=3.0;
            ctx.strokeText(llevs[i],(pos*(250))+pad,36)
            ctx.lineWidth=1.5;
            ctx.fillText(llevs[i],(pos*(250))+pad,36)
        }

        ctx.textAlign="left";
        ctx.lineWidth=3.0;
        ctx.strokeText(labels[field],25,10);
        ctx.lineWidth=1.5;
        ctx.fillText(labels[field],25,10);

    }

    return {
        render
    }
}

function createLocalTexture(gl, values) {
    const imagetexture=gl.createTexture();

    // gl.activeTexture(gl.TEXTURE7);
    // gl.bindTexture(gl.TEXTURE_2D,imagetexture);
    // //textures[layertype] = imagetexture;
    // gl.texImage2D(gl.TEXTURE_2D,0,gl.RGBA,gl.RGBA,gl.UNSIGNED_BYTE,values.imageData)
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    // gl.bindTexture(gl.TEXTURE_2D, null);

    return {
        imageData: values.imageData,
        texture: imagetexture
    }

}

function createTexture(gl, layertype) {

    const color=colors[layertype];
    const levs=values[layertype];
    
    const colortcanvas = document.createElement("CANVAS");
    
    colortcanvas.width=1200;
    colortcanvas.height=1;
    const ctxt = colortcanvas.getContext('2d');
    ctxt.clearRect(0,0,colortcanvas.width,colortcanvas.height); 
    const grdt=ctxt.createLinearGradient(0,0,1200,0);
    
    const cmax=extents[layertype][1];
    const cmin=extents[layertype][0];
    const clen=color.length;

    for (let i=0;i<clen;++i) {
        grdt.addColorStop((levs[i]-cmin)/(cmax-cmin),color[i]);
    }

    ctxt.fillStyle=grdt;
    ctxt.fillRect(0,0,1200,1);

    const imageData=ctxt.getImageData(0,0,1200,1);
    const imagetexture=gl.createTexture();

    gl.activeTexture(gl.TEXTURE7);
    gl.bindTexture(gl.TEXTURE_2D,imagetexture);
    //textures[layertype] = imagetexture;
    gl.texImage2D(gl.TEXTURE_2D,0,gl.RGBA,gl.RGBA,gl.UNSIGNED_BYTE,imageData)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

    return {
        imageData: imageData,
        texture: imagetexture
    }
}

export { colorbarGenerator, createTexture, extents, vmin, vmax, barBar, createColorbarAppearance, createLocalTexture, tableBar, extentsDecode }