import { writable, readable, derived } from '../_snowpack/pkg/svelte/store.js';
import makeWorker from './workerModule.js';
import { Socket } from './socket.js';
import { LocalStorage } from './indexeddb.js';
import { Queue } from './Queue.js';
import { Animation } from './Animation.js';
import { Trending } from './Trending.js';
import { Files } from './files.js';
import { Auth } from './auth.js';
import { radarSites } from './sites.js';
import { createColorbarAppearance } from "./colorbars.js";
//import { testAlerts } from './test_sws2';
import { SINGLE, DEALIAS_OBJECT, INT_OBJECT, VIEW_OBJECT, MODE_OBJECT } from './enums.js';

const {DEALIASED} = DEALIAS_OBJECT;
const {READING} = INT_OBJECT;
const {ELEVATION} = VIEW_OBJECT;
const {HISTORICAL} = MODE_OBJECT;

export const error = writable({});
export const modal = writable({});
export const LOADING_3D = writable(false);
export const CLEAR = writable(false);
export const LOADING_LIVE = writable(false);
export const MOST_RECENT = writable(true);
export const ANIMATING = writable(false);
export const SITES = writable(false);
export const TRENDING = writable(false);
export const ALERTS = writable(false);
export const STATEMENTS = writable(false);
export const LIGHTNING = writable(false);
export const LOGGED_IN = writable(false);
export const STYLE = writable({
    value: 'monochrome',
    styleid: 'mapbox://styles/quadweather/cksxhy0l281db18nvnnfvdigv',
    layer: 'land-structure-line'
});
export const dataSettings = writable({
    opacity:1
});

export const MAP_STATE = writable(SINGLE);
export const SPLIT_STATE = writable("horizontal");
export const SETTINGS_STATE = writable("closed");
export const DEALIAS_STATE = writable(DEALIASED);
export const INT_STATE = writable(READING);
export const VIEW_STATE = writable(ELEVATION);
export const MODE_STATE = writable(HISTORICAL);
export const currentFile = writable(null);
export const scans = writable(Array.apply(null, Array(5)).map(function () {}));
// export const sites = writable({
//     stations:[]
// });
export const sites = derived(scans, $scans => {

    const d = {fileNames:[], elevations:[], stations:[], scans:[]};
    const localScans = $scans;
    //console.log("updateSite localScans", localScans);

    //Object.assign(d, {fileNames:[], elevations:[], stations:[], scans:[]});

    d.fileNames = [];
    d.stations = [];
    d.scans = {};
    d.elevations = {};
    const siteSets = {};
    const scanArrays = {};

   
    //loop through scans (files)
    for (let i=0; i<localScans.length; i++) {
        //only process those that are defined
        if (localScans[i] != undefined && localScans[i].idxs) {
            //want all filenames, they're unique (or you'd think)
            d.fileNames.push(localScans[i].fileName);

            //only want to save unique stations
            if (d.stations.indexOf(localScans[i].station) < 0) d.stations.push(localScans[i].station);

            //elevations (keys for later)
            if (localScans[i].station in siteSets) {
                //concatenate if already exists
                siteSets[localScans[i].station] = siteSets[localScans[i].station].concat(Object.keys(localScans[i].idxs));
            } else {
                
                siteSets[localScans[i].station] = Object.keys(localScans[i].idxs);
            }

            //scans
            if (localScans[i].station in scanArrays) {
                scanArrays[localScans[i].station] = scanArrays[localScans[i].station].concat(localScans[i].scans);
            } else {
                scanArrays[localScans[i].station] = localScans[i].scans;
            }
        }
    }

    //loop through each station
    for (let i=0; i<d.stations.length; i++) {
        const key = d.stations[i];

        //reset all properties
        d.elevations[key] = [];
        d.scans[key] = {};

        //get unique elevations sorted ascending
        d.elevations[key] = Array.from(new Set(siteSets[key])).sort(function(a,b) {
            return (+a)-(+b);
        });

        const allScans = scanArrays[key];
        //initialize object with all keys necessary
        for (let j=0; j<d.elevations[key].length; j++) {
            d.scans[key][d.elevations[key][j]] = [];
        }

        //loop through scan array
        for (let j=0; j<allScans.length; j++) {
            d.scans[key][allScans[j][0][0].rounded].push(...allScans[j]);
        }

        //final loop to sort each elevation by time
        for (let k in d.scans[key]) {
            d.scans[key][k] = d.scans[key][k].sort(function(a,b) {
                return a[0].date - b[0].date;
            })
        }
    }

    return d;
})
export const texture_3d = writable(Array.apply(null, Array(5)).map(function () {}));
export const vertexData = writable(Array.apply(null, Array(5)).map(function () {}));
export const radarMetadata = writable({});
export const message = writable(null);
export const box = writable(null);
export const line = writable(null);
export const selections = writable([
    {
        elevation:null,
        file:null,
        type:null,
        dealiased:null,
        scan:null
    },
    {
        elevation:null,
        file:null,
        type:null,
        dealiased:null,
        scan:null
    }
])

export const map = writable(Array.apply(null, Array(2)));
export const colorTables = writable({
    'br': {
        current:{},
        all:[]
    },
    'bv': {
        current:{},
        all:[]
    }
});
// export const socket = writable(null, set => {
//     set(Socket())
// })

// export const queue = writable(null, set => {
//     set(Queue())
// })
export const socket = Socket();
export const trending = Trending();
export const queue = Queue();
export const animation = Animation();
export const auth = Auth();
export const localStorage = LocalStorage();

//only load map after this resolves
export const promise = new Promise((resolve, reject) => {

    if ('indexedDB' in window) {
        localStorage.createDb()
        .then(d => {
            localStorage.getAllColors('colorTables')
            .then(d => {
    
                let output = {
                    'br': {
                        current:null,
                        all:[]
                    },
                    'bv': {
                        current:null,
                        all:[]
                    }
                }
                //let all = [];
                let indices = {
                    'br':0,
                    'bv':0
                }
    
                for (let i=0; i<d.length; i++) {
                    if (d[i].selected) {
                        output[d[i].product].current = indices[d[i].product];
                    }
    
                    indices[d[i].product] += 1;
    
                    //convert indexeddb data into usable stuff
                    const colorData = createColorbarAppearance(d[i].colorStops, d[i].name, d[i].id, d[i].product,
                        d[i].scale, d[i].units);
                    output[d[i].product].all.push(colorData);
                }
    
                colorTables.update(data => {
                    data = output;
                    //console.log("colorTables", data)
                    return data;
                })
    
                resolve("done loading indexeddb")

            })
        })
    } else {
        colorTables.update( d => {
            return "unsupported";
        })

        resolve("done loading indexeddb (not supported)")
    }
})

//delete and recreate
//localStorage.deleteDb();

//readable store for worker
export const worker = readable(null, set => {
    const myWorker = makeWorker();
    myWorker.setOnMessage(scans, vertexData, radarMetadata, selections, texture_3d, sites, error, CLEAR, socket, queue);
    set(myWorker.worker);
    
    //the unsubscribe function returned
	return function stop() {
		console.log("stopping");
	};
});

export const fileClass = writable(null, set => {
    set(Files())
})

export const alerts = writable({
    "type":"FeatureCollection",
    "features":[]
})

export const trendingData = writable({
    "type":"FeatureCollection",
    "features":[]
})

export const lightning = writable({
    "type":"FeatureCollection",
    "features":[]
})

//export const alerts = writable(JSON.parse(testAlerts));

export const nexradSites = readable(radarSites);