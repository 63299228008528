import './Colorbar.svelte.css';
/* src/Colorbar.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from '../_snowpack/pkg/svelte.js';
import { MAP_OBJECT } from './enums.js';
import { barBar, tableBar } from './colorbars.js';
import { colorTables } from './store.js';

function create_fragment(ctx) {
	let canvas_1;

	return {
		c() {
			canvas_1 = element("canvas");
			attr(canvas_1, "class", "svelte-80k2l0");
		},
		m(target, anchor) {
			insert(target, canvas_1, anchor);
			/*canvas_1_binding*/ ctx[3](canvas_1);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(canvas_1);
			/*canvas_1_binding*/ ctx[3](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $colorTables;
	component_subscribe($$self, colorTables, $$value => $$invalidate(2, $colorTables = $$value));
	const { REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED } = MAP_OBJECT;
	let { field } = $$props;
	let canvas;
	let colorbar;
	let table;

	//need to add redraw for when color table changes
	function redraw() {
		if (field === undefined || colorbar === undefined) return;

		if (field === REFLECTIVITY && $colorTables != 'unsupported') {
			table.render($colorTables.br.all[$colorTables.br.current]);
		} else {
			colorbar.render(field);
		}
	}

	function redrawTable() {
		if (field === REFLECTIVITY && $colorTables != 'unsupported') {
			table.render($colorTables.br.all[$colorTables.br.current]);
		}
	}

	onMount(() => {
		colorbar = barBar(canvas);
		table = tableBar(canvas);
		if (field === undefined || field === REFLECTIVITY) return;
		colorbar.render(field);
	});

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvas = $$value;
			$$invalidate(0, canvas);
		});
	}

	$$self.$$set = $$props => {
		if ('field' in $$props) $$invalidate(1, field = $$props.field);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*field*/ 2) {
			$: (field, redraw());
		}

		if ($$self.$$.dirty & /*$colorTables*/ 4) {
			$: ($colorTables, redrawTable());
		}
	};

	return [canvas, field, $colorTables, canvas_1_binding];
}

class Colorbar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { field: 1 });
	}
}

export default Colorbar;