
function createTable(subScans) {
    let elevations = [];
    for (let i=0; i<subScans.length; i++) {
        elevations.push(subScans[i][0][0].elevation);
    }

    const range = [Math.min(...elevations), Math.max(...elevations)+0.1];

    let start = range[0];
    let idx = 0;
    let output = [];
    let max = 0;
    //bracket contains integer indices of the elevations on either side 
    //of a given elevation with 0.1-degree resolution
    let bracket = [];

    while (start < range[1] && idx < elevations.length - 1) {
        if (Math.abs(start - elevations[idx]) < Math.abs(start - elevations[idx+1])) {
            output.push(idx);
        } else {
            output.push(idx+1);
        }
        bracket.push([idx, idx+1]);
        max = start;
        start += 0.1;
        if (start > elevations[idx+1]) {
            idx++;
        }
    }

    return {
        output: output,
        domain: [elevations[0], max], //actual min and max elevations observed
        range: range, //max plus 0.1
        elevations: elevations,
        bracket:bracket
    }
}

function createTexture(layertype) {
    const colors = {
        0:['#242424', //0
            //'#242424', //10
            '#14466c', //10
            '#0FCBFF',//20
            '#1D9E00',
            //'#68F000',
            '#95F44C',
             //25
            //'#5BFF42',   //30
            '#FFF700',  //35
            '#FF9F0F', //40
            '#EB7900', //45
            '#FF1F1F',//50
            '#AD1100', //60
            '#FFB8F5',//65
            '#CA57FF',//70
            '#4A009E',//80
            ],
        1:[
            "#ffffff",
            "#FC52FF",
            "#871FFF",
            "#0011CC",
            "#0088CC",
            "#B3F0FF",
            "#42FF42",
            "#009402",
            "#A3A3A3",
            "#8A0000",
            "#FF5269",
            "#FFB3E0",
            "#FFF1C2",
            "#FF9214",
            "#B85C00",
            "#572100",
            "#000000"
        ],
        2:[
            "#ffffff",
            "#FC52FF",
            "#871FFF",
            "#0011CC",
            "#0088CC",
            "#B3F0FF",
            "#42FF42",
            "#009402",
            "#A3A3A3",
            "#8A0000",
            "#FF5269",
            "#FFB3E0",
            "#FFF1C2",
            "#FF9214",
            "#B85C00",
            "#572100",
            "#000000"
        ]
    }
    const extents = {
        0:[0,80],
        1:[-300,300],
        2:[-300,300]
    }
    const values = {
        0:[0,10,20,25,30,35,40,45,50,60,65,70,80],
        1:[-300,-140, -120, -100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100, 120, 140, 300],
        2:[-300,-140, -120, -100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100, 120, 140, 300]
    }
    
    const color=colors[layertype];
    const levs=values[layertype];
    const colortcanvas = document.createElement("CANVAS");
    
    colortcanvas.width=1200;
    colortcanvas.height=1;
    var ctxt = colortcanvas.getContext('2d');
    ctxt.clearRect(0,0,colortcanvas.width,colortcanvas.height); 
    var grdt=ctxt.createLinearGradient(0,0,1200,0);
    var cmax=extents[layertype][1];
    var cmin=extents[layertype][0];
    var clen=color.length;

    for (let i=0;i<clen;++i) {
        grdt.addColorStop((levs[i]-cmin)/(cmax-cmin),color[i]);
    }
    ctxt.fillStyle=grdt;
    ctxt.fillRect(0,0,1200,10);

    return ctxt.getImageData(0,0,1200,1);
}

function createIcon(type) {

    const canvas = document.createElement("CANVAS");
    
    canvas.width=100;
    canvas.height=50;

    const radius = 10;

    const ctx = canvas.getContext('2d');

    const x = 0;
    const y = 0;

    const width = 100;
    const height = 50;

    if (type === "selected") {
        ctx.fillStyle = "#0FB7E5";
    }
   
    if (type === "unselected") {
        ctx.fillStyle = "#949494";
    }

    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
    ctx.fill();

    return ctx.getImageData(0, 0, width, height);
} 

export { createTable, createTexture, createIcon }