import './Notice.svelte.css';
/* src/Notice.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	null_to_empty,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text
} from "../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import { error } from './store.js';

function create_fragment(ctx) {
	let div4;
	let div0;
	let p0;
	let t0;
	let div0_class_value;
	let t1;
	let div2;
	let div1;
	let p1;
	let t2;
	let t3;
	let div3;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div4 = element("div");
			div0 = element("div");
			p0 = element("p");
			t0 = text(/*header*/ ctx[0]);
			t1 = space();
			div2 = element("div");
			div1 = element("div");
			p1 = element("p");
			t2 = text(/*body*/ ctx[1]);
			t3 = space();
			div3 = element("div");
			button = element("button");
			button.textContent = "Okay";
			attr(p0, "class", "header-text svelte-1knftiy");
			attr(div0, "class", div0_class_value = "" + (null_to_empty(`notice-header ${/*className*/ ctx[2]}`) + " svelte-1knftiy"));
			attr(div1, "class", "body-text svelte-1knftiy");
			attr(div2, "class", "notice-body svelte-1knftiy");
			attr(button, "class", "svelte-1knftiy");
			attr(div3, "class", "close svelte-1knftiy");
			attr(div4, "class", "notice-wrapper svelte-1knftiy");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div0);
			append(div0, p0);
			append(p0, t0);
			append(div4, t1);
			append(div4, div2);
			append(div2, div1);
			append(div1, p1);
			append(p1, t2);
			append(div4, t3);
			append(div4, div3);
			append(div3, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handleClick*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*header*/ 1) set_data(t0, /*header*/ ctx[0]);

			if (dirty & /*className*/ 4 && div0_class_value !== (div0_class_value = "" + (null_to_empty(`notice-header ${/*className*/ ctx[2]}`) + " svelte-1knftiy"))) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*body*/ 2) set_data(t2, /*body*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $error;
	component_subscribe($$self, error, $$value => $$invalidate(4, $error = $$value));
	let { header } = $$props;
	let { body } = $$props;
	let { className } = $$props;
	const dispatch = createEventDispatcher();

	function handleClick() {
		set_store_value(error, $error.error = false, $error);
		set_store_value(error, $error.color = null, $error);
		dispatch('message', { value: "hideError" });
	}

	$$self.$$set = $$props => {
		if ('header' in $$props) $$invalidate(0, header = $$props.header);
		if ('body' in $$props) $$invalidate(1, body = $$props.body);
		if ('className' in $$props) $$invalidate(2, className = $$props.className);
	};

	return [header, body, className, handleClick];
}

class Notice extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { header: 0, body: 1, className: 2 });
	}
}

export default Notice;