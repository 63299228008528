import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import { createAuth0Client } from '../_snowpack/pkg/@auth0/auth0-spa-js.js';
import { LOGGED_IN, error } from './store.js';
undefined /* [snowpack] import.meta.hot */ ;

function Auth() {
    let auth0Client = null;
    const fetchAuthConfig = () => fetch("/auth_config.json");

    const configureClient = async () => {
        const response = await fetchAuthConfig();
        const config = await response.json();
      
        auth0Client = await createAuth0Client({
          domain: config.domain,
          clientId: config.clientId,
          authorizationParams: {
            audience: config.audience 
          },
          useRefreshTokens: true,
          cacheLocation:"localstorage"
        });

        // Request a new access token using a refresh token
        //const token = await auth0Client.getTokenSilently();

        const isA = await isAuthenticated();

        if (isA) {
            // const user = await getUser();
            // console.log(user)
            LOGGED_IN.set(true);
        } else {
            LOGGED_IN.set(false);
        }
    };

    async function q() {
        await configureClient();
        const query = window.location.search;
      

        if (query.includes("error=")) {
            let params = (new URL(document.location)).searchParams;
            let errorSubject = params.get("error");
            let errorDescription = params.get("error_description");

            error.update(d => {
                d.error = true;
                d.message = `A verification email has been sent to the email provided. Click the link in the email to complete account creation.`;
                d.header = "Email not verified";
                d.color = "normal"
                return d;
            })
    
            // Use replaceState to redirect the user away and remove the querystring parameters
            window.history.replaceState({}, document.title, '/');
            //window.history.replaceState({}, document.title, '/test');
        }
        else if (query.includes("code=") && query.includes("state=")) {

            // Process the login state
            await auth0Client.handleRedirectCallback();
    
            // Use replaceState to redirect the user away and remove the querystring parameters
            window.history.replaceState({}, document.title, '/');
            //window.history.replaceState({}, document.title, '/test');

            LOGGED_IN.set(true);
        }
    }

    
    q();

    async function isAuthenticated() {
        const isAuthenticated = await auth0Client.isAuthenticated();
        
        return isAuthenticated;
    }

    async function getToken() {
        let token;
    
        if (__SNOWPACK_ENV__.SNOWPACK_PUBLIC_CREDS != "null") {
            token = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_CREDS;
        } else {
            try {
                token = await auth0Client.getTokenSilently();
            } catch(e) {
                //logout if expired;
                logout();
            } 
       }
        

        return token;
    }

    async function getUser() {
        const user = await auth0Client.getUser();
        return user;
    }

    const login = async () => {
        await auth0Client.loginWithRedirect({
          authorizationParams: {
            //redirect_uri: window.location.origin+"/test"
            redirect_uri: window.location.origin
          }
        });
    };

    const logout = () => {
        auth0Client.logout({
          logoutParams: {
            //returnTo: window.location.origin+"/test"
            returnTo: window.location.origin
          }
        });
    };

    return {
        isAuthenticated,
        login,
        logout,
        getToken,
        getUser
    }
}

export { Auth }