import './RadarCard.svelte.css';
/* src/RadarCard.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	set_style,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import {
	worker,
	selections,
	vertexData,
	scans as scansStore,
	sites,
	texture_3d,
	currentFile,
	radarMetadata as rmStore
} from './store.js';

import { MAP_OBJECT } from './enums.js';
import { UpdateData } from './UpdateClass.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	return child_ctx;
}

// (188:8) {#if station}
function create_if_block_1(ctx) {
	let div;
	let div_class_value;
	let t;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			t = space();
			button = element("button");

			button.innerHTML = `<svg class="trash svelte-18jl7v3" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 468.36 468.36" style="enable-background:new 0 0 468.36 468.36;" xml:space="preserve"><g><g><path d="M381.048,64.229l-71.396,0.031L309.624,0L158.666,0.064l0.027,64.26l-71.405,0.031l0.024,60.056h293.76L381.048,64.229z
                        M189.274,30.652l89.759-0.04l0.016,33.66l-89.759,0.04L189.274,30.652z"></path><path d="M87.312,468.36h293.76V139.71H87.312V468.36z M303.042,184.588h15.301v238.891h-15.301V184.588z M226.542,184.588h15.3
                        v238.891h-15.3V184.588z M150.042,184.588h15.3v238.891h-15.3V184.588z"></path></g></g></svg>`;

			attr(div, "class", div_class_value = "arrow " + /*directionClass*/ ctx[8] + " svelte-18jl7v3");
			attr(button, "class", "trashbutton svelte-18jl7v3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			insert(target, t, anchor);
			insert(target, button, anchor);

			if (!mounted) {
				dispose = [
					listen(div, "click", /*handleExpand*/ ctx[9]),
					listen(button, "click", /*deleteFile*/ ctx[11])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*directionClass*/ 256 && div_class_value !== (div_class_value = "arrow " + /*directionClass*/ ctx[8] + " svelte-18jl7v3")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t);
			if (detaching) detach(button);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (207:8) {#if station}
function create_if_block(ctx) {
	let each_1_anchor;
	let each_value = /*localScans*/ ctx[3];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*localScans, active, fileNumber, handleClick, fileName, station*/ 1055) {
				each_value = /*localScans*/ ctx[3];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (211:16) {#each elevation as scan}
function create_each_block_1(ctx) {
	let div;
	let span;
	let t_value = /*scan*/ ctx[32][0].date.toUTCString() + "";
	let t;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			span = element("span");
			t = text(t_value);
			attr(span, "class", "spanclass svelte-18jl7v3");

			toggle_class(span, "active", /*active*/ ctx[0] === /*fileNumber*/ ctx[4] + '-' + /*scan*/ ctx[32][0].idx
			? true
			: false);

			attr(div, "class", "item svelte-18jl7v3");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			append(span, t);

			if (!mounted) {
				dispose = listen(span, "click", function () {
					if (is_function(/*handleClick*/ ctx[10]({
						scan: /*scan*/ ctx[32],
						fileName: /*fileName*/ ctx[1],
						station: /*station*/ ctx[2],
						fileNumber: /*fileNumber*/ ctx[4]
					}))) /*handleClick*/ ctx[10]({
						scan: /*scan*/ ctx[32],
						fileName: /*fileName*/ ctx[1],
						station: /*station*/ ctx[2],
						fileNumber: /*fileNumber*/ ctx[4]
					}).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*localScans*/ 8 && t_value !== (t_value = /*scan*/ ctx[32][0].date.toUTCString() + "")) set_data(t, t_value);

			if (dirty[0] & /*active, fileNumber, localScans*/ 25) {
				toggle_class(span, "active", /*active*/ ctx[0] === /*fileNumber*/ ctx[4] + '-' + /*scan*/ ctx[32][0].idx
				? true
				: false);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (208:12) {#each localScans as elevation}
function create_each_block(ctx) {
	let h3;
	let t0_value = /*elevation*/ ctx[29][0][0].elevation.toFixed(1) + "";
	let t0;
	let t1;
	let t2;
	let div;
	let t3;
	let each_value_1 = /*elevation*/ ctx[29];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			h3 = element("h3");
			t0 = text(t0_value);
			t1 = text("°");
			t2 = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t3 = space();
			attr(h3, "class", "svelte-18jl7v3");
			attr(div, "class", "listul svelte-18jl7v3");
		},
		m(target, anchor) {
			insert(target, h3, anchor);
			append(h3, t0);
			append(h3, t1);
			insert(target, t2, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			append(div, t3);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*localScans*/ 8 && t0_value !== (t0_value = /*elevation*/ ctx[29][0][0].elevation.toFixed(1) + "")) set_data(t0, t0_value);

			if (dirty[0] & /*active, fileNumber, localScans, handleClick, fileName, station*/ 1055) {
				each_value_1 = /*elevation*/ ctx[29];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, t3);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) detach(h3);
			if (detaching) detach(t2);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div4;
	let div2;
	let table;
	let tbody;
	let tr0;
	let t5;
	let tr1;
	let td3;
	let div1;
	let div0;
	let t6;
	let span;
	let t7;
	let t8;
	let td4;
	let t9_value = (/*vcp*/ ctx[6] === undefined ? '' : /*vcp*/ ctx[6]) + "";
	let t9;
	let t10;
	let td5;

	let t11_value = (/*station*/ ctx[2] === undefined
	? ''
	: /*station*/ ctx[2]) + "";

	let t11;
	let t12;
	let t13;
	let div3;
	let if_block0 = /*station*/ ctx[2] && create_if_block_1(ctx);
	let if_block1 = /*station*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			div4 = element("div");
			div2 = element("div");
			table = element("table");
			tbody = element("tbody");
			tr0 = element("tr");

			tr0.innerHTML = `<td class="large svelte-18jl7v3">File</td> 
                    <td class="svelte-18jl7v3">VCP</td> 
                    <td class="svelte-18jl7v3">Radar</td>`;

			t5 = space();
			tr1 = element("tr");
			td3 = element("td");
			div1 = element("div");
			div0 = element("div");
			t6 = space();
			span = element("span");
			t7 = text(/*fileName*/ ctx[1]);
			t8 = space();
			td4 = element("td");
			t9 = text(t9_value);
			t10 = space();
			td5 = element("td");
			t11 = text(t11_value);
			t12 = space();
			if (if_block0) if_block0.c();
			t13 = space();
			div3 = element("div");
			if (if_block1) if_block1.c();
			attr(div0, "class", "progress svelte-18jl7v3");
			set_style(div0, "width", (/*percent*/ ctx[5] >= 0 ? /*percent*/ ctx[5] * 250 : 0) + "px");
			set_style(span, "position", "absolute");
			attr(div1, "class", "relative-table svelte-18jl7v3");
			attr(td3, "class", "large content svelte-18jl7v3");
			attr(td4, "class", "content svelte-18jl7v3");
			attr(td5, "class", "content svelte-18jl7v3");
			attr(table, "class", "svelte-18jl7v3");
			attr(div2, "class", "table-wrapper svelte-18jl7v3");
			attr(div3, "class", "svelte-18jl7v3");
			toggle_class(div3, "none", !/*open*/ ctx[7]);
			attr(div4, "class", "table-content svelte-18jl7v3");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div2);
			append(div2, table);
			append(table, tbody);
			append(tbody, tr0);
			append(tbody, t5);
			append(tbody, tr1);
			append(tr1, td3);
			append(td3, div1);
			append(div1, div0);
			append(div1, t6);
			append(div1, span);
			append(span, t7);
			append(tr1, t8);
			append(tr1, td4);
			append(td4, t9);
			append(tr1, t10);
			append(tr1, td5);
			append(td5, t11);
			append(div2, t12);
			if (if_block0) if_block0.m(div2, null);
			append(div4, t13);
			append(div4, div3);
			if (if_block1) if_block1.m(div3, null);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*percent*/ 32) {
				set_style(div0, "width", (/*percent*/ ctx[5] >= 0 ? /*percent*/ ctx[5] * 250 : 0) + "px");
			}

			if (dirty[0] & /*fileName*/ 2) set_data(t7, /*fileName*/ ctx[1]);
			if (dirty[0] & /*vcp*/ 64 && t9_value !== (t9_value = (/*vcp*/ ctx[6] === undefined ? '' : /*vcp*/ ctx[6]) + "")) set_data(t9, t9_value);

			if (dirty[0] & /*station*/ 4 && t11_value !== (t11_value = (/*station*/ ctx[2] === undefined
			? ''
			: /*station*/ ctx[2]) + "")) set_data(t11, t11_value);

			if (/*station*/ ctx[2]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(div2, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*station*/ ctx[2]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div3, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty[0] & /*open*/ 128) {
				toggle_class(div3, "none", !/*open*/ ctx[7]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let directionClass;
	let $worker;
	let $texture_3d;
	let $vertexData;
	let $rmStore;
	let $currentFile;
	let $selections;
	let $scansStore;
	let $sites;
	component_subscribe($$self, worker, $$value => $$invalidate(16, $worker = $$value));
	component_subscribe($$self, texture_3d, $$value => $$invalidate(17, $texture_3d = $$value));
	component_subscribe($$self, vertexData, $$value => $$invalidate(18, $vertexData = $$value));
	component_subscribe($$self, rmStore, $$value => $$invalidate(19, $rmStore = $$value));
	component_subscribe($$self, currentFile, $$value => $$invalidate(20, $currentFile = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(15, $selections = $$value));
	component_subscribe($$self, scansStore, $$value => $$invalidate(21, $scansStore = $$value));
	component_subscribe($$self, sites, $$value => $$invalidate(22, $sites = $$value));
	const { REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED } = MAP_OBJECT;
	let { fileName } = $$props;
	let { station } = $$props;
	let { localScans } = $$props;
	let { fileNumber } = $$props;
	let { percent } = $$props;
	let { vcp } = $$props;
	let { active = undefined } = $$props;
	let { scans } = $$props;
	let { idxs } = $$props;
	let { radarMetadata } = $$props;

	//export let active;
	let open = true;

	const idMap = {
		[REFLECTIVITY]: 0,
		[VELOCITY]: 1,
		[VELOCITY_DEALIASED]: 2
	};

	const updateInstance = UpdateData();

	function handleExpand() {
		$$invalidate(7, open = open ? false : true);
	}

	function handleClick(scan) {
		$$invalidate(0, active = scan.fileName + scan.scan[0].idx);
		updateInstance.logStore(scan);
	}

	function updateSites() {
		const localScans = $scansStore;
		const d = $sites;

		Object.assign(d, {
			fileNames: [],
			elevations: [],
			stations: [],
			scans: []
		});

		d.fileNames = [];
		d.stations = [];
		d.scans = {};
		d.elevations = {};
		const siteSets = {};
		const scanArrays = {};

		//loop through scans (files)
		for (let i = 0; i < localScans.length; i++) {
			//only process those that are defined
			if (localScans[i] != undefined) {
				//want all filenames, they're unique (or you'd think)
				d.fileNames.push(localScans[i].fileName);

				//only want to save unique stations
				if (d.stations.indexOf(localScans[i].station) < 0) d.stations.push(localScans[i].station);

				//elevations (keys for later)
				if (localScans[i].station in siteSets) {
					//concatenate if already exists
					siteSets[localScans[i].station] = siteSets[localScans[i].station].concat(Object.keys(localScans[i].idxs));
				} else {
					siteSets[localScans[i].station] = Object.keys(localScans[i].idxs);
				}

				//scans
				if (localScans[i].station in scanArrays) {
					scanArrays[localScans[i].station] = scanArrays[localScans[i].station].concat(localScans[i].scans);
				} else {
					scanArrays[localScans[i].station] = localScans[i].scans;
				}
			}
		}

		//loop through each station
		for (let i = 0; i < d.stations.length; i++) {
			const key = d.stations[i];

			//reset all properties
			d.elevations[key] = [];

			d.scans[key] = {};

			//get unique elevations sorted ascending
			d.elevations[key] = Array.from(new Set(siteSets[key])).sort(function (a, b) {
				return +a - +b;
			});

			const allScans = scanArrays[key];

			//initialize object with all keys necessary
			for (let j = 0; j < d.elevations[key].length; j++) {
				d.scans[key][d.elevations[key][j]] = [];
			}

			//loop through scan array
			for (let j = 0; j < allScans.length; j++) {
				d.scans[key][allScans[j][0][0].rounded].push(...allScans[j]);
			}

			//final loop to sort each elevation by time
			for (let k in d.scans[key]) {
				d.scans[key][k] = d.scans[key][k].sort(function (a, b) {
					return a[0].date - b[0].date;
				});
			}
		}
	}

	function deleteFile() {
		//first, remove client-side references
		//scan undefined like initial store
		set_store_value(scansStore, $scansStore[fileNumber] = undefined, $scansStore);

		//reset selections if deleting selected
		if (fileNumber === $selections[0].file) {
			set_store_value(
				selections,
				$selections = [
					{
						elevation: null,
						file: null,
						type: null,
						dealiased: null,
						scan: null
					},
					{
						elevation: null,
						file: null,
						type: null,
						dealiased: null,
						scan: null
					}
				],
				$selections
			);

			set_store_value(currentFile, $currentFile = null, $currentFile);
			set_store_value(rmStore, $rmStore = {}, $rmStore);
		}

		//update $sites since $scans has been updated
		//updateSites();
		//remove any and all vertex arrays
		set_store_value(vertexData, $vertexData[fileNumber] = undefined, $vertexData);

		set_store_value(texture_3d, $texture_3d[fileNumber] = undefined, $texture_3d);

		//remove memory from C
		$worker.postMessage({
			message: "deleteFile",
			data: { fileNum: fileNumber, fileName }
		});
	}

	$$self.$$set = $$props => {
		if ('fileName' in $$props) $$invalidate(1, fileName = $$props.fileName);
		if ('station' in $$props) $$invalidate(2, station = $$props.station);
		if ('localScans' in $$props) $$invalidate(3, localScans = $$props.localScans);
		if ('fileNumber' in $$props) $$invalidate(4, fileNumber = $$props.fileNumber);
		if ('percent' in $$props) $$invalidate(5, percent = $$props.percent);
		if ('vcp' in $$props) $$invalidate(6, vcp = $$props.vcp);
		if ('active' in $$props) $$invalidate(0, active = $$props.active);
		if ('scans' in $$props) $$invalidate(12, scans = $$props.scans);
		if ('idxs' in $$props) $$invalidate(13, idxs = $$props.idxs);
		if ('radarMetadata' in $$props) $$invalidate(14, radarMetadata = $$props.radarMetadata);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*open*/ 128) {
			$: $$invalidate(8, directionClass = open ? "down" : "up");
		}

		if ($$self.$$.dirty[0] & /*$selections*/ 32768) {
			$: $$invalidate(0, active = $selections[0] && $selections[0].scan
			? $selections[0].scan.scan[0].fileNumber + '-' + $selections[0].scan.scan[0].idx
			: undefined);
		}
	};

	return [
		active,
		fileName,
		station,
		localScans,
		fileNumber,
		percent,
		vcp,
		open,
		directionClass,
		handleExpand,
		handleClick,
		deleteFile,
		scans,
		idxs,
		radarMetadata,
		$selections
	];
}

class RadarCard extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance,
			create_fragment,
			safe_not_equal,
			{
				fileName: 1,
				station: 2,
				localScans: 3,
				fileNumber: 4,
				percent: 5,
				vcp: 6,
				active: 0,
				scans: 12,
				idxs: 13,
				radarMetadata: 14
			},
			null,
			[-1, -1]
		);
	}
}

export default RadarCard;