/* src/State.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	component_subscribe,
	init,
	safe_not_equal,
	set_store_value
} from "../_snowpack/pkg/svelte/internal.js";

import {
	selections,
	vertexData,
	radarMetadata,
	worker,
	scans,
	map,
	MAP_STATE,
	currentFile,
	texture_3d,
	INT_STATE,
	error,
	CLEAR,
	modal
} from './store.js';

import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import { DOUBLE, INT_OBJECT } from './enums.js';

function instance($$self, $$props, $$invalidate) {
	let $CLEAR;
	let $modal;
	let $error;
	let $currentFile;
	let $vertexData;
	let $selections;
	let $INT_STATE;
	let $texture_3d;
	let $MAP_STATE;
	component_subscribe($$self, CLEAR, $$value => $$invalidate(0, $CLEAR = $$value));
	component_subscribe($$self, modal, $$value => $$invalidate(1, $modal = $$value));
	component_subscribe($$self, error, $$value => $$invalidate(2, $error = $$value));
	component_subscribe($$self, currentFile, $$value => $$invalidate(3, $currentFile = $$value));
	component_subscribe($$self, vertexData, $$value => $$invalidate(4, $vertexData = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(5, $selections = $$value));
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(8, $INT_STATE = $$value));
	component_subscribe($$self, texture_3d, $$value => $$invalidate(9, $texture_3d = $$value));
	component_subscribe($$self, MAP_STATE, $$value => $$invalidate(6, $MAP_STATE = $$value));
	const { READING, MODE3D, CROSS } = INT_OBJECT;

	//component to handle state
	//idea is: every time store value is updated, a function fires in this component
	//this component decides what state is and dispatches events to other components
	//which should reduce or eliminate the need for branching in componenets like Map
	//because they'll be receiving a specific instruction
	const dispatch = createEventDispatcher();

	let numMaps;

	function handleSelections() {
		//check if vertices exist. If so, tell Map to update
		//loop through maps
		for (let i = 0; i < numMaps; i++) {
			//can take a beat to show up if looping
			if (!$selections[i]) continue;

			//very start
			if ($vertexData[$selections[i].file] === undefined) {
				continue;
			} else //need to load specific field
			if ($vertexData[$selections[i].file][$selections[i].type][$selections[i].elevation] === undefined) {
				continue;
			} else {
				dispatch('message', {
					value: "loadVertices", //everything already exists
					idx: i
				});
			}
		}
	}

	function handleVertexData() {
		for (let i = 0; i < numMaps; i++) {
			//can take a beat to show up if looping
			//issue is that in logStore, will return length 1 array when store initializes to 2
			if (!$selections[i]) continue;

			if ($selections[i].file === null || $vertexData[$selections[i].file] === undefined) {
				continue;
			} else if ($vertexData[$selections[i].file][$selections[i].type][$selections[i].elevation] === undefined) {
				continue;
			} else {
				dispatch('message', { value: "loadVertices", idx: i });
			}
		}
	}

	function handleFile() {
		if ($INT_STATE === MODE3D || $INT_STATE === CROSS) {
			//check if needs loading
			if ($texture_3d[$selections[0].file] === undefined) {
				return;
			} else {
				//dispatch event that will propagate to MenuCanvas to load texture
				dispatch('message', { value: "loadTexture" });
			}
		}
	}

	function handleError() {
		if ($error.error) {
			dispatch('message', { value: "showError" });
		}
	}

	function handleModal() {
		if ($modal.error) {
			dispatch('message', { value: "showModal" });
		}
	}

	function clearMap() {
		if ($CLEAR) {
			for (let i = 0; i < numMaps; i++) {
				dispatch('message', { value: "clearMap", idx: i });
			}

			if ($INT_STATE === MODE3D) {
				dispatch('message', { value: "removeBox" });
			}

			if ($INT_STATE === CROSS) {
				dispatch('message', { value: "removeLine" });
			}

			set_store_value(CLEAR, $CLEAR = false, $CLEAR);
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$MAP_STATE*/ 64) {
			//const numMaps = 2;
			$: if ($MAP_STATE === DOUBLE) {
				numMaps = 2;
			} else {
				numMaps = 1;
			}
		}

		if ($$self.$$.dirty & /*$selections*/ 32) {
			$: ($selections, handleSelections());
		}

		if ($$self.$$.dirty & /*$vertexData*/ 16) {
			$: ($vertexData, handleVertexData());
		}

		if ($$self.$$.dirty & /*$currentFile*/ 8) {
			$: ($currentFile, handleFile());
		}

		if ($$self.$$.dirty & /*$error*/ 4) {
			$: ($error, handleError());
		}

		if ($$self.$$.dirty & /*$modal*/ 2) {
			$: ($modal, handleModal());
		}

		if ($$self.$$.dirty & /*$CLEAR*/ 1) {
			$: ($CLEAR, clearMap());
		}
	};

	return [$CLEAR, $modal, $error, $currentFile, $vertexData, $selections, $MAP_STATE];
}

class State extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, null, safe_not_equal, {});
	}
}

export default State;