const radarSites = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -124.1069,
                    47.1158
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLGX",
                "STATION_NAME": "LANGLEY HILL NW WASHIN",
                "STATE": "WA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.76972,
                    33.17194
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBMX",
                "STATION_NAME": "BIRMINGHAM",
                "STATE": "AL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -78.73694,
                    42.94861
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBUF",
                "STATION_NAME": "BUFFALO",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -116.23444,
                    43.49083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCBX",
                "STATION_NAME": "BOISE",
                "STATE": "ID"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -74.41111,
                    39.94694
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDIX",
                "STATION_NAME": "PHILADELPHIA",
                "STATE": "NJ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -110.63028,
                    31.89361
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEMX",
                "STATION_NAME": "TUCSON",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -114.89139,
                    35.70111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KESX",
                "STATION_NAME": "LAS VEGAS",
                "STATE": "NV"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -98.97611,
                    34.36222
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFDR",
                "STATION_NAME": "ALTUS AFB",
                "STATE": "OK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -101.81361,
                    33.65417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLBB",
                "STATION_NAME": "LUBBOCK",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -89.87333,
                    35.34472
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KNQA",
                "STATION_NAME": "MEMPHIS",
                "STATE": "TN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.77194,
                    37.06833
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KPAH",
                "STATION_NAME": "PADUCAH",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -104.18139,
                    38.45944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KPUX",
                "STATION_NAME": "PUEBLO",
                "STATE": "CO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -122.96417,
                    45.715
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KRTX",
                "STATION_NAME": "PORTLAND",
                "STATE": "OR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -111.38444,
                    47.45972
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTFX",
                "STATION_NAME": "GREAT FALLS",
                "STATE": "MT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -98.1275,
                    36.74083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KVNX",
                "STATION_NAME": "VANCE AFB",
                "STATE": "OK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -159.55222,
                    21.89417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PHKI",
                "STATION_NAME": "SOUTH KAUAI",
                "STATE": "HI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -155.77778,
                    20.12556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PHKM",
                "STATION_NAME": "KAMUELA",
                "STATE": "HI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -98.41306,
                    45.45583
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KABR",
                "STATION_NAME": "ABERDEEN",
                "STATE": "SD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -122.49444,
                    48.19472
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KATX",
                "STATION_NAME": "SEATTLE",
                "STATE": "WA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -92.20972,
                    46.83694
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDLH",
                "STATION_NAME": "DULUTH",
                "STATE": "MN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -75.44,
                    38.82556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDOX",
                "STATION_NAME": "DOVER AFB",
                "STATE": "DE"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -101.7,
                    39.36694
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGLD",
                "STATION_NAME": "GOODLAND",
                "STATE": "KS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -119.63111,
                    36.31417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHNX",
                "STATION_NAME": "SAN JOAQUIN VALLEY",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.4425,
                    37.65444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KICT",
                "STATION_NAME": "WICHITA",
                "STATE": "KS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -100.57583,
                    41.95778
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLNX",
                "STATION_NAME": "NORTH PLATTE",
                "STATE": "NE"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -90.68278,
                    38.69889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLSX",
                "STATION_NAME": "ST LOUIS",
                "STATE": "MO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -112.68528,
                    43.10583
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSFX",
                "STATION_NAME": "POCATELLO",
                "STATE": "ID"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -96.2325,
                    38.99694
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTWX",
                "STATION_NAME": "TOPEKA",
                "STATE": "KS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -87.7247,
                    38.26
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KVWX",
                "STATION_NAME": "EVANSVILLE",
                "STATE": "IN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -91.19111,
                    43.82278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KARX",
                "STATION_NAME": "LA CROSSE",
                "STATE": "WI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -108.60611,
                    45.85389
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBLX",
                "STATION_NAME": "BILLINGS",
                "STATE": "MT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.70333,
                    24.59694
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBYX",
                "STATION_NAME": "KEY WEST",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -104.80611,
                    41.15194
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCYS",
                "STATION_NAME": "CHEYENNE",
                "STATE": "WY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.47167,
                    42.69972
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDTX",
                "STATION_NAME": "DETROIT",
                "STATE": "MI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -111.19833,
                    34.57444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFSX",
                "STATION_NAME": "FLAGSTAFF",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.11111,
                    44.49833
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGRB",
                "STATION_NAME": "GREEN BAY",
                "STATE": "WI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.55056,
                    42.96778
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMKX",
                "STATION_NAME": "MILWAUKEE",
                "STATE": "WI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.65444,
                    28.11306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMLB",
                "STATION_NAME": "MELBOURNE",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -78.49,
                    35.66528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KRAX",
                "STATION_NAME": "RALEIGH DURHAM",
                "STATE": "NC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -100.49222,
                    31.37111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSJT",
                "STATION_NAME": "SAN ANGELO",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.00194,
                    30.89
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KVAX",
                "STATION_NAME": "MOODY AFB",
                "STATE": "GA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -147.49917,
                    65.03556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PAPD",
                "STATION_NAME": "FAIRBANKS",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -157.18,
                    21.13278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PHMO",
                "STATION_NAME": "MOLOKAI",
                "STATE": "HI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -155.56889,
                    19.095
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PHWA",
                "STATION_NAME": "SOUTH SHORE",
                "STATE": "HI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -106.82333,
                    35.14972
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KABX",
                "STATION_NAME": "ALBUQUERQUE",
                "STATE": "NM"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -73.16639,
                    44.51111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCXX",
                "STATION_NAME": "BURLINGTON",
                "STATE": "VT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -111.19833,
                    34.57444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFSX",
                "STATION_NAME": "FLAGSTAFF",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -104.54528,
                    39.78667
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFTG",
                "STATION_NAME": "DENVER FRONT RANGE AP",
                "STATE": "CO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -106.62417,
                    48.20639
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGGW",
                "STATION_NAME": "GLASGOW",
                "STATE": "MT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -82.22028,
                    34.88306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGSP",
                "STATION_NAME": "GREER",
                "STATE": "SC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -87.285,
                    36.73667
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHPX",
                "STATION_NAME": "FT CAMPBELL",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.28028,
                    39.7075
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KIND",
                "STATION_NAME": "INDIANAPOLIS",
                "STATE": "IN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.70194,
                    30.48444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KJAX",
                "STATION_NAME": "JACKSONVILLE",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.35111,
                    32.675
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KJGX",
                "STATION_NAME": "ROBINS AFB",
                "STATE": "GA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.31306,
                    37.59083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KJKL",
                "STATION_NAME": "JACKSON",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.08472,
                    41.60444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLOT",
                "STATION_NAME": "CHICAGO",
                "STATE": "IL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -77.47806,
                    38.97528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLWX",
                "STATION_NAME": "STERLING",
                "STATE": "VA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.325,
                    47.52806
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMVX",
                "STATION_NAME": "GRAND FORKS",
                "STATE": "ND"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -72.86444,
                    40.86556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KOKX",
                "STATION_NAME": "NEW YORK CITY",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -93.84111,
                    32.45056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSHV",
                "STATION_NAME": "SHREVEPORT",
                "STATE": "LA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -102.82944,
                    44.125
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KUDX",
                "STATION_NAME": "RAPID CITY",
                "STATE": "SD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -114.65583,
                    32.49528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KYUX",
                "STATION_NAME": "YUMA",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -67.80694,
                    46.03917
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCBW",
                "STATION_NAME": "HOULTON",
                "STATE": "ME"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -71.1375,
                    41.95583
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBOX",
                "STATION_NAME": "BOSTON",
                "STATE": "MA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.86,
                    41.41306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCLE",
                "STATION_NAME": "CLEVELAND",
                "STATE": "OH"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.45944,
                    31.46028
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEOX",
                "STATION_NAME": "FT RUCKER",
                "STATE": "AL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.54472,
                    42.89389
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGRR",
                "STATION_NAME": "GRAND RAPIDS",
                "STATE": "MI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.82167,
                    39.42028
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KILN",
                "STATION_NAME": "CINCINNATI",
                "STATE": "OH"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.7,
                    41.40861
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KIWX",
                "STATION_NAME": "FT WAYNE",
                "STATE": "IN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -102.18889,
                    31.94333
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMAF",
                "STATION_NAME": "MIDLAND ODESSA",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -113.98611,
                    47.04111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMSX",
                "STATION_NAME": "MISSOULA",
                "STATE": "MT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -112.44694,
                    41.26278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMTX",
                "STATION_NAME": "SALT LAKE CITY",
                "STATE": "UT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.32889,
                    30.3975
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTLH",
                "STATION_NAME": "TALLAHASSEE",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -120.39583,
                    34.83806
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KVBX",
                "STATION_NAME": "VANDENBERG AFB",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -161.87417,
                    60.79278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PABC",
                "STATION_NAME": "BETHEL FAA",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    144.80833,
                    13.45444
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PGUA",
                "STATION_NAME": "ANDERSEN AFB AGANA",
                "STATE": "GU"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.27417,
                    37.02417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFCX",
                "STATION_NAME": "ROANOKE",
                "STATE": "VA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -95.07889,
                    29.47194
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHGX",
                "STATION_NAME": "HOUSTON",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -89.33667,
                    40.15056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KILX",
                "STATION_NAME": "LINCOLN",
                "STATE": "IL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -90.40740,
                    30.5195999
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHDC",
                "STATION_NAME": "NEW ORLEANS",
                "STATE": "LA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -78.42917,
                    33.98917
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLTX",
                "STATION_NAME": "WILMINGTON",
                "STATE": "NC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -87.54833,
                    46.53111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMQT",
                "STATION_NAME": "MARQUETTE",
                "STATE": "MI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -121.8975,
                    37.15528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMUX",
                "STATION_NAME": "SAN FRANCISCO",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.78972,
                    32.53667
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMXX",
                "STATION_NAME": "MAXWELL AFB",
                "STATE": "AL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -118.85278,
                    45.69056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KPDT",
                "STATION_NAME": "PENDLETON",
                "STATE": "OR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -92.97583,
                    31.15528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KPOE",
                "STATION_NAME": "FT POLK",
                "STATE": "LA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.635,
                    33.81778
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSOX",
                "STATION_NAME": "SANTA ANA MOUNTAINS",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -119.17861,
                    34.41167
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KVTX",
                "STATION_NAME": "LOS ANGELES",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -146.30111,
                    59.46194
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PAIH",
                "STATION_NAME": "MIDDLETON ISLAND",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -78.00389,
                    40.92306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCCX",
                "STATION_NAME": "STATE COLLEGE",
                "STATE": "PA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -121.63167,
                    39.49611
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBBX",
                "STATION_NAME": "BEALE AFB",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -75.985,
                    42.19972
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBGM",
                "STATION_NAME": "BINGHAMTON",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -124.29194,
                    40.49833
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBHX",
                "STATION_NAME": "EUREKA",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.51083,
                    27.78389
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCRP",
                "STATION_NAME": "CORPUS CHRISTI",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -121.67667,
                    38.50111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDAX",
                "STATION_NAME": "SACRAMENTO",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -93.72278,
                    41.73111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDMX",
                "STATION_NAME": "DES MOINES",
                "STATE": "IA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -94.26417,
                    38.81028
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEAX",
                "STATION_NAME": "KANSAS CITY",
                "STATE": "MO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.92139,
                    30.56417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEVX",
                "STATION_NAME": "EGLIN AFB",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -98.02806,
                    29.70361
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEWX",
                "STATION_NAME": "AUSTIN SAN ANTONIO",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.71972,
                    44.90722
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KAPX",
                "STATION_NAME": "GAYLORD",
                "STATE": "MI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.41861,
                    25.91556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBRO",
                "STATION_NAME": "BROWNSVILLE",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.04222,
                    32.65556
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCLX",
                "STATION_NAME": "CHARLESTON",
                "STATE": "SC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -99.96833,
                    37.76083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDDC",
                "STATION_NAME": "DODGE CITY",
                "STATE": "KS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -90.58083,
                    41.61167
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDVN",
                "STATION_NAME": "DAVENPORT",
                "STATE": "IA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.30278,
                    32.57278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFWS",
                "STATION_NAME": "DALLAS",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.38278,
                    30.72167
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGRK",
                "STATION_NAME": "FT HOOD",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -70.25694,
                    43.89139
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGYX",
                "STATION_NAME": "PORTLAND",
                "STATE": "ME"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -111.66917,
                    33.28917
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KIWA",
                "STATION_NAME": "PHOENIX",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.94389,
                    37.97528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLVX",
                "STATION_NAME": "LOUISVILLE",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -77.48751,
                    38.97628
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLWX",
                "STATION_NAME": "STERLING",
                "STATE": "VA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -122.71611,
                    42.08111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMAX",
                "STATION_NAME": "MEDFORD",
                "STATE": "OR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -76.87639,
                    34.77583
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMHX",
                "STATION_NAME": "MOREHEAD CITY",
                "STATE": "NC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.23972,
                    30.67944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMOB",
                "STATION_NAME": "MOBILE",
                "STATE": "AL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -93.56528,
                    44.84889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMPX",
                "STATION_NAME": "MINNEAPOLIS",
                "STATE": "MN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.5625,
                    36.24722
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KOHX",
                "STATION_NAME": "NASHVILLE",
                "STATE": "TN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.21833,
                    40.53167
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KPBZ",
                "STATION_NAME": "PITTSBURGH",
                "STATE": "PA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -93.40028,
                    37.23528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSGF",
                "STATION_NAME": "SPRINGFIELD",
                "STATE": "MO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.2775,
                    35.33306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTLX",
                "STATION_NAME": "OKLAHOMA CITY",
                "STATE": "OK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -151.351467,
                    60.725914
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PAHG",
                "STATION_NAME": "ANCHORAGE",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -77.0075,
                    36.98389
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KAKQ",
                "STATION_NAME": "NORFOLK RICH",
                "STATE": "VA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -101.70889,
                    35.23333
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KAMA",
                "STATION_NAME": "AMARILLO",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.41306,
                    25.61056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KAMX",
                "STATION_NAME": "MIAMI",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -100.76028,
                    46.77083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KBIS",
                "STATION_NAME": "BISMARCK",
                "STATE": "ND"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -89.98444,
                    32.28
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDGX",
                "STATION_NAME": "JACKSON BRANDON",
                "STATE": "MS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -74.06444,
                    42.58639
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KENX",
                "STATION_NAME": "ALBANY",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -103.62944,
                    34.63528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFDX",
                "STATION_NAME": "CANNON AFB",
                "STATE": "NM"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.56583,
                    33.36333
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFFC",
                "STATION_NAME": "ATLANTA",
                "STATE": "GA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -95.56444,
                    36.175
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KINX",
                "STATION_NAME": "TULSA",
                "STATE": "OK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -92.26194,
                    34.83639
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLZK",
                "STATION_NAME": "LITTLE ROCK",
                "STATE": "AR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -100.86444,
                    48.3925
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMBX",
                "STATION_NAME": "MINOT AFB",
                "STATE": "ND"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.40194,
                    36.16833
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMRX",
                "STATION_NAME": "KNOXVILLE",
                "STATE": "TN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.62583,
                    47.68056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KOTX",
                "STATION_NAME": "SPOKANE",
                "STATE": "WA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -119.46111,
                    39.75417
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KRGX",
                "STATION_NAME": "RENO",
                "STATE": "NV"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -108.47667,
                    43.06611
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KRIW",
                "STATION_NAME": "RIVERTON",
                "STATE": "WY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -98.44167,
                    40.32083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KUEX",
                "STATION_NAME": "HASTINGS",
                "STATE": "NE"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -135.52917,
                    56.85278
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PACG",
                "STATION_NAME": "SITKA",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -96.72889,
                    43.58778
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KFSD",
                "STATION_NAME": "SIOUX FALLS",
                "STATE": "SD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -94.36167,
                    35.29056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KSRX",
                "STATION_NAME": "FT SMITH",
                "STATE": "AR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -108.21306,
                    39.06222
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGJX",
                "STATION_NAME": "GRAND JUNCTION",
                "STATE": "CO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -93.21583,
                    30.125
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLCH",
                "STATION_NAME": "LAKE CHARLES",
                "STATE": "LA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -156.62944,
                    58.67944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PAKC",
                "STATION_NAME": "KING SALMON",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.11861,
                    33.94861
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KCAE",
                "STATION_NAME": "COLUMBIA",
                "STATE": "SC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -100.28028,
                    29.2725
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDFX",
                "STATION_NAME": "LAUGHLIN AFB",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.32889,
                    33.89667
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KGWX",
                "STATION_NAME": "COLUMBUS AFB",
                "STATE": "MS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.56,
                    35.09778
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEYX",
                "STATION_NAME": "EDWARDS",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -106.6975,
                    31.87306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KEPZ",
                "STATION_NAME": "EL PASO",
                "STATE": "NM"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -116.80278,
                    40.73972
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KLRX",
                "STATION_NAME": "ELKO",
                "STATE": "NV"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.72389,
                    38.31194
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KRLX",
                "STATION_NAME": "CHARLESTON",
                "STATE": "WV"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -106.12222,
                    33.07639
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHDX",
                "STATION_NAME": "HOLLOMAN AFB",
                "STATE": "NM"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -112.86222,
                    37.59083
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KICX",
                "STATION_NAME": "CEDAR CITY",
                "STATE": "UT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.65444,
                    28.11306
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KMLB",
                "STATION_NAME": "MELBOURNE",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -117.04194,
                    32.91889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KNKX",
                "STATION_NAME": "SAN DIEGO",
                "STATE": "CA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -165.295,
                    64.51139
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:PAEC",
                "STATION_NAME": "NOME",
                "STATE": "AK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -99.25417,
                    32.53833
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KDYX",
                "STATION_NAME": "DYESS AFB",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.08361,
                    34.93056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KHTX",
                "STATION_NAME": "HUNTSVILLE",
                "STATE": "AL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -96.36639,
                    41.32028
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KOAX",
                "STATION_NAME": "OMAHA",
                "STATE": "NE"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -82.40194,
                    27.70528
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTBW",
                "STATION_NAME": "TAMPA",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -75.68,
                    43.75583
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:KTYX",
                "STATION_NAME": "FT DRUM",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -76.845,
                    38.695
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TADW",
                "STATION_NAME": "ANDREWS AFB",
                "STATE": "MD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.261944,
                    33.646944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TATL",
                "STATION_NAME": "ATLANTA",
                "STATE": "GA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.661944,
                    35.98
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TBNA",
                "STATION_NAME": "NASHVILLE",
                "STATE": "TN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -70.933056,
                    42.158056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TBOS",
                "STATION_NAME": "BOSTON",
                "STATE": "MA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -76.63,
                    39.09
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TBWI",
                "STATION_NAME": "BALTIMORE WASHINGTON",
                "STATE": "MD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.885,
                    35.336944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TCLT",
                "STATION_NAME": "CHARLOTTE",
                "STATE": "NC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -82.715,
                    40.006111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TCMH",
                "STATION_NAME": "COLUMBUS",
                "STATE": "OH"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.58,
                    38.898056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TCVG",
                "STATION_NAME": "COVINGTON",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -96.968056,
                    32.926111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDAL",
                "STATION_NAME": "DALLAS LOVE FLD",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -84.123056,
                    40.021944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDAY",
                "STATION_NAME": "DAYTON",
                "STATE": "OH"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -76.961944,
                    38.758889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDCA",
                "STATION_NAME": "WASHINGTON NATL",
                "STATE": "MD"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -104.526111,
                    39.728056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDEN",
                "STATION_NAME": "DENVER",
                "STATE": "CO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -96.918056,
                    33.065
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDFW",
                "STATION_NAME": "DALLAS FT WORTH",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -83.515,
                    42.111111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TDTW",
                "STATION_NAME": "DETROIT",
                "STATE": "MI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -74.27,
                    40.593056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TEWR",
                "STATION_NAME": "NEWARK",
                "STATE": "NJ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.343889,
                    26.143056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TFLL",
                "STATION_NAME": "FT LAUDERDALE",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -95.241944,
                    29.516111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:THOU",
                "STATION_NAME": "HOUSTON HOBBY",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -77.528889,
                    39.083889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TIAD",
                "STATION_NAME": "WASHINGTON DULLES",
                "STATE": "VA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -95.566944,
                    30.065
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TIAH",
                "STATION_NAME": "HOUSTON INTL",
                "STATE": "TX"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.436944,
                    37.506944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TICH",
                "STATION_NAME": "WICHITA",
                "STATE": "KS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -86.436111,
                    39.636944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TIDS",
                "STATION_NAME": "INDIANAPOLIS",
                "STATE": "IN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -73.881111,
                    40.588889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TJFK",
                "STATION_NAME": "NYC JFK",
                "STATE": "NY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -66.07861,
                    18.1175
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TJUA",
                "STATION_NAME": "SAN JUAN",
                "STATE": "PR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -115.006944,
                    36.143889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TLAS",
                "STATION_NAME": "LAS VEGAS",
                "STATE": "NV"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -82.008056,
                    41.29
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TLVE",
                "STATION_NAME": "CLEVELAND",
                "STATE": "OH"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -94.741944,
                    39.498056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMCI",
                "STATION_NAME": "KANSAS CITY",
                "STATE": "MO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -81.326111,
                    28.343889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMCO",
                "STATION_NAME": "ORLANDO INTL",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -87.713333,
                    41.651111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMDW",
                "STATION_NAME": "CHICAGO MIDWAY",
                "STATE": "IL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -89.993056,
                    34.896111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMEM",
                "STATION_NAME": "MEMPHIS",
                "STATE": "MS"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.491111,
                    25.758056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMIA",
                "STATION_NAME": "MIAMI",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -88.046111,
                    42.818889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMKE",
                "STATION_NAME": "MILWAUKEE",
                "STATE": "WI"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -92.933056,
                    44.871111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMSP",
                "STATION_NAME": "MINNEAPOLIS",
                "STATE": "MN"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -90.403056,
                    30.021944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TMSY",
                "STATION_NAME": "NEW ORLEANS",
                "STATE": "LA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -97.51,
                    35.276111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TOKC",
                "STATION_NAME": "NORMAN WFO",
                "STATE": "OK"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -87.858056,
                    41.796944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TORD",
                "STATION_NAME": "CHICAGO OHARE",
                "STATE": "IL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.273056,
                    26.688056
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TPBI",
                "STATION_NAME": "W PALM BEACH",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -75.068889,
                    39.948889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TPHL",
                "STATION_NAME": "PHILA",
                "STATE": "NJ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -112.163056,
                    33.421111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TPHX",
                "STATION_NAME": "PHOENIX",
                "STATE": "AZ"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -80.486111,
                    40.501111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TPIT",
                "STATION_NAME": "PITTSBURGH",
                "STATE": "PA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -78.696944,
                    36.001944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TRDU",
                "STATION_NAME": "RALEIGH",
                "STATE": "NC"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -85.61,
                    38.046111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TSDF",
                "STATION_NAME": "LOUISVILLE",
                "STATE": "KY"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -66.178889,
                    18.473889
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TSJU",
                "STATION_NAME": "SAN JUAN",
                "STATE": "PR"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -111.93,
                    40.966944
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TSLC",
                "STATION_NAME": "SALT LAKE CITY",
                "STATE": "UT"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -90.488889,
                    38.805
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TSTL",
                "STATION_NAME": "ST LOUIS",
                "STATE": "MO"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -82.518056,
                    27.86
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TTPA",
                "STATION_NAME": "TAMPA",
                "STATE": "FL"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -95.826944,
                    36.071111
                ]
            },
            "properties": {
                "STATION_ID": "NEXRAD:TTUL",
                "STATION_NAME": "TULSA",
                "STATE": "OK"
            }
        }
    ]
}

export { radarSites }