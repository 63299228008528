const SINGLE = Symbol('single');
const DOUBLE = Symbol('double');
const SINGLE_HIDDEN = Symbol('single_hidden');

const MAP_OBJECT = {
    REFLECTIVITY: Symbol('reflectivity'),
    VELOCITY: Symbol('velocity'),
    VELOCITY_DEALIASED: Symbol('velocity_dealiased'),
    CC:Symbol('cc'),
    PHI:Symbol('phi'),
    SW:Symbol('sw'),
    ZDR:Symbol('zdr')
}

const DEALIAS_OBJECT = {
    DEALIASED: Symbol('dealiased'),
    RAW: Symbol('raw')
}

const INT_OBJECT = {
    READING: Symbol('reading'),
    MODE3D: Symbol('mode3d'),
    CROSS: Symbol('cross'),
    LIVE: Symbol('live')
}

const VIEW_OBJECT = {
    FILE: Symbol('file'),
    ELEVATION: Symbol('elevation')
}

const MODE_OBJECT = {
    HISTORICAL: Symbol('historical'),
    LIVE: Symbol('live')
}

export { SINGLE, DOUBLE, SINGLE_HIDDEN, MAP_OBJECT, DEALIAS_OBJECT, INT_OBJECT, VIEW_OBJECT, MODE_OBJECT }