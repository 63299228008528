import './FileDrop.svelte.css';
/* src/FileDrop.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	toggle_class
} from "../_snowpack/pkg/svelte/internal.js";

import { scans, worker, error, selections, sites, animation } from './store.js';
import TinyQueue from '../_snowpack/pkg/tinyqueue.js';
import * as pako from '../_snowpack/pkg/pako.js';
import { UpdateData } from './UpdateClass.js';

function create_if_block(ctx) {
	let div2;
	let div0;
	let t0;
	let t1_value = Math.min(/*numLoaded*/ ctx[1] + 1, /*count*/ ctx[0]) + "";
	let t1;
	let t2;
	let t3;
	let t4;
	let div1;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t0 = text("Reading ");
			t1 = text(t1_value);
			t2 = text(" of ");
			t3 = text(/*count*/ ctx[0]);
			t4 = space();
			div1 = element("div");
			attr(div0, "class", "of svelte-18cfpa4");
			attr(div1, "class", "lds-dual-ring svelte-18cfpa4");
			attr(div2, "class", "loadingWrapper svelte-18cfpa4");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, t0);
			append(div0, t1);
			append(div0, t2);
			append(div0, t3);
			append(div2, t4);
			append(div2, div1);
		},
		p(ctx, dirty) {
			if (dirty & /*numLoaded, count*/ 3 && t1_value !== (t1_value = Math.min(/*numLoaded*/ ctx[1] + 1, /*count*/ ctx[0]) + "")) set_data(t1, t1_value);
			if (dirty & /*count*/ 1) set_data(t3, /*count*/ ctx[0]);
		},
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let input0;
	let t0;
	let label;
	let input1;
	let t1;
	let t2;
	let mounted;
	let dispose;
	let if_block = /*count*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			input0 = element("input");
			t0 = space();
			label = element("label");
			input1 = element("input");
			t1 = text("\n            Add Level II files");
			t2 = space();
			if (if_block) if_block.c();
			attr(input0, "type", "file");
			attr(input0, "id", "input");
			input0.multiple = true;
			attr(input0, "class", "svelte-18cfpa4");
			attr(input1, "type", "file");
			input1.multiple = true;
			attr(input1, "class", "svelte-18cfpa4");
			attr(label, "class", "custom-file-upload svelte-18cfpa4");
			attr(div0, "class", "dragover svelte-18cfpa4");
			toggle_class(div0, "active", /*hovering*/ ctx[2]);
			attr(div1, "class", "dragoverContainer svelte-18cfpa4");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, input0);
			append(div0, t0);
			append(div0, label);
			append(label, input1);
			/*input1_binding*/ ctx[10](input1);
			append(label, t1);
			/*label_binding*/ ctx[11](label);
			append(div1, t2);
			if (if_block) if_block.m(div1, null);

			if (!mounted) {
				dispose = [
					listen(input1, "change", /*handleFiles*/ ctx[8]),
					listen(div0, "dragenter", /*handleDragEnter*/ ctx[5]),
					listen(div0, "dragover", handleDragOver),
					listen(div0, "dragleave", /*handleDragLeave*/ ctx[6]),
					listen(div0, "drop", /*handleDragDrop*/ ctx[7])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*hovering*/ 4) {
				toggle_class(div0, "active", /*hovering*/ ctx[2]);
			}

			if (/*count*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			/*input1_binding*/ ctx[10](null);
			/*label_binding*/ ctx[11](null);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function handleDragOver(e) {
	e.preventDefault();
}

function instance($$self, $$props, $$invalidate) {
	let $scans;
	let $sites;
	let $selections;
	let $error;
	let $worker;
	component_subscribe($$self, scans, $$value => $$invalidate(9, $scans = $$value));
	component_subscribe($$self, sites, $$value => $$invalidate(13, $sites = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(14, $selections = $$value));
	component_subscribe($$self, error, $$value => $$invalidate(15, $error = $$value));
	component_subscribe($$self, worker, $$value => $$invalidate(16, $worker = $$value));
	const updateInstance = UpdateData();

	//queue to hold files to load, fifo comparator
	let queue = new TinyQueue([],
	function (a, b) {
			return b.time - a.time;
		});

	let count = 0;
	let numLoaded = 0;
	let hovering = false;
	let percent;
	let b;
	let fileInput;

	function emptyQueue() {
		while (queue.length) {
			const reader = new FileReader();
			let file = queue.pop();

			reader.onload = function (event) {
				const buffer = event.target.result;
				let firstBytes = new Uint8Array(buffer);

				try {
					const result = pako.inflate(firstBytes);
					firstBytes = result;
				} catch(err) {
					
				} //don't set if didn't work

				let toMatch = '';

				for (let i = 0; i < 6; i++) {
					toMatch = toMatch + String.fromCharCode(firstBytes[i]);
				}

				if (toMatch != 'AR2V00' && toMatch != "ARCHIV") {
					$$invalidate(0, count -= 1);

					if (toMatch === "ARCHIV") {
						set_store_value(
							error,
							$error = {
								error: true,
								message: 'The application cannot yet read files in an old format like ' + file.name + '.',
								header: 'Old format'
							},
							$error
						);
					} else {
						set_store_value(
							error,
							$error = {
								error: true,
								message: 'The file ' + file.name + ' could not be read due to an unexpected format.',
								header: 'Unexpected format'
							},
							$error
						);
					}

					return;
				}

				// Transfer the underlying buffer to a worker
				$worker.postMessage(
					{
						"message": "initialize",
						fileName: file.name,
						buffer: firstBytes.buffer
					},
					[firstBytes.buffer]
				);
			};

			reader.readAsArrayBuffer(file);
		}
	}

	function handleDragEnter(e) {
		e.preventDefault();
		$$invalidate(2, hovering = true);
		$$invalidate(3, b.style.pointerEvents = 'none', b);
	}

	function handleDragLeave(e) {
		e.preventDefault();
		$$invalidate(2, hovering = false);
		$$invalidate(3, b.style.pointerEvents = '', b);
	}

	function fileWork(files) {
		let filesLoaded = 0;

		for (let i = 0; i < $scans.length; i++) {
			if ($scans[i]) {
				filesLoaded++;
			}
		}

		const loadedNames = $scans.filter(x => x != undefined).map(x => x.fileName);
		let forQueue = [];

		for (let i = 0; i < files.length; i++) {
			let file = files[i];

			if (loadedNames.indexOf(file.name) >= 0) {
				set_store_value(
					error,
					$error = {
						error: true,
						message: 'A file already exists with the name ' + file.name,
						header: 'Duplicate file'
					},
					$error
				);

				//clear so onchange will fire again
				$$invalidate(4, fileInput.value = null, fileInput);

				return;
			}

			;
			forQueue.push(file);
		}

		//don't even add to queue if too many files
		if (count + files.length > 5 || files.length + filesLoaded > 5) {
			set_store_value(
				error,
				$error = {
					error: true,
					message: 'The application cannot load more than 5 files. Remove a file by clicking the trashcan icon in the File View before loading another.',
					header: 'Too many files'
				},
				$error
			);

			//clear so onchange will fire again
			$$invalidate(4, fileInput.value = null, fileInput);

			return;
		}

		//add to queue
		for (let i = 0; i < forQueue.length; i++) {
			queue.push(forQueue[i]);
		}

		$$invalidate(0, count = count + forQueue.length);

		//empty queue
		emptyQueue();

		//clear so onchange will fire again
		$$invalidate(4, fileInput.value = null, fileInput);
	}

	function handleDragDrop(e) {
		$$invalidate(3, b.style.pointerEvents = '', b);
		e.preventDefault();
		$$invalidate(2, hovering = false);

		if (e.dataTransfer.items) {
			let toPass = [];

			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === 'file') {
					let file = e.dataTransfer.items[i].getAsFile();
					file.time = Date.now();
					toPass.push(file);
				}
			}

			fileWork(toPass);
		}
	}

	function updatePercent() {
		if (!count) return;

		if ($scans.finished === 1) {
			percent = 1;
			$$invalidate(1, numLoaded += 1);
		}

		//load if no selections
		if (numLoaded === count) {
			percent = -1;
			$$invalidate(1, numLoaded = 0);
			$$invalidate(0, count = 0);

			//no selections
			if (!$selections[0].scan) {
				const e = {
					elevation: $sites.elevations[$sites.stations[0]][0],
					station: $sites.stations[0]
				};

				const selectedScans = $sites.scans[e.station][e.elevation];

				const localScan = {
					fileNumber: selectedScans[0][0].fileNumber,
					scan: selectedScans[0],
					fileName: selectedScans[0][0].fileName,
					station: e.station
				};

				updateInstance.logStore(localScan);
				animation.setStates();
				animation.setSites();
			}
		} else {
			if ($scans[$scans.loading]) {
				let localLoading = $scans.loading;
				let localPercent = $scans[localLoading].percent;
				if (localPercent != undefined) percent = localPercent;
			}
		}
	}

	function handleFiles() {
		const fileList = this.files;
		let toPass = [];

		for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
			const file = fileList[i];
			file.time = Date.now();
			toPass.push(file);
		}

		fileWork(toPass);
	}

	function input1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			fileInput = $$value;
			$$invalidate(4, fileInput);
		});
	}

	function label_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			b = $$value;
			$$invalidate(3, b);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$scans*/ 512) {
			$: ($scans, updatePercent());
		}
	};

	return [
		count,
		numLoaded,
		hovering,
		b,
		fileInput,
		handleDragEnter,
		handleDragLeave,
		handleDragDrop,
		handleFiles,
		$scans,
		input1_binding,
		label_binding
	];
}

class FileDrop extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default FileDrop;