function closest (num, arr) {
    let idx = 0;
    let curr = arr[0];
    let diff = Math.abs (num - curr);
    for (let val = 0; val < arr.length; val++) {
        let newdiff = Math.abs (num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
            idx = val;
        }
    }
    return idx;
}

function beamHeight(lambda0, phi1, lambda, phi, elevation) {
    const re = 6378.1;
    const radians = Math.PI/180.0;

    const c = Math.acos(
        Math.sin(radians*phi1)*Math.sin(radians*phi)
        + Math.cos(radians*phi1)*Math.cos(radians*phi)
        * Math.cos(radians*lambda - radians*lambda0)
    )

    const kprime = c/Math.sin(c);

    const x = kprime*Math.cos(radians*phi)*Math.sin(radians*lambda - radians*lambda0);
    const y = kprime*(
        Math.cos(phi1*radians)*Math.sin(phi*radians)
        - Math.sin(phi1*radians) * Math.cos(phi*radians)
        * Math.cos(lambda*radians - lambda0*radians)
    )

    const h = 6378.1*Math.sqrt(x*x + y*y);
  
    const height = Math.tan(radians*elevation)*h;

    const curv = re - re * Math.sin((Math.PI/2) - h/re)
   
    return height + curv;
}

export { closest, beamHeight }