import { worker, scans, sites, selections, currentFile, vertexData, texture_3d, MODE_STATE, LOADING_LIVE, MOST_RECENT, ANIMATING, alerts, lightning, auth, radarMetadata, error } from './store.js';
import { get } from '../_snowpack/pkg/svelte/store.js';
import {MODE_OBJECT} from './enums.js';
import { UpdateData } from './UpdateClass.js';

const updateInstance = UpdateData();

const {HISTORICAL, LIVE} = MODE_OBJECT;


function Socket() {
    
    let ws;
    let site;
    let connectionId;
    //let toProcess = [];
    let processing = [];
    let state = "READY";
    let triggerFunction;
    let cancelFunction;
    let toLoad = 0;
    let needLoop = false;
    let intervalId;
    let pongId;
    //let events = [];
    const toProcess = new Map();


    function setTrigger(callback) {
        triggerFunction = callback;
    }

    function setCancel(callback) {
        cancelFunction = callback;
    }

    //response from wasm worker
    //1. finished processing file contents (initialize)
    //2. finished deleting a file (deleted)
    //3. don't process the file because duplicate processing attempted (noop)
    //4. tried processing before another file successfully deleted (try_again)
    function handleQueue(message) {
        if (get(MODE_STATE) != LIVE) return;

        let firstKey, item;

        //console.log("message", message)
        if (message.message === "initialize") {
            toLoad-=1;
            //console.log("sending handle", toLoad);
            if (triggerFunction) triggerFunction(toLoad);

            if (state === "READY") {
                processing.shift();
                [firstKey] = toProcess.keys();
                item = toProcess.get(firstKey);
                if (item) checkProcess(item.fileName);
            }
        }

        if (message.message === "try_again") {
            const element = {
                "message":"initialize",
                fileName: message.fileName,
                buffer: message.buffer
            };

            //console.log("try again element", element);

            toProcess.set(message.fileName, element);

            if (state === "READY") {
                processing.shift();
                [firstKey] = toProcess.keys();
                item = toProcess.get(firstKey);
                if (item) checkProcess(item.fileName);
            }
        }

        if (message.message === "deleted") {
            //events.push(message);
            //console.log(events);
            state = "READY"
        } 

        if (message.message === "noop") {
            //state = "READY"
            toLoad-=1;
            console.log("received noop", toLoad);
            if (triggerFunction) triggerFunction(toLoad);

            if (toProcess.has(message.fileName)) {
                toProcess.delete(message.fileName);
            }

            if (state === "READY") {
                processing.shift();
                [firstKey] = toProcess.keys();
                item = toProcess.get(firstKey);
                if (item) checkProcess(item.fileName);
            }
        }

    }

    function checkProcess(fileName) {
        //if (toProcess.length === 0) return;
      
        if (toProcess.size === 0) return;
       
        if (processing.length === 0) {
            //const element = toProcess.shift();
            const element = toProcess.get(fileName);

            if (element === undefined) {
                return;
            } else {
                toProcess.delete(fileName);
            }

            processing.push(element);

            //first check to delete message if needed
            checkAndDelete();

            //then post new data for loading
            //console.log("posting initialize")
           
            get(worker).postMessage(element, [element.buffer]);

        }
    }

    function updateSites() {
        const localScans = get(scans);
        const d = get(sites);

        //console.log("updateSite localScans", localScans);

        Object.assign(d, {fileNames:[], elevations:[], stations:[], scans:[]});

        d.fileNames = [];
        d.stations = [];
        d.scans = {};
        d.elevations = {};
        const siteSets = {};
        const scanArrays = {};

        //loop through scans (files)
        for (let i=0; i<localScans.length; i++) {
            //only process those that are defined
            if (localScans[i] != undefined) {
                //want all filenames, they're unique (or you'd think)
                d.fileNames.push(localScans[i].fileName);

                //only want to save unique stations
                if (d.stations.indexOf(localScans[i].station) < 0) d.stations.push(localScans[i].station);

                //elevations (keys for later)
                if (localScans[i].station in siteSets) {
                    //concatenate if already exists
                    siteSets[localScans[i].station] = siteSets[localScans[i].station].concat(Object.keys(localScans[i].idxs));
                } else {
                    siteSets[localScans[i].station] = Object.keys(localScans[i].idxs);
                }

                //scans
                if (localScans[i].station in scanArrays) {
                    scanArrays[localScans[i].station] = scanArrays[localScans[i].station].concat(localScans[i].scans);
                } else {
                    scanArrays[localScans[i].station] = localScans[i].scans;
                }
            }
        }

        //loop through each station
        for (let i=0; i<d.stations.length; i++) {
            const key = d.stations[i];

            //reset all properties
            d.elevations[key] = [];
            d.scans[key] = {};

            //get unique elevations sorted ascending
            d.elevations[key] = Array.from(new Set(siteSets[key])).sort(function(a,b) {
                return (+a)-(+b);
            });

            const allScans = scanArrays[key];
            //initialize object with all keys necessary
            for (let j=0; j<d.elevations[key].length; j++) {
                d.scans[key][d.elevations[key][j]] = [];
            }

            //loop through scan array
            for (let j=0; j<allScans.length; j++) {
                d.scans[key][allScans[j][0][0].rounded].push(...allScans[j]);
            }

            //final loop to sort each elevation by time
            for (let k in d.scans[key]) {
                d.scans[key][k] = d.scans[key][k].sort(function(a,b) {
                    return a[0].date - b[0].date;
                })
            }
        }
        //console.log("updated sites", d);
        //console.log("localscans", localScans)
    }   


    function checkAndDelete() {
        //first, remove client-side references
       
        const localScans = get(scans);

        const filtered = localScans.filter(d => {
            //if (d) console.log(d.fileName, d.scans);
            return d != undefined
        });

        //console.log("new check and delete", filtered, filtered.length);

        let now = Date.now();
        let toDelete, fileName;
        //need to delete
        if (filtered.length === 5) {
            //get list of first times
            for (let i=0; i<filtered.length; i++) {
                //console.log("i",i, filtered[i])
                if (!filtered[i].scans || !filtered[i].scans[0]) {
                    toDelete = i;
                    fileName = filtered[i].fileName;
                    break;
                } else {
                    const d = filtered[i].scans[0][0][0].date;
                    if (d < now) {
                        toDelete = i;
                        fileName = filtered[i].fileName;
                        now = d;
                    }
                }
            }

            //console.log("deleting", toDelete, localScans[toDelete].fileName, localScans[toDelete].scans[0][0][0].date);

            scans.update(d => {
                d[toDelete] = undefined;
                return d;
            })

            //update $sites since $scans has been updated
            //updateSites();

            //get(scans)[toDelete] = undefined;
            //console.log("scans afte delete", get(selections));

            let localSelections = get(selections);
            let localSites = get(sites);

            //console.log("localSites.scans", localSites.scans);
            //console.log("localSelections[0].scan", localSelections[0].scan);

            let station_state;
            let elevation_state;
            let selectedScans;
            
            //just trying to see if need to change selections
            station_state = localSelections[0].scan ? localSelections[0].scan.station : undefined;
            //console.log("station_state", station_state);

            //selected station may have been deleted
            const keys = Object.keys(localSites.scans);

            if (keys.indexOf(station_state) < 0) {
                
                // setSite(keys[0]);
                // station_state = keys[0];
                // const elevationKeys = Object.keys(localSites.scans[keys[0]]);
                // elevation_state = elevationKeys[0];
                // selectedScans = localSites.scans[station_state][elevation_state];

                // const localScan = {
                //     fileNumber: selectedScans[0][0].fileNumber,
                //     scan:selectedScans[0],
                //     fileName: selectedScans[0][0].fileName,
                //     station:station_state
                // }
        
                // updateInstance.logStore(localScan);
                // currentFile.set(selectedScans[0][0].fileNumber);
                // //update selection variables
                // localSelections = get(selections);
                // localSites = get(sites);

                selections.set([
                    {
                        elevation:null,
                        file:null,
                        type:null,
                        dealiased:null,
                        scan:null
                    },
                    {
                        elevation:null,
                        file:null,
                        type:null,
                        dealiased:null,
                        scan:null
                    }
                ])

                currentFile.set(null);

                radarMetadata.set({});

            } else {
                //nothing to worry about
                elevation_state = localSelections[0].scan ? localSelections[0].scan.scan[0].rounded : undefined;
                station_state = localSelections[0].scan ? localSelections[0].scan.station : undefined;
                selectedScans = localSites.scans && localSelections[0].scan ? localSites.scans[station_state][elevation_state] : undefined;

                //get date of selected scan
                const selectionsDate = localSelections[0].scan.scan[0].date.getTime();

                let matchIdx;

                //find scan from elevation array with same date, there's your index
                for (let i=0; i<selectedScans.length; i++) {
                
                    if (selectedScans[i][0].date.getTime() === selectionsDate) {
                        matchIdx = i;
                        break;
                    }
                }

                if (!matchIdx && selectedScans.length > 0) {
                    const localScan = {
                        fileNumber: selectedScans[0][0].fileNumber,
                        scan:selectedScans[0],
                        fileName: selectedScans[0][0].fileName,
                        station:station_state
                    }
            
                    updateInstance.logStore(localScan);

                    currentFile.set(selectedScans[0][0].fileNumber);
                }
                
            }

            //remove any and all vertex arrays
            vertexData.update(
                d => {
                    d[toDelete] = undefined;
                    return d;
                }
            )

            texture_3d.update(
                d => {
                    d[toDelete] = undefined;
                    return d;
                }
            )
               
            state = "DELETING";
           
            // events.push({
            //     message:"deleteFile",
            //     data:{
            //         fileNum:toDelete,
            //         fileName: fileName
            //     }
            // });
            // console.log(events);

            //remove memory from C
            get(worker).postMessage({
                message:"deleteFile",
                data:{
                    fileNum:toDelete,
                    fileName: fileName
                }
            })

        } //end length is 5

    }

    function deleteAll() {

        const localScans = get(scans);

        for (let i=0; i<localScans.length; i++) {
           
            if (localScans[i] === undefined) continue;
            if (localScans[i].station === site) continue;

            let fileName = localScans[i].fileName;
            let fileNumber = localScans[i].fileNumber;

            //erase settings if deleting currently shown scan
            if (localScans[i].fileNumber === get(selections)[0].file) {
                selections.set([
                    {
                        elevation:null,
                        file:null,
                        type:null,
                        dealiased:null,
                        scan:null
                    },
                    {
                        elevation:null,
                        file:null,
                        type:null,
                        dealiased:null,
                        scan:null
                    }
                ])

                currentFile.set(null);

                radarMetadata.set({});
            }

            scans.update(d => {
                d[i] = undefined;
                return d;
            })

            //remove any and all vertex arrays
            vertexData.update(
                d => {
                    d[i] = undefined;
                    return d;
                }
            )
 
            texture_3d.update(
                d => {
                    d[i] = undefined;
                    return d;
                }
            )

            // events.push({
            //     message:"deleteFile",
            //     data:{
            //         fileNum:fileNumber,
            //         fileName: fileName
            //     }
            //  });
            // console.log(events);

            get(worker).postMessage({
                message:"deleteFile",
                data:{
                    fileNum:fileNumber,
                    fileName: fileName
                }
            })
        }

        //update $sites since $scans has been updated
        //updateSites();

    }

    function reset() {
        clearTimeout(pongId);
    }

    function startTimeout() {
       
            const toSend = {
                id:connectionId,
                action:"ping"
            }
            ws.send(JSON.stringify(toSend));

            pongId = setTimeout(() => {
                console.log("closing in pong");
                ws.close();
            }, 3000)
       
    }

    function connect() {
        if (ws) return;
        return new Promise(function(resolve, reject) {

            ws = new WebSocket(`wss://radar.quadweather.com`);

            ws.onerror = function (err) {
                ws.close();
            };

            ws.onopen = async function () {
               
                if (intervalId) {
                    clearInterval(intervalId);
                    intervalId = null;
                }
                
                ws.isAlive = true;

                //heartbeat functions
                reset();
                intervalId = setInterval(startTimeout, 5000);

                const token = await auth.getToken();
                fetch("https://radar.quadweather.com/socket/alerts", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(
                    d => {
                        return d.json()
                    }
                ).then(
                    d => {
                        alerts.update(x => {
                            x = d;
                            return x;
                        });
                    }
                ).catch(err => console.log("fetch error", err));

                fetch("https://radar.quadweather.com/socket/lightning", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(
                    d => {
                        return d.json()
                    }
                ).then(
                    d => {
                        lightning.update(x => {
                            x = d;
                           
                            return x;
                        });
                    }
                ).catch(err => console.log("fetch error", err));

                resolve(ws);
            };

            ws.onclose = function () {

                reset();
                clearInterval(intervalId);
                intervalId = null;

                ws = null;
                //only attempt reconnect if live
                if (get(MODE_STATE) === LIVE) {
                    setTimeout(function() {
                        connect();
                    }, Math.max(Math.random()*2000, 1000)); //give a second for server to restart
                    
                }
            };

            ws.onmessage = async function(e) {
                //console.log("message", e);
                const data = e.data;
                if (data instanceof Blob) {
                e.data.arrayBuffer()
                .then(d=>console.log(d));
                } else {
                    //was json
                    const parsed = JSON.parse(data);
                    //console.log("parsed", parsed);
                    
                    //run once connection established
                    if (parsed.data) {
                        connectionId = parsed.data;

                        //set site if exists
                        if (site) {

                            //loading new when site changes
                            LOADING_LIVE.update(d=>true);

                            let toSend;
                            if (get(ANIMATING)) {
                                needLoop = true;
                            } //else {
                                toSend = {
                                    id:connectionId,
                                    site:site,
                                    action:"new_site"
                                }

                                ws.send(JSON.stringify(toSend));
                            //}
                        } 
                    }

                    if (parsed.action === "pong") {
                        reset();
                    }

                    if (parsed.action === "no_file") {
                        //stop downloading
                        LOADING_LIVE.update(d=>false);

                        //dialog saying no file available for site
                        error.update(d => {
                            d.error = true;
                            d.message = `Site ${site} does not have any recent radar data available.`;
                            d.header = "No data available";
                            d.color = "normal"
                            return d;
                        })
                    }

                    if (parsed.action === "new_file") {
                        //console.log(e);
                        const token = await auth.getToken();
                        fetch(`https://radar.quadweather.com/socket/data?site=${parsed.site}&file_name=${parsed.fileName}`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        .then(d => d.arrayBuffer())
                        .then( d => {
                            //console.log("new file")

                            //check if looping
                            //this works because file only sent after most recent 5 downloaded on backend
                            if (needLoop) {
                                needLoop = false;
                                loadLoop();
                            }
                          
                            //check to see if still want the file (is selected radar still the same)
                            let files = [];
                            const s = get(scans);
                            const loaded = s.filter(x=>x!=undefined);

                            for (let i=0; i<loaded.length; i++) {
                                files.push(loaded[i].fileName)
                                
                            }

                            if (files.indexOf(parsed.fileName) < 0 && parsed.site === site) {
                                //push file to queue
                                

                                //need to load
                                if (!toProcess.has(parsed.fileName)) {
                                    toLoad++;
                                    toProcess.set(parsed.fileName,
                                        {
                                            "message":"initialize",
                                            fileName: parsed.fileName,
                                            buffer: d
                                        }
                                    )
                                }
                                
                                //console.log("sending parsed", toLoad);

                                if (triggerFunction) triggerFunction(toLoad);
                                
                                // toProcess.push(
                                //     {
                                //         "message":"initialize",
                                //         fileName: parsed.fileName,
                                //         buffer: d
                                //     }
                                // );

                                

                                checkProcess(parsed.fileName);
                            } else {
                                LOADING_LIVE.update(d=>false);
                            }
                        })
                        .catch(error => console.log(error));
                    }

                    if (parsed.action === "new_site") {
                        //console.log(e);
                        //check to see if still want the file (is selected radar still the same)
                        let files = [];
                        const s = get(scans);
                        const loaded = s.filter(x=>x!=undefined);

                        for (let i=0; i<loaded.length; i++) {
                            files.push(loaded[i].fileName)
                            
                        }

                        if (!parsed.fileName) {
                            LOADING_LIVE.update(d=>false);
                            return;
                        }

                        if (files.indexOf(parsed.fileName) >= 0 && parsed.site === site) {
                            LOADING_LIVE.update(d=>false);
                            if (triggerFunction) triggerFunction(0);
                        } else {
                            //download file
                            //fetch(`https://radar.quadweather.com/socket/data?site=${parsed.site}&file_name=${parsed.fileName}`)
                            const token = await auth.getToken();
                            fetch(`https://radar.quadweather.com/socket/data?site=${parsed.site}&file_name=${parsed.fileName}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            })
                            .then(d => d.arrayBuffer())
                            .then( d => {
                                //console.log("new site")
                            
                               

                                if (files.indexOf(parsed.fileName) < 0 && parsed.site === site) {
                                    //push file to queue
                                   

                                    //need to load
                                    if (!toProcess.has(parsed.fileName)) {
                                        toLoad++;
                                        toProcess.set(parsed.fileName,
                                            {
                                                "message":"initialize",
                                                fileName: parsed.fileName,
                                                buffer: d
                                            }
                                        )
                                    }
                                    
                                  

                                    if (triggerFunction) triggerFunction(toLoad);
                                    
                                    // toProcess.push(
                                    //     {
                                    //         "message":"initialize",
                                    //         fileName: parsed.fileName,
                                    //         buffer: d
                                    //     }
                                    // );

                                    
                                    

                                    checkProcess(parsed.fileName);
                                } 
                            })
                            .catch(error => console.log(error));

                        }
                    }

                    if (parsed.action === "loop_response") {
                        //console.log(e);
                        //console.log(parsed)
                        if (parsed.files.length === 0) {
                            LOADING_LIVE.update(d=>false);
                            return;
                        } else {
                            for (let i=0; i<parsed.files.length; i++) {
                                //fetch(`https://radar.quadweather.com/socket/data?site=${parsed.site}&file_name=${parsed.files[i]}`)
                                const token = await auth.getToken();
                                fetch(`https://radar.quadweather.com/socket/data?site=${parsed.site}&file_name=${parsed.files[i]}`, {
                                    headers: {
                                        Authorization: `Bearer ${token}`
                                    }
                                })
                                .then(d => d.arrayBuffer())
                                .then( d => {
                                    //console.log("new loaded file")
                                
                                    //check to see if still want the file (is selected radar still the same)
                                    let files = [];
                                    const s = get(scans);
                                    const loaded = s.filter(x=>x!=undefined);

                                    for (let j=0; j<loaded.length; j++) {
                                        files.push(loaded[j].fileName)
                                    }

                                    if (files.indexOf(parsed.files[i]) < 0 && parsed.site === site) {
                                        //push file to queue

                                        //need to load
                                        if (!toProcess.has(parsed.files[i])) {
                                            toLoad++;
                                            toProcess.set(parsed.files[i],
                                                {
                                                    "message":"initialize",
                                                    fileName: parsed.files[i],
                                                    buffer: d
                                                }
                                            )
                                        }
                                        
                                        //console.log("sending parsed", toLoad);

                                        if (triggerFunction) triggerFunction(toLoad);
                                        
                                        // toProcess.push(
                                        //     {
                                        //         "message":"initialize",
                                        //         fileName: parsed.files[i],
                                        //         buffer: d
                                        //     }
                                        // );

                                        
                                      
                                        checkProcess(parsed.files[i]);
                                    } 
                                })
                                .catch(error => console.log(error));
                            }
                            
                        }
                    }

                    if (parsed.action === "new_alerts") {
                        const token = await auth.getToken();
                        fetch("https://radar.quadweather.com/socket/alerts", {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        //fetch("https://radar.quadweather.com/socket/alerts")
                        .then(
                            d => {
                                return d.json()
                            }
                        ).then(
                            d => {
                                alerts.update(x => {
                                    x = d;
                                    return x;
                                });
                                //return;
                            }
                        ).catch(err => console.log("fetch error", err));
                    }

                    if (parsed.action === "new_lightning") {
                        const token = await auth.getToken();
                        fetch("https://radar.quadweather.com/socket/lightning", {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                        .then(
                            d => {
                                return d.json()
                            }
                        ).then(
                            d => {
                                lightning.update(x => {
                                    x = d;
                                    return x;
                                });
                              
                            }
                        ).catch(err => console.log("fetch error", err));
                    }
                } 
            }
        })
    }

    function loadLoop() {
        if (site) {
            deleteAll();

            let mostRecent;
            let files = [];
            const s = get(scans);
            const loaded = s.filter(x=>x!=undefined);

            for (let i=0; i<loaded.length; i++) {
                files.push(loaded[i].fileName)
            }

            //console.log("files", files);

            const toSend = {
                id:connectionId,
                site:site,
                action:"load_loop",
                fileNames: files
            }

            //console.log("loop", get(scans));
    
            ws.send(JSON.stringify(toSend));
        }
    }

    function setSite(argSite) {
       
        //update site if different
        if (argSite != site) {
            site = argSite;

            //cancel any animation
            cancelFunction();
            needLoop = false;

            //loading new when site changes
            LOADING_LIVE.update(d=>true);
        } else {
            return;
        }
        
        const toSend = {
            id:connectionId,
            site:site,
            action:"new_site"
        }

        ws.send(JSON.stringify(toSend));
    }

    function close() {
        if (ws) {
            ws.close();
            ws = null;
        }
    }

    return {
        get site() {
            return site;
        },
        set site(value) {
            site = value;
        },
        get ws() {
            return ws;
        },
        connect,
        close,
        setSite,
        loadLoop,
        handleQueue,
        setTrigger,
        setCancel
    }

}

export { Socket }