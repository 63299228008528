import './MenuCanvas.svelte.css';
/* src/MenuCanvas.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	add_flush_callback,
	append,
	attr,
	bind,
	binding_callbacks,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	texture_3d,
	box,
	radarMetadata,
	scans,
	selections,
	LOADING_3D,
	INT_STATE,
	line
} from './store.js';

import * as THREE from '../_snowpack/pkg/three.js';
import { OrbitControls } from '../_snowpack/pkg/three/examples/jsm/controls/OrbitControls.js';

import {
	CSS2DRenderer,
	CSS2DObject
} from '../_snowpack/pkg/three/examples/jsm/renderers/CSS2DRenderer.js';

import { onMount } from '../_snowpack/pkg/svelte.js';
import { createTable, createTexture } from './renderHelpers.js';
import { INT_OBJECT } from './enums.js';
import Menu3D from './Menu3D.svelte.js';

function create_if_block_2(ctx) {
	let div;
	let p;
	let t;

	return {
		c() {
			div = element("div");
			p = element("p");
			t = text(/*instruction*/ ctx[4]);
			attr(div, "class", "inc svelte-6pvw7y");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*instruction*/ 16) set_data(t, /*instruction*/ ctx[4]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (1209:4) {#if $INT_STATE === MODE3D}
function create_if_block_1(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");

			button.innerHTML = `<div class="circle svelte-6pvw7y"></div> 
            <div class="circle svelte-6pvw7y"></div> 
            <div class="circle svelte-6pvw7y"></div>`;

			attr(button, "class", "svelte-6pvw7y");
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*show*/ ctx[8]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (1216:4) {#if $LOADING_3D === true}
function create_if_block(ctx) {
	let div8;

	return {
		c() {
			div8 = element("div");

			div8.innerHTML = `<div class="scene svelte-6pvw7y"><div class="cube svelte-6pvw7y"><div class="cube__face cube__face--front svelte-6pvw7y"></div> 
                <div class="cube__face cube__face--back svelte-6pvw7y"></div> 
                <div class="cube__face cube__face--right svelte-6pvw7y"></div> 
                <div class="cube__face cube__face--left svelte-6pvw7y"></div> 
                <div class="cube__face cube__face--top svelte-6pvw7y"></div> 
                <div class="cube__face cube__face--bottom svelte-6pvw7y"></div></div></div>`;

			attr(div8, "class", "loader svelte-6pvw7y");
		},
		m(target, anchor) {
			insert(target, div8, anchor);
		},
		d(detaching) {
			if (detaching) detach(div8);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let canvas_1;
	let t0;
	let div0;
	let t1;
	let t2;
	let t3;
	let t4;
	let menu3d;
	let updating_showTrans;
	let updating_imageData;
	let current;
	let if_block0 = /*$LOADING_3D*/ ctx[6] === false && show_in === true && create_if_block_2(ctx);
	let if_block1 = /*$INT_STATE*/ ctx[1] === /*MODE3D*/ ctx[7] && create_if_block_1(ctx);
	let if_block2 = /*$LOADING_3D*/ ctx[6] === true && create_if_block(ctx);

	function menu3d_showTrans_binding(value) {
		/*menu3d_showTrans_binding*/ ctx[16](value);
	}

	function menu3d_imageData_binding(value) {
		/*menu3d_imageData_binding*/ ctx[17](value);
	}

	let menu3d_props = {};

	if (/*showTrans*/ ctx[5] !== void 0) {
		menu3d_props.showTrans = /*showTrans*/ ctx[5];
	}

	if (/*textureData*/ ctx[0] !== void 0) {
		menu3d_props.imageData = /*textureData*/ ctx[0];
	}

	menu3d = new Menu3D({ props: menu3d_props });
	binding_callbacks.push(() => bind(menu3d, 'showTrans', menu3d_showTrans_binding));
	binding_callbacks.push(() => bind(menu3d, 'imageData', menu3d_imageData_binding));

	return {
		c() {
			div1 = element("div");
			canvas_1 = element("canvas");
			t0 = space();
			div0 = element("div");
			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			if (if_block2) if_block2.c();
			t4 = space();
			create_component(menu3d.$$.fragment);
			attr(canvas_1, "class", "can svelte-6pvw7y");
			attr(canvas_1, "id", "can");
			attr(div0, "class", "v svelte-6pvw7y");
			attr(div1, "class", "wrapper svelte-6pvw7y");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, canvas_1);
			/*canvas_1_binding*/ ctx[15](canvas_1);
			append(div1, t0);
			append(div1, div0);
			append(div1, t1);
			if (if_block0) if_block0.m(div1, null);
			append(div1, t2);
			if (if_block1) if_block1.m(div1, null);
			append(div1, t3);
			if (if_block2) if_block2.m(div1, null);
			append(div1, t4);
			mount_component(menu3d, div1, null);
			/*div1_binding*/ ctx[18](div1);
			current = true;
		},
		p(ctx, dirty) {
			if (/*$LOADING_3D*/ ctx[6] === false && show_in === true) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div1, t2);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*$INT_STATE*/ ctx[1] === /*MODE3D*/ ctx[7]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div1, t3);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*$LOADING_3D*/ ctx[6] === true) {
				if (if_block2) {
					
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(div1, t4);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			const menu3d_changes = {};

			if (!updating_showTrans && dirty[0] & /*showTrans*/ 32) {
				updating_showTrans = true;
				menu3d_changes.showTrans = /*showTrans*/ ctx[5];
				add_flush_callback(() => updating_showTrans = false);
			}

			if (!updating_imageData && dirty[0] & /*textureData*/ 1) {
				updating_imageData = true;
				menu3d_changes.imageData = /*textureData*/ ctx[0];
				add_flush_callback(() => updating_imageData = false);
			}

			menu3d.$set(menu3d_changes);
		},
		i(local) {
			if (current) return;
			transition_in(menu3d.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(menu3d.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			/*canvas_1_binding*/ ctx[15](null);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			destroy_component(menu3d);
			/*div1_binding*/ ctx[18](null);
		}
	};
}

const EPSILON = 0.00001;

//instruction text bool
let show_in = true;

function mercatorToAzimuthal(lambda0, phi0, lon, lat) {
	let phi = lat * (Math.PI / 180);
	let lambda = lon * (Math.PI / 180);
	let centerLat = phi0 * (Math.PI / 180);
	let centerLon = lambda0 * (Math.PI / 180);
	let rhsf = Math.fround(Math.sin(Math.fround(centerLat)) * Math.sin(Math.fround(phi))) + Math.fround(Math.cos(Math.fround(centerLat)) * Math.cos(Math.fround(phi)) * Math.cos(Math.fround(lambda - centerLon)));
	let rhs = Math.sin(centerLat) * Math.sin(phi) + Math.cos(centerLat) * Math.cos(phi) * Math.cos(lambda - centerLon);
	let c = Math.acos(rhs);
	let cf = Math.fround(Math.acos(rhsf));
	let kprime = c / Math.sin(c);
	let xout = kprime * Math.cos(phi) * Math.sin(lambda - centerLon) * 6371000;
	let yout = kprime * (Math.cos(centerLat) * Math.sin(phi) - Math.sin(centerLat) * Math.cos(phi) * Math.cos(lambda - centerLon)) * 6371000;
	return [xout, yout];
}

function instance($$self, $$props, $$invalidate) {
	let $INT_STATE;
	let $texture_3d;
	let $line;
	let $box;
	let $LOADING_3D;
	let $selections;
	let $scans;
	let $radarMetadata;
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(1, $INT_STATE = $$value));
	component_subscribe($$self, texture_3d, $$value => $$invalidate(11, $texture_3d = $$value));
	component_subscribe($$self, line, $$value => $$invalidate(12, $line = $$value));
	component_subscribe($$self, box, $$value => $$invalidate(13, $box = $$value));
	component_subscribe($$self, LOADING_3D, $$value => $$invalidate(6, $LOADING_3D = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(35, $selections = $$value));
	component_subscribe($$self, scans, $$value => $$invalidate(36, $scans = $$value));
	component_subscribe($$self, radarMetadata, $$value => $$invalidate(14, $radarMetadata = $$value));
	const { MODE3D, CROSS } = INT_OBJECT;
	let canvas, labelDiv;
	let scene;
	let mesh;
	let meshPlane;
	let labelRenderer;
	let texture; // = new THREE.Data3DTexture();
	let tableTexture, bracketTexture;
	let instruction = '';
	let labels = [];
	let tfTexture;
	let tfTextureCross;
	let textureData;
	let showTrans = false;
	let positions = new Float32Array(108);
	let positionsPlane = new Float32Array(18);
	const empty = new Uint8Array([255, 255, 255, 255]);
	const texture3dEmpty = new THREE.Data3DTexture(new Uint8Array([255]), 1, 1, 1);
	texture3dEmpty.format = THREE.RedFormat;
	texture3dEmpty.minFilter = THREE.NearestFilter;
	texture3dEmpty.magFilter = THREE.NearestFilter;
	texture3dEmpty.unpackAlignment = 1;
	texture3dEmpty.needsUpdate = true;
	const texture2dEmpty = new THREE.DataTexture(empty, 1, 1, THREE.RGBAFormat);
	texture2dEmpty.minFilter = THREE.NearestFilter;
	texture2dEmpty.magFilter = THREE.NearestFilter;
	texture2dEmpty.unpackAlignment = 1;
	texture2dEmpty.needsUpdate = true;

	const vertexShaderPlane = /* glsl */
	`
        in vec3 position;
        uniform mat4 modelMatrix;
		uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform mat4 viewMatrix;

        uniform vec3 u_eye_pos;
        out vec3 outPosition;

        void main() {
            vec3 dif = vec3(position.xyz) - u_eye_pos;

            vec4 outpos = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
            outPosition = position.xyz;
            gl_Position = outpos;
        }
    `;

	const fragmentShaderPlane = /* glsl */
	`

        precision highp float;
        precision highp sampler3D;

        uniform vec3 u_eye_pos;
        uniform sampler3D volume;
        uniform highp sampler2D table;
        uniform highp sampler2D tf;
        uniform highp sampler2D bracket;
        uniform vec3 u_plane_center;
        uniform vec3 u_plane_normal;

        //values held in texture
        uniform vec2 u_table_domain;
        //for decoding
        uniform float u_table_max;

        uniform float u_scale;
        uniform float u_offset;
        uniform float u_bin1;
        uniform float u_ngates;
        uniform float u_binMax;
        uniform float u_num_elevations;

        in vec3 outPosition;
        out vec4 color;

        float intersect_plane(vec3 dir) {
            float denominator = dot(u_plane_normal, dir);
            if (abs(denominator) > 0.0001) {
                vec3 difference = u_plane_center - u_eye_pos;
                float t = dot(difference, u_plane_normal)/denominator;

                if (t > 0.000000001) {
                    return t;
                }
            }

            return -1.0;
        }

        float lint(vec4 inputs, float c) {
            
            float y0 = inputs.y;
            float y1 = inputs.w;
            float x0 = inputs.x;
            float x1 = inputs.z;
            if (x1 < x0) {
                return 0.0;
            }
            float x = c;
            float y = y0*(1.0-((x-x0)/(x1-x0)))
            + y1*((x-x0)/(x1-x0));

            return y;
        }

        void main() {
            float b = 0.0;
            float newBelow = 0.0;
            float belowVal = 0.0;
            vec4 below = vec4(0,0,0,0);
            vec4 above = vec4(0,0,0,0);
            float elevation = 0.0;
            float d = 0.0;

        

            vec3 p = outPosition;

            
                //spherical radius
               
                vec3 pcopy = p;
                pcopy.y = pcopy.y/3.0;
                float r = sqrt(p.x*p.x + pcopy.y*pcopy.y + p.z*p.z);

                b = mod(degrees(atan(p.z, p.x)) + 90.0, 360.0);

                elevation = degrees(atan(pcopy.y/sqrt(p.x*p.x + p.z*p.z)));
                d = sqrt(r*r - pcopy.y*pcopy.y);

                //advance if above or below highest or lowest elevation
                if (elevation > u_table_domain.y || elevation < u_table_domain.x) {
                    color = vec4(0,0,0,0);
                    return;
                }

                //get idxs of two closest 
                vec4 two = texture(bracket, vec2((elevation - u_table_domain.x)/(u_table_domain.y-u_table_domain.x), 0.0));

                //need number of levels as uniform
                float aboveZ = (two.y*255.0)/u_num_elevations;
                float belowZ = (two.x*255.0)/u_num_elevations;

                above = texture(table, vec2(aboveZ, 0.0));
                below = texture(table, vec2(belowZ, 0.0));

                //elevations above and below in degrees
                float aboveVal = (above.x+above.y*(1.0/255.0))
                        *(u_table_max - u_table_domain.x) + u_table_domain.x;

                belowVal = (below.x+below.y*(1.0/255.0))
                        *(u_table_max - u_table_domain.x) + u_table_domain.x;

                if (elevation < belowVal) {
                    belowZ = (two.x*255.0 - 1.0)/u_num_elevations;
                    aboveZ = (two.y*255.0 - 1.0)/u_num_elevations;
                    above = texture(table, vec2(aboveZ, 0.0));
                    below = texture(table, vec2(belowZ, 0.0));
                    //elevations above and below in degrees
                    aboveVal = (above.x+above.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                    belowVal = (below.x+below.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                } else if (elevation > aboveVal) {
                    belowZ = (two.x*255.0 + 1.0)/u_num_elevations;
                    aboveZ = (two.y*255.0 + 1.0)/u_num_elevations;
                    above = texture(table, vec2(aboveZ, 0.0));
                    below = texture(table, vec2(belowZ, 0.0));
                    //elevations above and below in degrees
                    aboveVal = (above.x+above.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                    belowVal = (below.x+below.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                }

                //get whether elevation closer to beam above or below
                float average = (aboveVal + belowVal)/2.0;
                float closestStep = step(average, elevation);

                float newAbove = d/cos(radians(aboveVal));
                newBelow = d/cos(radians(belowVal));

                if (newAbove < u_bin1 || newBelow < u_bin1 || newAbove > u_binMax || newBelow > u_binMax) {
                    color = vec4(0,0,0,0);
                    return;
                } else {
                    newAbove = newAbove - u_bin1;
                    newBelow = newBelow - u_bin1;
                }
                float newVal;
                //was closer to below
                if (closestStep == 0.0) {
                    newVal = texture(volume, vec3(newBelow/u_ngates, b/360.0, belowZ)).r;
                } else { //was closer to above
                    newVal = texture(volume, vec3(newAbove/u_ngates, b/360.0, aboveZ)).r;
                }

                //Needs to be divided by range_max in km
                float valAbove = texture(volume, vec3(newAbove/u_ngates, b/360.0, aboveZ)).r;
                float valBelow = texture(volume, vec3(newBelow/u_ngates, b/360.0, belowZ)).r;

                vec4 forLint = vec4(belowVal, valBelow, aboveVal, valAbove);
                newVal = lint(forLint, elevation);

                float dbzVal = (newVal*255.0 - u_offset)/u_scale;

                if (dbzVal < 10.0) {
                    color = vec4(0,0,0,0);
                    return;
                }

                vec4 val_color = vec4(texture(tf, vec2(max(dbzVal,0.0)/80.0, 0.0)).rgb, 1.0);

                color = val_color;
            

            //color = vec4(t_hit/100.0, 0.0, 0.0, 1.0);
        }
    `;

	const vertexShader = /* glsl */
	`
        in vec3 position;
        uniform mat4 modelMatrix;
		uniform mat4 modelViewMatrix;
        uniform mat4 projectionMatrix;
        uniform mat4 viewMatrix;
        //uniform mat4 modelMatrix;
        
        //uniform vec3 cameraPos;
        uniform vec3 u_eye_pos;
        uniform vec2 u_radar_pos;
        uniform float u_height_scale;

        //out vec3 vOrigin;
        //out vec3 vDirection;
        out vec3 vray_dir;

        mat4 scaling;

        void main() {


            vec3 pcopy = vec3(position.x, position.y, position.z);
            vec3 dif = pcopy - u_eye_pos;
            vray_dir = dif;
           
            vec4 outpos = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);

            gl_Position = outpos;
        
        }
    `;

	const fragmentShader = /* glsl */
	`
        precision highp float;
        precision highp sampler3D;

        in vec3 vray_dir;
        out vec4 color;

        uniform vec3 u_eye_pos;
        uniform vec2 u_x_domain;
        uniform vec2 u_y_domain;
        uniform vec2 u_z_domain;
        uniform sampler3D volume;
        uniform highp sampler2D table;
        uniform highp sampler2D tf;
        uniform highp sampler2D bracket;

        //values held in texture
        uniform vec2 u_table_domain;
        //for decoding
        uniform float u_table_max;

        uniform float u_scale;
        uniform float u_offset;
        uniform float u_bin1;
        uniform float u_ngates;
        uniform float u_binMax;
        uniform float u_num_elevations;

        vec2 intersect_box(vec3 dir) {
            vec3 inv_dir = 1.0 / dir;

            vec3 box_min = vec3(u_x_domain.x, u_y_domain.x, u_z_domain.x);
            vec3 box_max = vec3(u_x_domain.y, u_y_domain.y, u_z_domain.y);
            
            vec3 tmin_tmp = (box_min - u_eye_pos) * inv_dir;
            vec3 tmax_tmp = (box_max - u_eye_pos) * inv_dir;
            vec3 tmin = min(tmin_tmp, tmax_tmp);
            vec3 tmax = max(tmin_tmp, tmax_tmp);
            float t0 = max(tmin.x, max(tmin.y, tmin.z));
            float t1 = min(tmax.x, min(tmax.y, tmax.z));
            return vec2(t0, t1);
        }


        float lint(vec4 inputs, float c) {
            
            float y0 = inputs.y;
            float y1 = inputs.w;
            float x0 = inputs.x;
            float x1 = inputs.z;
            if (x1 < x0) {
                return 0.0;
            }
            float x = c;
            float y = y0*(1.0-((x-x0)/(x1-x0)))
            + y1*((x-x0)/(x1-x0));

            return y;
        }

        // Pseudo-random number gen from
        // http://www.reedbeta.com/blog/quick-and-easy-gpu-random-numbers-in-d3d11/
        // with some tweaks for the range of values
        float wang_hash(int seed) {
            seed = (seed ^ 61) ^ (seed >> 16);
            seed *= 9;
            seed = seed ^ (seed >> 4);
            seed *= 0x27d4eb2d;
            seed = seed ^ (seed >> 15);
            return float(seed % 2147483647) / float(2147483647);
        }
        

        void main(){
            vec3 ray_dir = normalize(vray_dir);

            vec2 t_hit = intersect_box(ray_dir);
            
            if (t_hit.x > t_hit.y) {
                color = vec4(0,0,0,0);
                return;
            }

            t_hit.x = max(t_hit.x, 0.0);
            
            float b = 0.0;
            float newBelow = 0.0;
            float belowVal = 0.0;
            vec4 below = vec4(0,0,0,0);
            vec4 above = vec4(0,0,0,0);
            float elevation = 0.0;
            
            float d = 0.0;
            float outt = 0.0;
            int broke = 0;
            
            float dt = 0.25;

            //vec3 p = u_eye_pos + (t_hit.x * ray_dir);
            

            //float offset = wang_hash(int(gl_FragCoord.x + 400.0 * gl_FragCoord.y));

            vec3 p = u_eye_pos + (t_hit.x) * ray_dir;

            for (float t = t_hit.x; t < t_hit.y; t += dt) {
                //spherical radius
               
                vec3 pcopy = p;
                pcopy.y = pcopy.y/3.0;
               
                float r = sqrt(p.x*p.x + pcopy.y*pcopy.y + p.z*p.z);

                b = mod(degrees(atan(p.z, p.x)) + 90.0, 360.0);

                elevation = degrees(atan(pcopy.y/sqrt(p.x*p.x + p.z*p.z)));
                d = sqrt(r*r - pcopy.y*pcopy.y);

                //advance if above or below highest or lowest elevation
                if (elevation > u_table_domain.y || elevation < u_table_domain.x) {
                   p += ray_dir * dt;
                   continue;
                }

                //get idxs of two closest 
                vec4 two = texture(bracket, vec2((elevation - u_table_domain.x)/(u_table_domain.y - u_table_domain.x), 0.0));

                //need number of levels as uniform
                float aboveZ = (two.y*255.0)/u_num_elevations;
                float belowZ = (two.x*255.0)/u_num_elevations;

                above = texture(table, vec2(aboveZ, 0.0));
                below = texture(table, vec2(belowZ, 0.0));

                //elevations above and below in degrees
                float aboveVal = (above.x+above.y*(1.0/255.0))
                        *(u_table_max - u_table_domain.x) + u_table_domain.x;

                belowVal = (below.x+below.y*(1.0/255.0))
                        *(u_table_max - u_table_domain.x) + u_table_domain.x;


                if (elevation < belowVal) {
                    belowZ = (two.x*255.0 - 1.0)/u_num_elevations;
                    aboveZ = (two.y*255.0 - 1.0)/u_num_elevations;
                    above = texture(table, vec2(aboveZ, 0.0));
                    below = texture(table, vec2(belowZ, 0.0));
                    //elevations above and below in degrees
                    aboveVal = (above.x+above.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                    belowVal = (below.x+below.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                } else if (elevation > aboveVal) {
                    belowZ = (two.x*255.0 + 1.0)/u_num_elevations;
                    aboveZ = (two.y*255.0 + 1.0)/u_num_elevations;
                    above = texture(table, vec2(aboveZ, 0.0));
                    below = texture(table, vec2(belowZ, 0.0));
                    //elevations above and below in degrees
                    aboveVal = (above.x+above.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                    belowVal = (below.x+below.y*(1.0/255.0))
                            *(u_table_max - u_table_domain.x) + u_table_domain.x;
                }

                float newAbove = d/cos(radians(aboveVal));
                newBelow = d/cos(radians(belowVal));

                //if (newAbove < u_bin1 || newBelow < u_bin1) {
                if (newAbove < u_bin1 || newBelow < u_bin1 || newAbove > u_binMax || newBelow > u_binMax) {
                    p += ray_dir * dt;
                    continue;
                } else {
                    newAbove = newAbove - u_bin1;
                    newBelow = newBelow - u_bin1;
                }

                //Needs to be divided by range_max in km
                float valAbove = texture(volume, vec3(newAbove/u_ngates, b/360.0, aboveZ)).r;
                float valBelow = texture(volume, vec3(newBelow/u_ngates, b/360.0, belowZ)).r;

                vec4 forLint = vec4(belowVal, valBelow, aboveVal, valAbove);
                float newVal = lint(forLint, elevation);

                float dbzVal = (newVal*255.0 - u_offset)/u_scale;

                vec4 val_color = vec4(texture(tf, vec2(max(dbzVal,0.0)/80.0, 0.0)).rgba);
                float alpha = 1.0-pow((1.0 - val_color.a),0.3);
                //float alpha = 1.0 - (1.0 - val_color.a);
                color.rgb += (1.0 - color.a) * alpha * val_color.rgb;
                color.a += (1.0 - color.a) * alpha;

                if (color.a >= 0.95) {
                    break;
                }
                p += ray_dir * dt;  
            } 
        }
    `;

	function setInstruction() {
		if ($INT_STATE === MODE3D) {
			renderer.clear();
			scene.remove(meshPlane);
			labels.forEach(x => scene.remove(x));
			scene.add(mesh);
			$$invalidate(4, instruction = 'Drag bounding box on map.');
		}

		if ($INT_STATE === CROSS) {
			$$invalidate(5, showTrans = false);
			renderer.clear();
			scene.remove(mesh);
			scene.add(meshPlane);
			$$invalidate(4, instruction = 'Drag line on map.');
		}
	}

	function removeMeshes(state = $INT_STATE) {
		if (scene && renderer) {
			renderer.clear();

			if (state === MODE3D) {
				scene.remove(mesh);

				//$box = null;
				for (let i = 0; i < positions.length; i++) {
					mesh.geometry.attributes.position.needsUpdate = true;
					mesh.geometry.attributes.position.array[i] = 0;
				}
			} else {
				scene.remove(meshPlane);

				//$line = null;
				for (let i = 0; i < positionsPlane.length; i++) {
					meshPlane.geometry.attributes.position.needsUpdate = true;
					meshPlane.geometry.attributes.position.array[i] = 0;
				}
			}

			if (state === CROSS) {
				labels.forEach(x => scene.remove(x));
			}
		}
	}

	function setPlane() {
		if ($line === null) return;
		if (!$radarMetadata.lat || !$radarMetadata.lon) return;

		//add mesh if it needs it
		if (!scene.getObjectByName('meshPlane')) {
			scene.add(meshPlane);
		}

		const pointA = $line[0];
		const pointB = $line[1];
		const pointA_aeq = mercatorToAzimuthal($radarMetadata.lon, $radarMetadata.lat, pointA.lng, pointA.lat);
		const pointB_aeq = mercatorToAzimuthal($radarMetadata.lon, $radarMetadata.lat, pointB.lng, pointB.lat);
		const maxx = pointB_aeq[0] / 1000;
		const minx = pointA_aeq[0] / 1000;
		const miny = -pointA_aeq[1] / 1000;
		const maxy = -pointB_aeq[1] / 1000;

		//60000 meters, but scaled 3x so really 20000
		const maxz = 60000.0 / 1000;

		const minz = 0.0;

		// const startingPoint = [minx, miny];
		// const endingPoint = [maxx, maxy];
		const vector = [maxx - minx, maxy - miny];

		const center = [(maxx + minx) / 2.0, (maxy + miny) / 2.0, maxz / 3.0];
		let orthogonal;

		if (Math.abs(vector[0]) < EPSILON) {
			orthogonal = [1, 0, 0];
		} else if (Math.abs(vector[1]) < EPSILON) {
			orthogonal = [0, 1, 0];
		} else {
			const v = [1, -vector[0] / vector[1]];
			const mag = Math.sqrt(v[0] * v[0] + v[1] * v[1]);
			orthogonal = [v[0] / mag, v[1] / mag, 0];
		}

		//test if orthogonal vector to the right or left 
		const dot = vector[0] * -orthogonal[1] + vector[1] * orthogonal[0];

		//need to flip if dot > 0
		if (dot > 0) {
			orthogonal = orthogonal.map(x => -x);
		}

		//set target to center of plane.
		//set camera position x units from center.
		//need to determine approprate x
		const vertices = new Float32Array([
				//plane
				minx,
				minz,
				miny,
				maxx,
				minz,
				maxy,
				minx,
				maxz,
				miny,
				minx,
				maxz,
				miny,
				maxx,
				minz,
				maxy,
				maxx,
				maxz,
				maxy
			]);

		meshPlane.geometry.attributes.position.needsUpdate = true;

		for (let i = 0; i < vertices.length; i++) {
			meshPlane.geometry.attributes.position.array[i] = vertices[i];
		}

		//calculate appropriate camera distance from plane
		const xcomp = maxx - minx;

		const ycomp = maxy - miny;
		const planeWidth = Math.sqrt(xcomp * xcomp + ycomp * ycomp);
		const planeHeight = maxz;
		let t;

		if (controls && camera) {
			//fit horizontal 
			if (planeWidth > planeHeight) {
				const hfov = Math.atan(Math.tan(camera.fov * Math.PI / 180 / 2) * camera.aspect);
				t = planeWidth / 2 / Math.tan(hfov);
			} else {
				//fit vertical
				t = planeHeight / 2 / Math.tan(camera.fov * Math.PI / 180 / 2);
			}

			t *= 1.1;
			camera.position.set(center[0] + orthogonal[0] * t, center[2] + orthogonal[2] * t, center[1] + orthogonal[1] * t);
			controls.target = new THREE.Vector3(center[0], center[2], center[1]);
			meshPlane.material.uniforms.u_plane_normal.value = new THREE.Vector3(orthogonal[0], orthogonal[2], orthogonal[1]);
			meshPlane.material.uniforms.u_plane_center.value = new THREE.Vector3(center[0], center[2], center[1]);
			controls.update();
			labels[0].position.copy(new THREE.Vector3(minx, minz, miny));
			labels[1].position.copy(new THREE.Vector3(maxx, minz, maxy));

			if (!scene.getObjectByName('A')) {
				labels.forEach(x => scene.add(x));
			}
		}

		//console.log("render setplane");
		render();
	}

	function setBox() {
		//console.log("setting box", $box);
		if ($box === null) return;

		if (!$radarMetadata.lat || !$radarMetadata.lon) return;

		//add mesh if it needs it
		if (!scene.getObjectByName('mesh')) {
			scene.add(mesh);
		}

		const nw = $box[0];
		const se = $box[1];
		const nw_aeq = mercatorToAzimuthal($radarMetadata.lon, $radarMetadata.lat, nw.lng, nw.lat);
		const se_aeq = mercatorToAzimuthal($radarMetadata.lon, $radarMetadata.lat, se.lng, se.lat);
		const maxx = se_aeq[0] / 1000;
		const minx = nw_aeq[0] / 1000;
		const miny = -nw_aeq[1] / 1000;
		const maxy = -se_aeq[1] / 1000;
		const maxz = 60000.0 / 1000;
		const minz = 0.0;
		const yAvg = (miny + maxy) / 2.0;
		const xAvg = (minx + maxx) / 2.0;
		mesh.material.uniforms.u_x_domain.value = new THREE.Vector2(minx, maxx);
		mesh.material.uniforms.u_y_domain.value = new THREE.Vector2(minz, maxz);
		mesh.material.uniforms.u_z_domain.value = new THREE.Vector2(miny, maxy);

		// create a simple square shape. We duplicate the top left and bottom right
		// vertices because each vertex needs to appear once per triangle.
		const vertices2 = new Float32Array([
				//front
				minx,
				minz,
				maxy,
				maxx,
				minz,
				maxy,
				minx,
				maxz,
				maxy,
				minx,
				maxz,
				maxy,
				maxx,
				minz,
				maxy,
				maxx,
				maxz,
				maxy,
				//right
				maxx,
				minz,
				maxy,
				maxx,
				minz,
				miny,
				maxx,
				maxz,
				maxy,
				maxx,
				maxz,
				maxy,
				maxx,
				minz,
				miny,
				maxx,
				maxz,
				miny,
				//back
				maxx,
				minz,
				miny,
				minx,
				minz,
				miny,
				maxx,
				maxz,
				miny,
				maxx,
				maxz,
				miny,
				minx,
				minz,
				miny,
				minx,
				maxz,
				miny,
				//left
				minx,
				minz,
				miny,
				minx,
				minz,
				maxy,
				minx,
				maxz,
				miny,
				minx,
				maxz,
				miny,
				minx,
				minz,
				maxy,
				minx,
				maxz,
				maxy,
				//top
				maxx,
				maxz,
				miny,
				minx,
				maxz,
				miny,
				maxx,
				maxz,
				maxy,
				maxx,
				maxz,
				maxy,
				minx,
				maxz,
				miny,
				minx,
				maxz,
				maxy,
				//bottom
				maxx,
				minz,
				maxy,
				minx,
				minz,
				maxy,
				maxx,
				minz,
				miny,
				maxx,
				minz,
				miny,
				minx,
				minz,
				maxy,
				minx,
				minz,
				miny
			]);

		const vertices3 = new Float32Array([
				minx,
				minz,
				maxy,
				maxx,
				minz,
				maxy,
				maxx,
				maxz,
				maxy,
				minx,
				maxz,
				maxy,
				minx,
				minz,
				miny,
				minx,
				maxz,
				miny,
				maxx,
				maxz,
				miny,
				maxx,
				minz,
				miny,
				minx,
				maxz,
				miny,
				minx,
				maxz,
				maxy,
				maxx,
				maxz,
				maxy,
				maxx,
				maxz,
				miny,
				minx,
				minz,
				miny,
				maxx,
				minz,
				miny,
				maxx,
				minz,
				maxy,
				minx,
				minz,
				maxy,
				maxx,
				minz,
				miny,
				maxx,
				maxz,
				miny,
				maxx,
				maxz,
				maxy,
				maxx,
				minz,
				maxy,
				minx,
				minz,
				miny,
				minx,
				minz,
				maxy,
				minx,
				maxz,
				maxy,
				minx,
				maxz,
				miny
			]);

		const indices = [
			0,
			1,
			2,
			0,
			2,
			3,
			4,
			5,
			6,
			4,
			6,
			7,
			8,
			9,
			10,
			8,
			10,
			11,
			12,
			13,
			14,
			12,
			14,
			15,
			16,
			17,
			18,
			16,
			18,
			19,
			20,
			21,
			22,
			20,
			22,
			23
		]; // front
		// back
		// top
		// bottom
		// right
		// left

		mesh.geometry.attributes.position.needsUpdate = true;

		for (let i = 0; i < vertices2.length; i++) {
			mesh.geometry.attributes.position.array[i] = vertices2[i];
		}

		//mesh.geometry.setIndex(indices);
		if (controls && camera) {
			//controls.dispose();
			//const element = document.getElementById("can");
			//camera = new THREE.PerspectiveCamera(75, element.clientWidth/element.clientHeight, .1, 1000);
			const offsetx = Math.abs(minx - maxx);

			const offsety = Math.abs(miny - maxy);
			const offset = Math.max(offsetx, offsety) / 1.5;
			camera.position.set(xAvg + offset, 70, yAvg + offset);

			//controls = new OrbitControls(camera, renderer.domElement);
			//controls.target = new THREE.Vector3(0,0,0);
			//cube.position.set(xAvg, 0, yAvg);
			controls.target = new THREE.Vector3(xAvg, 10, yAvg);

			controls.update();
		} //controls.saveState();
		//controls.addEventListener( 'change', render ); 

		//console.log(controls);
		//console.log("render setbox");
		render();
	} //show_in = false;
	// if (mesh) {

	function loadTexture() {
		let values, sizes, scale, offset, bin1, binMax, ngates;
		const t = $texture_3d[$selections[0].file];

		//console.log("t", t);
		if (t && t.float && t.size) {
			values = t.float;
			sizes = t.size;
			scale = t.scale;
			offset = t.offset;
			bin1 = t.bin1;
			binMax = t.binMax;
			ngates = t.ngates;
		} else {
			return; //console.log("texture3d", t);
		}

		let min = 999;
		let max = -999;

		//let num20 = 0;
		//console.log("offset, scale", offset, scale)
		for (let i = 0; i < values.length; i++) {
			if (values[i] > max) max = values[i];
			if (values[i] < min) min = values[i];
		} //if (((values[i]-offset)/scale)> 20) num20+=1;

		if (texture) texture.dispose();
		texture = new THREE.Data3DTexture(values, sizes[0], sizes[1], sizes[2]);
		texture.format = THREE.RedFormat;
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;

		//texture.minFilter = THREE.NearestFilter;
		//texture.magFilter = THREE.NearestFilter;
		texture.unpackAlignment = 1;

		texture.needsUpdate = true;
		mesh.material.uniforms.volume.value = texture;
		meshPlane.material.uniforms.volume.value = texture;

		//TODO: maintain state of which scan currently viewing
		const table = createTable($scans[$selections[0].file].scans);

		//console.log("table", table);
		const nearest = table.output;

		const domain = table.domain;
		const elevations = table.elevations;
		const bracket = table.bracket;

		// gl.uniform2fv(locations["u_table_domain"], domain);
		const range = table.range;

		// gl.uniform1f(locations["u_table_max"], range[1]);
		mesh.material.uniforms.u_table_domain.value = domain;

		mesh.material.uniforms.u_table_max.value = range[1];
		mesh.material.uniforms.u_scale.value = scale;
		mesh.material.uniforms.u_offset.value = offset;
		mesh.material.uniforms.u_bin1.value = bin1 / 1000.;
		mesh.material.uniforms.u_ngates.value = ngates;
		mesh.material.uniforms.u_binMax.value = binMax / 1000.;
		mesh.material.uniforms.u_num_elevations.value = sizes[2] - 1.0;
		meshPlane.material.uniforms.u_table_domain.value = domain;
		meshPlane.material.uniforms.u_table_max.value = range[1];
		meshPlane.material.uniforms.u_scale.value = scale;
		meshPlane.material.uniforms.u_offset.value = offset;
		meshPlane.material.uniforms.u_bin1.value = bin1 / 1000.;
		meshPlane.material.uniforms.u_ngates.value = ngates;
		meshPlane.material.uniforms.u_binMax.value = binMax / 1000.;
		meshPlane.material.uniforms.u_num_elevations.value = sizes[2] - 1.0;
		const textureDataCross = createTexture(0);
		const imageDataElevations = new Uint8Array(elevations.length * 4);
		const imageDataIdxs = new Uint8Array(nearest.length * 4);

		for (let i = 0; i < elevations.length; i++) {
			const scaled = (elevations[i] - range[0]) / (range[1] - range[0]);
			const r = scaled * 255;
			const g = Math.floor(scaled * 255) / 255;
			const fracr = r % 1;
			const y = Math.floor(fracr * 255);
			const x = g * 255;
			imageDataElevations[i * 4] = x;
			imageDataElevations[i * 4 + 1] = y;
		}

		for (let i = 0; i < bracket.length; i++) {
			//let scaled = (elevations[nearest[i]] - range[0])/(range[1] - range[0]);
			imageDataIdxs[i * 4] = bracket[i][0];

			imageDataIdxs[i * 4 + 1] = bracket[i][1];
		}

		if (tableTexture) tableTexture.dispose();
		tableTexture = new THREE.DataTexture(imageDataElevations, elevations.length, 1, THREE.RGBAFormat);

		//console.log("initial setting", textureData);
		if (tfTextureCross) tfTextureCross.dispose();

		if (tfTexture) tfTexture.dispose();
		tfTexture = new THREE.DataTexture(textureData.data, textureData.data.length / 4, 1, THREE.RGBAFormat);
		tfTextureCross = new THREE.DataTexture(textureDataCross.data, textureDataCross.data.length / 4, 1, THREE.RGBAFormat);

		//tfTexture.needsUpdate = true;
		if (bracketTexture) bracketTexture.dispose();

		bracketTexture = new THREE.DataTexture(imageDataIdxs, nearest.length, 1, THREE.RGBAFormat);
		tableTexture.minFilter = THREE.NearestFilter;
		tableTexture.magFilter = THREE.NearestFilter;
		tableTexture.unpackAlignment = 1;
		tableTexture.needsUpdate = true;
		tfTexture.minFilter = THREE.NearestFilter;
		tfTexture.magFilter = THREE.NearestFilter;
		tfTexture.unpackAlignment = 1;
		tfTexture.needsUpdate = true;
		tfTextureCross.minFilter = THREE.NearestFilter;
		tfTextureCross.magFilter = THREE.NearestFilter;
		tfTextureCross.unpackAlignment = 1;
		tfTextureCross.needsUpdate = true;
		bracketTexture.minFilter = THREE.NearestFilter;
		bracketTexture.magFilter = THREE.NearestFilter;
		bracketTexture.unpackAlignment = 1;
		bracketTexture.needsUpdate = true;
		mesh.material.uniforms.table.value = tableTexture;
		mesh.material.uniforms.tf.value = tfTexture;
		mesh.material.uniforms.bracket.value = bracketTexture;
		meshPlane.material.uniforms.table.value = tableTexture;
		meshPlane.material.uniforms.tf.value = tfTextureCross;
		meshPlane.material.uniforms.bracket.value = bracketTexture;
		set_store_value(LOADING_3D, $LOADING_3D = false, $LOADING_3D);

		if ($INT_STATE === MODE3D || $INT_STATE === CROSS) {
			render();
		}
	}

	let renderer, camera, controls, cube;

	function render() {
		//console.log("inside render");
		//controls.update();
		//console.log(camera.position);
		if (mesh && $INT_STATE === MODE3D) {
			//console.log("3d mode");
			//console.log(camera.position);
			mesh.material.uniforms.u_eye_pos.value.copy(camera.position);
		} else if (meshPlane && $INT_STATE === CROSS) {
			meshPlane.material.uniforms.u_eye_pos.value.copy(camera.position);
		}

		renderer.render(scene, camera);
		labelRenderer.render(scene, camera);
	} //console.log(renderer.info.memory.textures);

	function onResize() {
		if (!canvas) return;
		camera.aspect = canvas.clientWidth / canvas.clientHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(canvas.clientWidth, canvas.clientHeight);
		labelRenderer.setSize(canvas.clientWidth, canvas.clientHeight);
		render();
	}

	;

	onMount(() => {
		const observer = new IntersectionObserver(onResize);
		observer.observe(canvas);
		renderer = new THREE.WebGLRenderer({ canvas });
		const element = document.getElementById("can");
		renderer.setSize(element.clientWidth, element.clientHeight);
		camera = new THREE.PerspectiveCamera(75, element.clientWidth / element.clientHeight, .001, 1000);
		camera.position.set(100, 0, 100);

		//section for cross section labels
		labelRenderer = new CSS2DRenderer();

		labelRenderer.setSize(element.clientWidth, element.clientHeight);
		labelRenderer.domElement.style.position = 'absolute';
		labelRenderer.domElement.style.top = '0px';
		labelRenderer.domElement.style.pointerEvents = 'none';
		labelDiv.appendChild(labelRenderer.domElement);
		const textA = document.createElement('div');
		textA.className = 'crosslabel';
		textA.textContent = "A";
		const textB = document.createElement('div');
		textB.className = 'crosslabel';
		textB.textContent = "B";
		labels.push(new CSS2DObject(textA));
		labels.push(new CSS2DObject(textB));
		labels[0].name = 'A';
		labels[1].name = 'B';

		//end cross section labels
		scene = new THREE.Scene();

		scene.background = new THREE.Color(0x707070);
		controls = new OrbitControls(camera, renderer.domElement);
		controls.target = new THREE.Vector3(0, 0, 0);
		controls.enableZoom = true;
		const geometry = new THREE.BoxGeometry(10, 10, 10);
		const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: false });
		cube = new THREE.Mesh(geometry, material);

		//scene.add(cube)
		cube.position.set(0, 0, 0);

		const axesHelper = new THREE.AxesHelper(50);

		//scene.add(axesHelper);
		//add volume mesh
		const materialVolume = new THREE.RawShaderMaterial({
				glslVersion: THREE.GLSL3,
				uniforms: {
					volume: { value: texture3dEmpty },
					u_eye_pos: { value: new THREE.Vector3() },
					u_x_domain: { value: new THREE.Vector2() },
					u_y_domain: { value: new THREE.Vector2() },
					u_z_domain: { value: new THREE.Vector2() },
					u_table_domain: { value: new THREE.Vector2() },
					u_table_max: { value: 0.0 },
					u_radar_pos: { value: new THREE.Vector2() },
					u_height_scale: { value: 0.0 },
					u_scale: { value: 0.0 },
					u_offset: { value: 0.0 },
					u_bin1: { value: 0.0 },
					u_ngates: { value: 0.0 },
					u_binMax: { value: 0.0 },
					u_num_elevations: { value: 0.0 },
					table: { value: texture2dEmpty },
					tf: { value: texture2dEmpty },
					bracket: { value: texture2dEmpty }
				},
				vertexShader,
				fragmentShader,
				transparent: true,
				side: THREE.BackSide
			});

		const materialPlane = new THREE.RawShaderMaterial({
				glslVersion: THREE.GLSL3,
				uniforms: {
					volume: { value: texture3dEmpty },
					u_eye_pos: { value: new THREE.Vector3() },
					u_plane_center: { value: new THREE.Vector3() },
					u_plane_normal: { value: new THREE.Vector3() },
					// u_x_domain: {value: new THREE.Vector2()},
					// u_y_domain: {value: new THREE.Vector2()},
					// u_z_domain: {value: new THREE.Vector2()},
					u_table_domain: { value: new THREE.Vector2() },
					u_table_max: { value: 0.0 },
					u_radar_pos: { value: new THREE.Vector2() },
					u_height_scale: { value: 0.0 },
					u_scale: { value: 0.0 },
					u_offset: { value: 0.0 },
					u_bin1: { value: 0.0 },
					u_ngates: { value: 0.0 },
					u_binMax: { value: 0.0 },
					u_num_elevations: { value: 0.0 },
					table: { value: texture2dEmpty },
					tf: { value: texture2dEmpty },
					bracket: { value: texture2dEmpty }
				},
				vertexShader: vertexShaderPlane,
				fragmentShader: fragmentShaderPlane,
				transparent: true,
				side: THREE.DoubleSide
			});

		const geometryVolume = new THREE.BufferGeometry();
		const geometryPlane = new THREE.BufferGeometry();
		geometryVolume.setAttribute('position', new THREE.BufferAttribute(positions, 3));
		geometryPlane.setAttribute('position', new THREE.BufferAttribute(positionsPlane, 3));

		//mesh is combination of geometry and material
		mesh = new THREE.Mesh(geometryVolume, materialVolume);

		mesh.frustumCulled = false;
		meshPlane = new THREE.Mesh(geometryPlane, materialPlane);
		meshPlane.frustumCulled = false;
		meshPlane.name = "meshPlane";
		mesh.name = "mesh";

		//scene.add(mesh);
		controls.addEventListener('change', render);

		render();
	});

	function updateTexture() {
		//console.log("updating texture");
		if (tfTexture) {
			tfTexture.needsUpdate = true;
			const newData = textureData.data;
			const data = tfTexture.image.data;
			const size = tfTexture.image.width * tfTexture.image.height;

			for (let i = 0; i < size; i++) {
				const stride = i * 4;
				data[stride] = newData[stride];
				data[stride + 1] = newData[stride + 1];
				data[stride + 2] = newData[stride + 2];
				data[stride + 3] = newData[stride + 3];
			}

			render();
		}
	}

	function show() {
		$$invalidate(5, showTrans = showTrans ? false : true);
	}

	function canvas_1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvas = $$value;
			$$invalidate(2, canvas);
		});
	}

	function menu3d_showTrans_binding(value) {
		showTrans = value;
		$$invalidate(5, showTrans);
	}

	function menu3d_imageData_binding(value) {
		textureData = value;
		$$invalidate(0, textureData);
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			labelDiv = $$value;
			$$invalidate(3, labelDiv);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$radarMetadata*/ 16384) {
			//when radar changes, remove mesh
			$: ($radarMetadata, removeMeshes());
		}

		if ($$self.$$.dirty[0] & /*$INT_STATE*/ 2) {
			$: ($INT_STATE, setInstruction());
		}

		if ($$self.$$.dirty[0] & /*$box*/ 8192) {
			$: ($box, setBox());
		}

		if ($$self.$$.dirty[0] & /*$line*/ 4096) {
			$: ($line, setPlane());
		}

		if ($$self.$$.dirty[0] & /*$texture_3d*/ 2048) {
			$: ($texture_3d, loadTexture());
		}

		if ($$self.$$.dirty[0] & /*textureData*/ 1) {
			$: (textureData, updateTexture());
		}
	};

	return [
		textureData,
		$INT_STATE,
		canvas,
		labelDiv,
		instruction,
		showTrans,
		$LOADING_3D,
		MODE3D,
		show,
		removeMeshes,
		loadTexture,
		$texture_3d,
		$line,
		$box,
		$radarMetadata,
		canvas_1_binding,
		menu3d_showTrans_binding,
		menu3d_imageData_binding,
		div1_binding
	];
}

class MenuCanvas extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { removeMeshes: 9, loadTexture: 10 }, null, [-1, -1]);
	}

	get removeMeshes() {
		return this.$$.ctx[9];
	}

	get loadTexture() {
		return this.$$.ctx[10];
	}
}

export default MenuCanvas;