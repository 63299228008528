import './FileView.svelte.css';
/* src/FileView.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	add_flush_callback,
	assign,
	attr,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	get_spread_object,
	get_spread_update,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import RadarCard from './RadarCard.svelte.js';
import { scans, selections, sites, animation } from './store.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (15:8) {#if scan && scan.scans}
function create_if_block(ctx) {
	let radarcard;
	let updating_active;
	let updating_loading;
	let current;
	const radarcard_spread_levels = [/*scan*/ ctx[7], { localScans: /*scan*/ ctx[7].scans }];

	function radarcard_active_binding(value) {
		/*radarcard_active_binding*/ ctx[5](value);
	}

	function radarcard_loading_binding(value) {
		/*radarcard_loading_binding*/ ctx[6](value);
	}

	let radarcard_props = {};

	for (let i = 0; i < radarcard_spread_levels.length; i += 1) {
		radarcard_props = assign(radarcard_props, radarcard_spread_levels[i]);
	}

	if (/*active*/ ctx[0] !== void 0) {
		radarcard_props.active = /*active*/ ctx[0];
	}

	if (/*loading*/ ctx[1] !== void 0) {
		radarcard_props.loading = /*loading*/ ctx[1];
	}

	radarcard = new RadarCard({ props: radarcard_props });
	binding_callbacks.push(() => bind(radarcard, 'active', radarcard_active_binding));
	binding_callbacks.push(() => bind(radarcard, 'loading', radarcard_loading_binding));

	return {
		c() {
			create_component(radarcard.$$.fragment);
		},
		m(target, anchor) {
			mount_component(radarcard, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const radarcard_changes = (dirty & /*$scans*/ 4)
			? get_spread_update(radarcard_spread_levels, [
					get_spread_object(/*scan*/ ctx[7]),
					{ localScans: /*scan*/ ctx[7].scans }
				])
			: {};

			if (!updating_active && dirty & /*active*/ 1) {
				updating_active = true;
				radarcard_changes.active = /*active*/ ctx[0];
				add_flush_callback(() => updating_active = false);
			}

			if (!updating_loading && dirty & /*loading*/ 2) {
				updating_loading = true;
				radarcard_changes.loading = /*loading*/ ctx[1];
				add_flush_callback(() => updating_loading = false);
			}

			radarcard.$set(radarcard_changes);
		},
		i(local) {
			if (current) return;
			transition_in(radarcard.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(radarcard.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(radarcard, detaching);
		}
	};
}

// (14:4) {#each $scans as scan}
function create_each_block(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*scan*/ ctx[7] && /*scan*/ ctx[7].scans && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*scan*/ ctx[7] && /*scan*/ ctx[7].scans) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$scans*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let each_value = /*$scans*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "scroll svelte-1kipxq3");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*$scans, active, loading*/ 7) {
				each_value = /*$scans*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $sites;
	let $selections;
	let $scans;
	component_subscribe($$self, sites, $$value => $$invalidate(3, $sites = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(4, $selections = $$value));
	component_subscribe($$self, scans, $$value => $$invalidate(2, $scans = $$value));
	let active; //need to define based on selection (filename+idx?)
	let loading;

	function radarcard_active_binding(value) {
		active = value;
		$$invalidate(0, active);
	}

	function radarcard_loading_binding(value) {
		loading = value;
		$$invalidate(1, loading);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$selections*/ 16) {
			//whenever selections change, update component state
			$: ($selections, animation.setStates());
		}

		if ($$self.$$.dirty & /*$sites*/ 8) {
			$: ($sites, animation.setSites());
		}
	};

	return [
		active,
		loading,
		$scans,
		$sites,
		$selections,
		radarcard_active_binding,
		radarcard_loading_binding
	];
}

class FileView extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default FileView;