import './Map.svelte.css';
/* src/Map.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_store_value,
	set_style,
	space,
	toggle_class,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	map,
	selections,
	vertexData,
	radarMetadata,
	box,
	MAP_STATE,
	DEALIAS_STATE,
	INT_STATE,
	line,
	nexradSites,
	socket,
	MODE_STATE,
	alerts,
	lightning,
	SITES,
	ALERTS,
	ANIMATING,
	LOADING_LIVE,
	STATEMENTS,
	LIGHTNING,
	STYLE,
	dataSettings,
	colorTables,
	promise,
	trendingData,
	TRENDING,
	SPLIT_STATE
} from './store.js';

import { onMount } from '../_snowpack/pkg/svelte.js';
import { mapboxgl } from './map.js';
import { shaders } from './shaders.js';
import { afterUpdate } from '../_snowpack/pkg/svelte.js';
import syncMaps from './sync.js';
import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import { MAP_OBJECT, DEALIAS_OBJECT, INT_OBJECT, MODE_OBJECT } from './enums.js';
import { UpdateData } from './UpdateClass.js';
import MapBar from './MapBar.svelte.js';

import {
	createTexture,
	extentsDecode,
	vmin,
	vmax,
	createLocalTexture
} from './colorbars.js';

import { beamHeight } from './utils.js';
import { createIcon } from './renderHelpers.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[110] = list[i];
	return child_ctx;
}

// (2026:4) {#if loading}
function create_if_block(ctx) {
	let div6;

	return {
		c() {
			div6 = element("div");

			div6.innerHTML = `<div class="radar-container svelte-1gtocuo"><div class="background svelte-1gtocuo"></div> 
                <div class="line svelte-1gtocuo"></div> 
                <div class="inner svelte-1gtocuo"></div> 
                <div class="outer svelte-1gtocuo"></div> 
                <div class="border svelte-1gtocuo"></div></div>`;

			attr(div6, "class", "loading svelte-1gtocuo");
		},
		m(target, anchor) {
			insert(target, div6, anchor);
		},
		d(detaching) {
			if (detaching) detach(div6);
		}
	};
}

// (2043:0) {#each canvases as canvas}
function create_each_block(ctx) {
	let canvas;
	let canvas_id_value;

	return {
		c() {
			canvas = element("canvas");
			set_style(canvas, "display", "none");
			attr(canvas, "id", canvas_id_value = /*canvas*/ ctx[110]);
		},
		m(target, anchor) {
			insert(target, canvas, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(canvas);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let div0_id_value;
	let t0;
	let canvas0;
	let t1;
	let mapbar;
	let t2;
	let div1_id_value;
	let t3;
	let t4;
	let canvas1;
	let canvas1_width_value;
	let canvas1_height_value;
	let current;

	mapbar = new MapBar({
			props: {
				mouseoverValue: /*decoded*/ ctx[7],
				field: /*selectedField*/ ctx[8],
				idx: /*idx*/ ctx[1],
				height: /*height*/ ctx[10],
				over: /*over*/ ctx[3]
			}
		});

	mapbar.$on("message", /*handleMessage*/ ctx[2]);
	let if_block = /*loading*/ ctx[9] && create_if_block(ctx);
	let each_value = /*canvases*/ ctx[11];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = space();
			canvas0 = element("canvas");
			t1 = space();
			create_component(mapbar.$$.fragment);
			t2 = space();
			if (if_block) if_block.c();
			t3 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			canvas1 = element("canvas");
			attr(div0, "id", div0_id_value = "map" + /*idx*/ ctx[1]);
			attr(div0, "class", "mapContainer svelte-1gtocuo");
			toggle_class(div0, "active", !/*show*/ ctx[0]);
			attr(canvas0, "class", "overlay-canvas svelte-1gtocuo");
			attr(div1, "id", div1_id_value = "mapwrapper" + /*idx*/ ctx[1]);
			attr(div1, "class", "mapWrapper svelte-1gtocuo");
			toggle_class(div1, "active", !/*show*/ ctx[0]);
			attr(canvas1, "class", "canvas3d svelte-1gtocuo");
			attr(canvas1, "width", canvas1_width_value = 32);
			attr(canvas1, "height", canvas1_height_value = 32);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			/*div0_binding*/ ctx[29](div0);
			append(div1, t0);
			append(div1, canvas0);
			/*canvas0_binding*/ ctx[30](canvas0);
			append(div1, t1);
			mount_component(mapbar, div1, null);
			append(div1, t2);
			if (if_block) if_block.m(div1, null);
			insert(target, t3, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, t4, anchor);
			insert(target, canvas1, anchor);
			/*canvas1_binding*/ ctx[31](canvas1);
			current = true;
		},
		p(ctx, dirty) {
			if (!current || dirty[0] & /*idx*/ 2 && div0_id_value !== (div0_id_value = "map" + /*idx*/ ctx[1])) {
				attr(div0, "id", div0_id_value);
			}

			if (dirty[0] & /*show*/ 1) {
				toggle_class(div0, "active", !/*show*/ ctx[0]);
			}

			const mapbar_changes = {};
			if (dirty[0] & /*decoded*/ 128) mapbar_changes.mouseoverValue = /*decoded*/ ctx[7];
			if (dirty[0] & /*selectedField*/ 256) mapbar_changes.field = /*selectedField*/ ctx[8];
			if (dirty[0] & /*idx*/ 2) mapbar_changes.idx = /*idx*/ ctx[1];
			if (dirty[0] & /*height*/ 1024) mapbar_changes.height = /*height*/ ctx[10];
			if (dirty[0] & /*over*/ 8) mapbar_changes.over = /*over*/ ctx[3];
			mapbar.$set(mapbar_changes);

			if (/*loading*/ ctx[9]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (!current || dirty[0] & /*idx*/ 2 && div1_id_value !== (div1_id_value = "mapwrapper" + /*idx*/ ctx[1])) {
				attr(div1, "id", div1_id_value);
			}

			if (dirty[0] & /*show*/ 1) {
				toggle_class(div1, "active", !/*show*/ ctx[0]);
			}

			if (dirty[0] & /*canvases*/ 2048) {
				each_value = /*canvases*/ ctx[11];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t4.parentNode, t4);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i(local) {
			if (current) return;
			transition_in(mapbar.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mapbar.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			/*div0_binding*/ ctx[29](null);
			/*canvas0_binding*/ ctx[30](null);
			destroy_component(mapbar);
			if (if_block) if_block.d();
			if (detaching) detach(t3);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t4);
			if (detaching) detach(canvas1);
			/*canvas1_binding*/ ctx[31](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $map;
	let $STYLE;
	let $colorTables;
	let $ANIMATING;
	let $INT_STATE;
	let $selections;
	let $vertexData;
	let $DEALIAS_STATE;
	let $dataSettings;
	let $SPLIT_STATE;
	let $LIGHTNING;
	let $TRENDING;
	let $STATEMENTS;
	let $ALERTS;
	let $SITES;
	let $radarMetadata;
	let $line;
	let $box;
	let $MAP_STATE;
	let $MODE_STATE;
	let $LOADING_LIVE;
	let $nexradSites;
	let $alerts;
	let $trendingData;
	let $lightning;
	component_subscribe($$self, map, $$value => $$invalidate(45, $map = $$value));
	component_subscribe($$self, STYLE, $$value => $$invalidate(12, $STYLE = $$value));
	component_subscribe($$self, colorTables, $$value => $$invalidate(13, $colorTables = $$value));
	component_subscribe($$self, ANIMATING, $$value => $$invalidate(46, $ANIMATING = $$value));
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(14, $INT_STATE = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(15, $selections = $$value));
	component_subscribe($$self, vertexData, $$value => $$invalidate(16, $vertexData = $$value));
	component_subscribe($$self, DEALIAS_STATE, $$value => $$invalidate(47, $DEALIAS_STATE = $$value));
	component_subscribe($$self, dataSettings, $$value => $$invalidate(17, $dataSettings = $$value));
	component_subscribe($$self, SPLIT_STATE, $$value => $$invalidate(18, $SPLIT_STATE = $$value));
	component_subscribe($$self, LIGHTNING, $$value => $$invalidate(19, $LIGHTNING = $$value));
	component_subscribe($$self, TRENDING, $$value => $$invalidate(20, $TRENDING = $$value));
	component_subscribe($$self, STATEMENTS, $$value => $$invalidate(21, $STATEMENTS = $$value));
	component_subscribe($$self, ALERTS, $$value => $$invalidate(22, $ALERTS = $$value));
	component_subscribe($$self, SITES, $$value => $$invalidate(23, $SITES = $$value));
	component_subscribe($$self, radarMetadata, $$value => $$invalidate(24, $radarMetadata = $$value));
	component_subscribe($$self, line, $$value => $$invalidate(48, $line = $$value));
	component_subscribe($$self, box, $$value => $$invalidate(49, $box = $$value));
	component_subscribe($$self, MAP_STATE, $$value => $$invalidate(25, $MAP_STATE = $$value));
	component_subscribe($$self, MODE_STATE, $$value => $$invalidate(50, $MODE_STATE = $$value));
	component_subscribe($$self, LOADING_LIVE, $$value => $$invalidate(51, $LOADING_LIVE = $$value));
	component_subscribe($$self, nexradSites, $$value => $$invalidate(52, $nexradSites = $$value));
	component_subscribe($$self, alerts, $$value => $$invalidate(26, $alerts = $$value));
	component_subscribe($$self, trendingData, $$value => $$invalidate(27, $trendingData = $$value));
	component_subscribe($$self, lightning, $$value => $$invalidate(28, $lightning = $$value));
	const { REFLECTIVITY, VELOCITY, VELOCITY_DEALIASED, CC, PHI, ZDR, SW } = MAP_OBJECT;
	const { DEALIASED } = DEALIAS_OBJECT;
	const { READING, MODE3D, CROSS } = INT_OBJECT;
	const { HISTORICAL, LIVE } = MODE_OBJECT;
	const dispatch = createEventDispatcher();
	const updateInstance = UpdateData();
	let { show } = $$props;
	let { idx } = $$props;
	let style = $STYLE.styleid;
	let beforeLayer = $STYLE.layer;
	let over = 0;
	let changingStyle = false;
	const popup = new mapboxgl.Popup();

	function updateAlerts() {
		if (!$map[idx]) return;
		const source = $map[idx].getSource("alerts");

		if (source) {
			source.setData($alerts);
		}
	}

	function updateTrending() {
		if (!$map[idx]) return;
		const source = $map[idx].getSource("trending");

		if (source) {
			source.setData($trendingData);
		}
	}

	function updateLightning() {
		if (!$map[idx]) return;
		const source = $map[idx].getSource("lightning");

		if (source) {
			source.setData($lightning);
			const seconds = new Date().getTime() / 1000.0;

			$map[idx].setPaintProperty('lightning', 'circle-color', [
				'interpolate',
				['linear'],
				["/", ["-", seconds, ["get", "date"]], 1800.0],
				0.1,
				"#fff",
				1,
				"#696969"
			]);
		}
	}

	const markers = {};
	let container;
	let canvases = [0, 1];
	let colorCanvas;
	let overlayCanvas; //bound canvas element
	let OverlayCanvas; //factory instance
	let textures = {};
	let imageData = {};
	let pixels = new Uint8Array(4);
	let decoded;
	let selectedField;
	let loading = false;
	let mapGL;

	// let lat;
	// let lon;
	let height;

	function repaint() {
		if ($map[idx]) {
			$map[idx].triggerRepaint();
		}
	}

	function setLoading() {
		$$invalidate(9, loading = true);
	}

	function flyTo() {
		if ("lon" in $radarMetadata && $map[idx]) {
			if ($MODE_STATE === HISTORICAL) {
				$map[idx].flyTo({
					center: [$radarMetadata.lon, $radarMetadata.lat],
					zoom: 7.5
				});
			}

			removeBox();
			removeLine();
			set_store_value(line, $line = null, $line);
			set_store_value(box, $box = null, $box);
		}
	}

	let boo = 0;

	function toggleShow() {
		boo = 1;
	}

	function addAndShowLightning() {
		const source = $map[idx].getSource("lightning");

		if (source === undefined) {
			$map[idx].addSource("lightning", { type: "geojson", data: $lightning });
		}

		let visibility;

		if ($LIGHTNING) {
			visibility = 'visible';
		} else {
			visibility = 'none';
		}

		const layerList = $map[idx].getStyle().layers.map(x => x.id);

		if (layerList.indexOf("lightning") < 0) {
			$map[idx].addLayer({
				'id': 'lightning-blur',
				'type': 'circle',
				'source': 'lightning',
				'paint': {
					'circle-color': "#000",
					'circle-opacity': 0.3,
					'circle-blur': 0.4,
					'circle-radius': ["interpolate", ["linear"], ["zoom"], 6, 4, 13, 12],
					'circle-translate': [2, 2]
				},
				'layout': { visibility }
			});

			const seconds = new Date().getTime() / 1000.0;

			$map[idx].addLayer({
				'id': 'lightning',
				'type': 'circle',
				'source': 'lightning',
				'paint': {
					'circle-color': [
						'interpolate',
						['linear'],
						["/", ["-", seconds, ["get", "date"]], 1800.0],
						0.1,
						"#fff",
						1,
						"#696969"
					],
					'circle-radius': ["interpolate", ["linear"], ["zoom"], 6, 3, 13, 8],
					'circle-stroke-color': '#ebebeb',
					'circle-stroke-width': ["interpolate", ["linear"], ["zoom"], 6, 0.25, 13, 1.5],
					'circle-stroke-opacity': 1
				},
				'layout': { visibility }
			});
		}
	}

	function addAndShowAlerts() {
		const source = $map[idx].getSource("alerts");

		if (source === undefined) {
			$map[idx].addSource("alerts", { type: "geojson", data: $alerts });
		}

		let visibility;

		if ($ALERTS) {
			visibility = 'visible';
		} else {
			visibility = 'none';
		}

		const layerList = $map[idx].getStyle().layers.map(x => x.id);
		let layerId;

		if (layerList.indexOf("sites") > -1) {
			layerId = "sites";
		} else {
			layerId = "";
		}

		if (layerList.indexOf("alerts-background") < 0) {
			$map[idx].addLayer(
				{
					'id': 'alerts-background',
					'type': 'line',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["!=", ['get', 'event'], "Special Weather Statement"],
					'paint': {
						'line-color': "#232323",
						'line-width': 4.5
					}
				},
				layerId
			);

			$map[idx].addLayer(
				{
					'id': 'alerts',
					'type': 'line',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["!=", ['get', 'event'], "Special Weather Statement"],
					'paint': {
						'line-color': [
							"case",
							["==", ['get', 'event'], "Tornado Warning"],
							"#f00",
							["==", ['get', 'event'], "Severe Thunderstorm Warning"],
							"#ff0",
							["==", ['get', 'event'], "Flash Flood Warning"],
							'#0f0',
							["==", ['get', 'event'], "Winter Storm Warning"],
							'#f0f',
							'#fff'
						],
						'line-width': 3
					}
				},
				layerId
			);

			$map[idx].addLayer(
				{
					'id': 'alerts-fill',
					'type': 'fill',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["!=", ['get', 'event'], "Special Weather Statement"],
					'paint': { 'fill-opacity': 0 }
				},
				layerId
			);
		}
	}

	function addAndShowTrending() {
		const source = $map[idx].getSource("trending");

		if (source === undefined) {
			$map[idx].addSource("trending", { type: "geojson", data: $trendingData });
		}

		let visibility;

		if ($TRENDING) {
			visibility = 'visible';
		} else {
			visibility = 'none';
		}

		const layerList = $map[idx].getStyle().layers.map(x => x.id);
		let layerId;

		//before layer
		if (layerList.indexOf("sws") > -1) {
			layerId = "sws";
		} else {
			layerId = "";
		}

		if (layerList.indexOf("trending") < 0) {
			$map[idx].addLayer(
				{
					'id': 'trending',
					'type': 'circle',
					'source': 'trending',
					'layout': { visibility },
					'paint': {
						'circle-color': ['case', ['to-boolean', ['get', 'trending']], "#FFE83D", "#8356ff"],
						'circle-radius': ['get', 'scaling'],
						'circle-opacity': 0.6,
						'circle-stroke-color': ["case", ['to-boolean', ['get', 'trending']], "#FFE83D", "#8356ff"],
						'circle-stroke-width': ["case", ['to-boolean', ['get', 'trending']], 5, 3],
						'circle-stroke-opacity': 1
					}
				},
				layerId
			);
		}
	}

	function addAndShowStatements() {
		const source = $map[idx].getSource("alerts");

		if (source === undefined) {
			$map[idx].addSource("alerts", { type: "geojson", data: $alerts });
		}

		let visibility;

		if ($STATEMENTS) {
			visibility = 'visible';
		} else {
			visibility = 'none';
		}

		const layerList = $map[idx].getStyle().layers.map(x => x.id);
		let layerId;

		if (layerList.indexOf("alerts") > -1) {
			layerId = "alerts";
		} else {
			layerId = "";
		}

		if (layerList.indexOf("sws-background") < 0) {
			$map[idx].addLayer(
				{
					'id': 'sws-background',
					'type': 'line',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["==", ['get', 'event'], "Special Weather Statement"],
					'paint': {
						'line-color': "#232323",
						'line-width': 4.5
					}
				},
				layerId
			);

			$map[idx].addLayer(
				{
					'id': 'sws',
					'type': 'line',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["==", ['get', 'event'], "Special Weather Statement"],
					'paint': {
						'line-color': [
							"case",
							["==", ['get', 'event'], "Special Weather Statement"],
							"#fff",
							'#fff'
						],
						'line-width': 3
					}
				},
				layerId
			);

			$map[idx].addLayer(
				{
					'id': 'sws-fill',
					'type': 'fill',
					'source': 'alerts',
					'layout': { visibility },
					'filter': ["==", ['get', 'event'], "Special Weather Statement"],
					'paint': { 'fill-opacity': 0 }
				},
				layerId
			);
		}
	}

	function addAndShow() {
		const source = $map[idx].getSource("sites");

		if (source === undefined) {
			$map[idx].addSource("sites", { type: "geojson", data: $nexradSites });
			const prop = socket.site === undefined ? null : socket.site;

			$map[idx].addLayer({
				'id': 'sites',
				'type': 'symbol',
				'source': 'sites',
				'layout': {
					//'icon-image':'unselected',
					'icon-image': [
						"case",
						["==", ["slice", ['get', 'STATION_ID'], 7], prop],
						'selected',
						'unselected'
					],
					'text-field': ["slice", ['get', 'STATION_ID'], 7],
					'icon-text-fit': 'both',
					'text-size': 12,
					'text-font': ["Arial Unicode MS Bold"],
					'icon-text-fit-padding': [4, 8, 4, 8]
				},
				'paint': {
					'text-color': "#fff",
					'icon-halo-width': 20,
					'icon-halo-color': "#fff"
				}
			});

			$map[idx].on('click', ['sites', 'sws-fill', 'alerts-fill'], function (e) {
				if ($MODE_STATE != LIVE || $LOADING_LIVE) return;
				const siteFeature = e.features.filter(x => x.layer.id === 'sites');
				const alertFeatures = e.features.filter(x => x.layer.id != 'sites');

				//if click a site, show popup but don't show alert popup if coincident
				if (siteFeature.length) {
					const stationId = siteFeature[0].properties.STATION_ID.split(":").slice(-1)[0];

					if (stationId[0] === "K" || stationId[0] === "P" || stationId[0] === "T") {
						socket.setSite(stationId);

						$map[idx].setLayoutProperty('sites', 'icon-image', [
							"case",
							["==", ["slice", ['get', 'STATION_ID'], 7], socket.site],
							'selected',
							'unselected'
						]);
					}
				} else if (alertFeatures.length) {
					const colors = {
						"Tornado Warning": "#ef1212",
						"Special Weather Statement": "#000",
						"Severe Thunderstorm Warning": "#f9dc00",
						"Flash Flood Warning": "#00990F"
					};

					let content = '';

					for (let i = 0; i < alertFeatures.length; i++) {
						content += `<h3 style="
                    background-color:${colors[alertFeatures[i].properties.event]};
                    color:white;
                    padding:8px;
                    white-space:normal;
                    margin:0;
                    border-radius:inherit;
                    border-bottom-left-radius:0px;
                    border-bottom-right-radius:-0px;
                    ">
                        ${alertFeatures[i].properties.event}
                        </h3><p style="
                        font-weight:bold;
                        padding:8px;
                        padding-bottom:0px;
                        margin:0;
                        ">${alertFeatures[i].properties.headline}
                            </p><p style="
                            margin:0;
                            padding:8px;
                            ">${alertFeatures[i].properties.description}
                                </p>`;
					}

					if (popup.isOpen()) {
						popup.remove();
					}

					//new mapboxgl.Popup()
					popup.setLngLat(e.lngLat).setHTML(content).addTo($map[idx]);
				}
			});

			// $map[idx].on('click', ['sws-fill', 'alerts-fill'], function(e) {
			//     const colors = {
			//         "Tornado Warning":"#ef1212",
			//         "Special Weather Statement":"#000",
			//         "Severe Thunderstorm Warning":"#f9dc00",
			//         "Flash Flood Warning":"#00990F"
			//     }
			//     let content = '';
			//     for (let i=0; i<e.features.length; i++) {
			//         content += `<h3 style="
			//         background-color:${colors[e.features[i].properties.event]};
			//         color:white;
			//         padding:8px;
			//         white-space:normal;
			//         margin:0;
			//         border-radius:inherit;
			//         border-bottom-left-radius:0px;
			//         border-bottom-right-radius:-0px;
			//         ">
			//             ${e.features[i].properties.event}
			//             </h3><p style="
			//             font-weight:bold;
			//             padding:8px;
			//             padding-bottom:0px;
			//             margin:0;
			//             ">${e.features[i].properties.headline}
			//                 </p><p style="
			//                 margin:0;
			//                 padding:8px;
			//                 ">${e.features[i].properties.description}
			//                     </p>`
			//     }
			//     if (popup.isOpen()) {
			//         popup.remove();
			//     }
			//     //new mapboxgl.Popup()
			//     popup
			//     .setLngLat(e.lngLat)
			//     .setHTML(content)
			//     .addTo($map[idx]);
			// })
			$map[idx].on('mouseenter', ['sites', 'sws-fill', 'alerts-fill'], function (e) {
				if ($MODE_STATE === LIVE) $map[idx].getCanvas().style.cursor = 'pointer';
			});

			$map[idx].on('mouseleave', ['sites', 'sws-fill', 'alerts-fill'], function (e) {
				if ($INT_STATE === CROSS || $INT_STATE === MODE3D) {
					$map[idx].getCanvas().style.cursor = 'crosshair';
				} else {
					$map[idx].getCanvas().style.cursor = '';
				}
			});
		}
	}

	function handleMessage(m) {
		//exit if doesn't pertain to component instance
		if (m === null) return;

		if (m.detail.value === "loadVertices") {
			if (mapGL) {
				mapGL.updateData();
				$$invalidate(9, loading = false);
			}
		}

		if (m.detail.value === "setBox") {
			setData(m.detail.box);
		}

		if (m.detail.value === "setLine") {
			setData(m.detail.line);
		}

		if (m.detail.value === "readValue") {
			readValue(m.detail.coords);
		}

		if (m.detail.value === "showHide") {
			$$invalidate(3, over = m.detail.over);
			if (over === 0) OverlayCanvas?.clear();
		}

		if (m.detail.value === "removeBox") {
			removeBox();
		}

		if (m.detail.value === "removeLine") {
			removeLine();
		}

		if (m.detail.value === "showSites") {
			addAndShow();
		}

		if (m.detail.value === "showAlerts") {
			addAndShowAlerts();
		}

		if (m.detail.value === "showLightning") {
			addAndShowLightning();
		}

		if (m.detail.value === "showStatements") {
			addAndShowStatements();
		}

		if (m.detail.value === "activateMap") {
			//may introduce bugginess if events still on
			toggleInteraction();

			//make field dealiased if button had been clicked while hidden
			if ($map[idx].field === VELOCITY || $map[idx].field === VELOCITY_DEALIASED) {
				set_store_value(
					map,
					$map[idx].field = $DEALIAS_STATE === DEALIASED
					? VELOCITY_DEALIASED
					: VELOCITY,
					$map
				);
			}

			if ($selections[0].scan) updateInstance.logStore($selections[0].scan);
		}

		if (m.detail.value === "setField") {
			const newField = m.detail.field;
			updateField(newField);
		}

		if (m.detail.value === "clearMap") {
			mapGL.clear();
			$map[idx].triggerRepaint();
		}

		if (m.detail.value === "setDealiasState") {
			//if field is velocity then do something, otherwise don't need to
			if ($map[idx].field === VELOCITY || $map[idx].field === VELOCITY_DEALIASED) {
				$DEALIAS_STATE === DEALIASED
				? updateField(VELOCITY_DEALIASED)
				: updateField(VELOCITY);

				updateInstance.logStore($selections[idx].scan);
			}
		}

		if (m.detail.value === "startLoading") {
			$$invalidate(9, loading = true);
		}
	}

	//$: $message, handleMessage();
	afterUpdate(() => {
		if ($map[idx] && boo) {
			$map[idx].resize();

			// const w = document.getElementById('map0').clientWidth;
			// const h = document.getElementById('map0').clientHeight;
			boo = 0;
		}
	});

	const DRAWING = Symbol("drawing");
	const DONE = Symbol("done");
	let STATUS = READING;
	let start, current;

	function finish(corners) {
		set_store_value(box, $box = corners, $box);
	}

	function finishLine(corners) {
		set_store_value(line, $line = corners, $line);
	}

	function setData(corners) {
		const lat1 = corners[0].lat;
		const lon1 = corners[0].lng;
		const lat2 = corners[1].lat;
		const lon2 = corners[1].lng;

		if ($INT_STATE === MODE3D) {
			const nw = [Math.min(lon1, lon2), Math.max(lat1, lat2)];
			const se = [Math.max(lon1, lon2), Math.min(lat1, lat2)];

			const data = {
				'type': 'Feature',
				'geometry': {
					'type': 'Polygon',
					'coordinates': [[nw, [se[0], nw[1]], se, [nw[0], se[1]], nw]]
				}
			};

			$map[idx].getSource('box').setData(data);
		}

		if ($INT_STATE === CROSS) {
			const data = {
				'type': 'Feature',
				'geometry': {
					'type': 'LineString',
					'coordinates': [[lon1, lat1], [lon2, lat2]]
				}
			};

			// const pointData = {
			//     'type':'FeatureCollection',
			//     'features':[
			//         {
			//             'type':'Feature',
			//             'properties': {
			//                 'letter':'A'
			//             },
			//             'geometry': {
			//                 'type':'Point',
			//                 'coordinates':[lon1,lat1]
			//             }
			//         },
			//         {
			//             'type':'Feature',
			//             'properties': {
			//                 'letter':'B'
			//             },
			//             'geometry': {
			//                 'type':'Point',
			//                 'coordinates':[lon2,lat2]
			//             }
			//         }
			//     ]
			// }
			$map[idx].getSource('line').setData(data);

			if (markers['A']) markers['A'].setLngLat([lon1, lat1]).addTo($map[idx]);
			if (markers['B']) markers['B'].setLngLat([lon2, lat2]).addTo($map[idx]);
		} // $map[idx].getSource('points').setData(pointData);
	}

	function removeBox() {
		if ($map[idx] && $map[idx].getSource('box')) {
			set_store_value(box, $box = null, $box);

			$map[idx].getSource('box').setData({
				'type': 'Feature',
				'geometry': { 'type': 'Polygon', 'coordinates': [] }
			});
		}
	}

	function removeLine() {
		//if ($map[idx] && $map[idx].getSource('line') && $map[idx].getSource('points')) {
		if ($map[idx] && $map[idx].getSource('line')) {
			set_store_value(line, $line = null, $line);

			$map[idx].getSource('line').setData({
				'type': 'Feature',
				'geometry': { 'type': 'Polygon', 'coordinates': [] }
			});

			// $map[idx].getSource('points').setData({
			//             'type' : 'Feature',
			//             'geometry': {
			//                 'type': 'Polygon',
			//                 'coordinates': []
			// }
			// })
			if (markers['A']) markers['A'].remove();

			if (markers['B']) markers['B'].remove();
		}
	}

	function onMouseDown(e) {
		STATUS = DRAWING;
		start = e.lngLat.wrap();
	}

	function mousePos(e) {
		const rect = container.getBoundingClientRect();
		const point = new mapboxgl.Point(e.clientX - rect.left - container.clientLeft, e.clientY - rect.top - container.clientTop);
		const mapCoordinate = $map[idx].unproject(point);
		return mapCoordinate;
	}

	function containerMouseDown(e) {
		if ($INT_STATE === MODE3D && e.composedPath()[0].localName != "select") {
			removeBox();
		}

		if ($INT_STATE === CROSS && e.composedPath()[0].localName != "select") {
			removeLine();
		}

		//most behavior between 3d and cross the same
		if ($INT_STATE === MODE3D || $INT_STATE === CROSS) {
			//if map not in path, return
			if (e.composedPath().indexOf($map[idx]._canvas) == -1) {
				return;
			}

			STATUS = DRAWING;
			start = mousePos(e);
		} // if ($INT_STATE === CROSS) {
		//     markers['A'].setLngLat([start.lng, start.lat]).addTo($map[idx]);
	} //     markers['B'].setLngLat([start.lng, start.lat]).addTo($map[idx]);
	// }

	function onMouseMove(e) {
		breakable: if (STATUS === DRAWING) {
			//ignore events from map 
			if (e.lngLat) break breakable;

			current = mousePos(e);
			setData([start, current]);

			if ($INT_STATE === MODE3D) {
				dispatch('message', {
					value: "setBox",
					idx,
					box: [start, current]
				});
			} else if ($INT_STATE === CROSS) {
				dispatch('message', {
					value: "setLine",
					idx,
					line: [start, current]
				});
			}
		}

		if ($INT_STATE === READING && !$ANIMATING) {
			//console.log($radarMetadata, e, $selections);
			//console.log(beamHeight);
			if ($radarMetadata && $radarMetadata.lon && $selections[0].scan) {
				// lon = e.lngLat.lng;
				// lat = e.lngLat.lat;
				$$invalidate(10, height = beamHeight($radarMetadata.lon, $radarMetadata.lat, e.lngLat.lng, e.lngLat.lat, $selections[0].scan.scan[0].elevation));
			} else {
				// lon = null;
				// lat = null;
				$$invalidate(10, height = null);
			}

			if (mapGL) {
				const factor = window.devicePixelRatio || 1;

				//send event to other map
				dispatch('message', {
					value: "readValue",
					idx,
					coords: [factor * e.point.x, factor * e.point.y, height]
				});

				mapGL.gl.bindFramebuffer(mapGL.gl.FRAMEBUFFER, mapGL.fb);
				mapGL.gl.readPixels(factor * e.point.x, mapGL.gl.drawingBufferHeight - factor * e.point.y, 1, 1, mapGL.gl.RGBA, mapGL.gl.UNSIGNED_BYTE, pixels);
				updateCanvas(pixels);
			}
		}
	}

	//handle position from other map
	function readValue(e) {
		if ($INT_STATE === READING && !$ANIMATING) {
			//draw circle
			if (OverlayCanvas) OverlayCanvas.drawCircle(e);

			if (mapGL) {
				//passed height as third element
				$$invalidate(10, height = e[2]);

				mapGL.gl.bindFramebuffer(mapGL.gl.FRAMEBUFFER, mapGL.fb);
				const factor = window.devicePixelRatio || 1;
				mapGL.gl.readPixels(e[0], mapGL.gl.drawingBufferHeight - e[1], 1, 1, mapGL.gl.RGBA, mapGL.gl.UNSIGNED_BYTE, pixels);
				updateCanvas(pixels);
			}
		}
	}

	function onMouseClick(e) {
		if (STATUS === READING) {
			if (mapGL) {
				mapGL.gl.bindFramebuffer(mapGL.gl.FRAMEBUFFER, mapGL.fb);

				//drawingBufferHeight dependent on pixelResolution
				//TODO: figure out pixel resolution, create program for 
				mapGL.gl.readPixels(2.0 * e.point.x, mapGL.gl.drawingBufferHeight - 2.0 * e.point.y, 1, 1, mapGL.gl.RGBA, mapGL.gl.UNSIGNED_BYTE, pixels);

				updateCanvas(pixels);
			}
		}
	}

	function onMouseUp(e) {
		if (STATUS === DRAWING) {
			STATUS = DONE;
			if ($INT_STATE === MODE3D) finish([start, mousePos(e)]);
			if ($INT_STATE === CROSS) finishLine([start, mousePos(e)]);
		}
	}

	//this fires when map is added, so can sync layers shown in here
	function toggleInteraction() {
		if (!$map[idx]) return;

		//enable drawing of box for 3d
		if ($INT_STATE === MODE3D) {
			$map[idx].getCanvas().style.cursor = 'crosshair';
			$map[idx].dragPan.disable();
			removeLine();

			if ($box) {
				setData($box);
			}

			container.addEventListener('mousedown', containerMouseDown, true);
			container.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		}

		if ($INT_STATE === CROSS) {
			$map[idx].getCanvas().style.cursor = 'crosshair';
			$map[idx].dragPan.disable();
			removeBox();

			if ($line) {
				setData($line);
			}

			container.addEventListener('mousedown', containerMouseDown, true);
			container.addEventListener('mousemove', onMouseMove);
			document.addEventListener('mouseup', onMouseUp);
		}

		if ($INT_STATE === READING) {
			$map[idx].getCanvas().style.cursor = '';
			removeBox();
			removeLine();
			$map[idx].dragPan.enable();

			// $map[idx].off('mousedown', onMouseDown);
			// $map[idx].off('mouseup', onMouseUp);
			container.removeEventListener('mousedown', containerMouseDown);

			container.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		}

		addAndShowTrending();
		addAndShowStatements();

		//if ($ALERTS) {
		addAndShowAlerts();

		//}
		addAndShowLightning();

		if ($SITES) {
			addAndShow();
		}
	}

	function showHide(layer) {
		if (!$map[idx]) return;
		const layerList = $map[idx].getStyle().layers.map(x => x.id);

		if (layer === "sites" && layerList.indexOf('sites') > -1) {
			if ($SITES) {
				$map[idx].setLayoutProperty('sites', 'visibility', 'visible');
			} else {
				$map[idx].setLayoutProperty('sites', 'visibility', 'none');
			}
		}

		if (layer === "lightning" && layerList.indexOf('lightning') > -1) {
			if ($LIGHTNING) {
				$map[idx].setLayoutProperty('lightning', 'visibility', 'visible');
				$map[idx].setLayoutProperty('lightning-blur', 'visibility', 'visible');
			} else {
				$map[idx].setLayoutProperty('lightning', 'visibility', 'none');
				$map[idx].setLayoutProperty('lightning-blur', 'visibility', 'none');
			}
		}

		if (layer === "alerts" && layerList.indexOf('alerts') > -1) {
			if ($ALERTS) {
				$map[idx].setLayoutProperty('alerts-background', 'visibility', 'visible');
				$map[idx].setLayoutProperty('alerts', 'visibility', 'visible');
				$map[idx].setLayoutProperty('alerts-fill', 'visibility', 'visible');
			} else {
				$map[idx].setLayoutProperty('alerts-background', 'visibility', 'none');
				$map[idx].setLayoutProperty('alerts', 'visibility', 'none');
				$map[idx].setLayoutProperty('alerts-fill', 'visibility', 'none');
			}
		}

		if (layer === "trending" && layerList.indexOf('trending') > -1) {
			if ($TRENDING) {
				$map[idx].setLayoutProperty('trending', 'visibility', 'visible');
			} else {
				$map[idx].setLayoutProperty('trending', 'visibility', 'none');
			}
		}

		if (layer === "sws" && layerList.indexOf('sws') > -1) {
			if ($STATEMENTS) {
				$map[idx].setLayoutProperty('sws-background', 'visibility', 'visible');
				$map[idx].setLayoutProperty('sws', 'visibility', 'visible');
				$map[idx].setLayoutProperty('sws-fill', 'visibility', 'visible');
			} else {
				$map[idx].setLayoutProperty('sws-background', 'visibility', 'none');
				$map[idx].setLayoutProperty('sws', 'visibility', 'none');
				$map[idx].setLayoutProperty('sws-fill', 'visibility', 'none');
			}
		}
	}

	function updateColorTable() {
		if (mapGL && $colorTables != 'unsupported') {
			//console.log("inside map", $colorTables);
			mapGL.updateColorTable();

			repaint();
		}
	}

	function updateOpacity() {
		if (mapGL) {
			mapGL.updateOpacity();
			repaint();
		}
	}

	function splitResize() {
		$map[idx]?.resize();
		if (mapGL) handleResize(mapGL);
	}

	function glFactory(gl, layertype) {
		const _this = this;
		const mapGL = gl;
		this.layerType = layertype;
		let floatArray;

		const setType = function (type) {
			_this.layerType = type;
			floatArray = new Float32Array([]);
			attachColor();
		};

		const clear = function () {
			floatArray = new Float32Array([]);
		};

		let fb;

		const render = function (matrix) {
			if (floatArray === undefined || floatArray.length == 0) return;
			mapGL.flush();

			//how to remove vertices from position buffer
			const offset = 0;

			const primitiveType = mapGL.TRIANGLES;
			mapGL.useProgram(this.programFramebuffer);

			//calculate matrices
			mapGL.uniformMatrix4fv(this.matrixLocationFramebuffer, false, matrix);

			//mapGL.uniform1i(this.textureLocation,0);
			// render to our targetTexture by binding the framebuffer
			mapGL.bindFramebuffer(mapGL.FRAMEBUFFER, fb);

			mapGL.clearColor(0, 0, 0, 0); // clear to transparent black
			mapGL.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

			//render to framebuffer if not animating
			if (!$ANIMATING) {
				mapGL.drawArrays(primitiveType, offset, floatArray.length / 3);
			}

			mapGL.flush();

			// render to the canvas
			mapGL.bindFramebuffer(mapGL.FRAMEBUFFER, null);

			// //use program
			mapGL.useProgram(this.program);

			//calculate matrices
			mapGL.uniformMatrix4fv(this.matrixLocation, false, matrix);

			mapGL.enable(mapGL.BLEND);
			mapGL.blendFunc(mapGL.SRC_ALPHA, mapGL.ONE_MINUS_SRC_ALPHA);
			mapGL.drawArrays(primitiveType, offset, floatArray.length / 3);
		};

		function attachColor() {
			//use program
			mapGL.useProgram(_this.program);

			mapGL.activeTexture(gl.TEXTURE7);
			mapGL.bindTexture(mapGL.TEXTURE_2D, textures[_this.layerType]);
			mapGL.texImage2D(mapGL.TEXTURE_2D, 0, mapGL.RGBA, mapGL.RGBA, mapGL.UNSIGNED_BYTE, imageData[_this.layerType]);
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_S, mapGL.CLAMP_TO_EDGE);
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_T, mapGL.CLAMP_TO_EDGE);
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_MIN_FILTER, mapGL.LINEAR);
			mapGL.uniform1i(_this.textureLocation, 7);

			if (_this.layerType === REFLECTIVITY) {
				let minVal;
				let maxVal;

				if ($colorTables != 'unsupported') {
					const minMax = $colorTables.br.all[$colorTables.br.current].minMax;
					minVal = minMax[0];
					maxVal = minMax[1];
				} else {
					minVal = 0;
					maxVal = 80;
				}

				//min and max values need to be variable based on colorbar CHANGE
				mapGL.uniform1f(_this.minLocation, minVal);

				mapGL.uniform1f(_this.maxLocation, maxVal);
			}

			if (_this.layerType === CC) {
				mapGL.uniform1f(_this.minLocation, 0.2);
				mapGL.uniform1f(_this.maxLocation, 1.05);
			}

			if (_this.layerType === PHI) {
				mapGL.uniform1f(_this.minLocation, -2.0);
				mapGL.uniform1f(_this.maxLocation, 10.0);
			}

			if (_this.layerType === ZDR) {
				mapGL.uniform1f(_this.minLocation, -8.0);
				mapGL.uniform1f(_this.maxLocation, 8.0);
			}

			if (_this.layerType === SW) {
				mapGL.uniform1f(_this.minLocation, 0);
				mapGL.uniform1f(_this.maxLocation, 60);
			}

			if (_this.layerType === VELOCITY) {
				mapGL.uniform1f(_this.minLocation, vmin);
				mapGL.uniform1f(_this.maxLocation, vmax);
			}

			if (_this.layerType === VELOCITY_DEALIASED) {
				mapGL.uniform1f(_this.minLocation, vmin);
				mapGL.uniform1f(_this.maxLocation, vmax);
			}

			mapGL.useProgram(_this.programFramebuffer);

			if (_this.layerType === REFLECTIVITY) {
				let minVal;
				let maxVal;

				// if ($colorTables != 'unsupported') {
				//     const minMax = $colorTables.all[$colorTables.current].minMax;
				//     minVal = minMax[0];
				//     maxVal = minMax[1];
				// } else {
				minVal = -30;

				maxVal = 80;

				// }
				mapGL.uniform1f(_this.minLocationFramebuffer, minVal);

				mapGL.uniform1f(_this.maxLocationFramebuffer, maxVal);
			}

			if (_this.layerType === CC) {
				mapGL.uniform1f(_this.minLocationFramebuffer, 0.2);
				mapGL.uniform1f(_this.maxLocationFramebuffer, 1.05);
			}

			if (_this.layerType === PHI) {
				mapGL.uniform1f(_this.minLocationFramebuffer, -2.0);
				mapGL.uniform1f(_this.maxLocationFramebuffer, 10.0);
			}

			if (_this.layerType === ZDR) {
				mapGL.uniform1f(_this.minLocationFramebuffer, -8.0);
				mapGL.uniform1f(_this.maxLocationFramebuffer, 8.0);
			}

			if (_this.layerType === SW) {
				mapGL.uniform1f(_this.minLocationFramebuffer, 0);
				mapGL.uniform1f(_this.maxLocationFramebuffer, 60);
			}

			if (_this.layerType === VELOCITY) {
				mapGL.uniform1f(_this.minLocationFramebuffer, vmin);
				mapGL.uniform1f(_this.maxLocationFramebuffer, vmax);
			}

			if (_this.layerType === VELOCITY_DEALIASED) {
				mapGL.uniform1f(_this.minLocationFramebuffer, vmin * 2.0);
				mapGL.uniform1f(_this.maxLocationFramebuffer, vmax * 2.0);
			}
		}

		function createFrameBuffer() {
			const targetTextureWidth = mapGL.drawingBufferWidth;
			const targetTextureHeight = mapGL.drawingBufferHeight;
			const targetTexture = mapGL.createTexture();
			mapGL.activeTexture(gl.TEXTURE0);
			mapGL.bindTexture(mapGL.TEXTURE_2D, targetTexture);

			// define size and format of level 0
			const level = 0;

			const internalFormat = mapGL.RGBA;
			const border = 0;
			const format = mapGL.RGBA;
			const type = mapGL.UNSIGNED_BYTE;
			const data = null;
			mapGL.texImage2D(mapGL.TEXTURE_2D, level, internalFormat, targetTextureWidth, targetTextureHeight, border, format, type, data);

			// set the filtering so we don't need mips
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_MIN_FILTER, mapGL.NEAREST);

			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_S, mapGL.CLAMP_TO_EDGE);
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_T, mapGL.CLAMP_TO_EDGE);

			// Create and bind the framebuffer
			fb = mapGL.createFramebuffer();

			mapGL.bindFramebuffer(mapGL.FRAMEBUFFER, fb);

			// attach the texture as the first color attachment
			const attachmentPoint = mapGL.COLOR_ATTACHMENT0;

			mapGL.framebufferTexture2D(mapGL.FRAMEBUFFER, attachmentPoint, mapGL.TEXTURE_2D, targetTexture, level);
		}

		const updateOpacity = function () {
			mapGL.useProgram(_this.program);
			mapGL.uniform1f(_this.opacityLocation, $dataSettings.opacity);
		};

		//CHANGE to use knowledge of what field was updated to change texture
		const updateColorTable = function () {
			//console.log(_this.layerType);
			//console.log("inside map", $colorTables)
			//if (_this.layerType === REFLECTIVITY) {
			//console.log("changign color")
			const returned = createLocalTexture(gl, $colorTables.br.all[$colorTables.br.current]);

			imageData[REFLECTIVITY] = returned.imageData;
			textures[REFLECTIVITY] = returned.texture;

			//actually update if current
			if (_this.layerType === REFLECTIVITY) {
				attachColor();
			}
		}; // imageData[layertype] = returned.imageData;
		// textures[layertype] = returned.texture;
		//use program
		//mapGL.useProgram(_this.program);

		//min and max values need to be variable based on colorbar
		//mapGL.uniform1f(_this.minLocation, $colorTables.br.all[$colorTables.br.current].minMax[0]);
		//mapGL.uniform1f(_this.maxLocation, $colorTables.br.all[$colorTables.br.current].minMax[1]);
		//}
		const updateData = function () {
			floatArray = $vertexData[$selections[idx].file][$selections[idx].type][$selections[idx].elevation];
			if (floatArray === undefined) return;
			mapGL.bindBuffer(mapGL.ARRAY_BUFFER, _this.positionBuffer);

			//subData to make uploading faster
			mapGL.bufferSubData(mapGL.ARRAY_BUFFER, 0, floatArray);
		};

		const createSubBuffer = function () {
			const size = 3;
			const type = mapGL.FLOAT;
			const normalize = false;
			const stride = 0;
			const offset = 0;
			mapGL.bindBuffer(mapGL.ARRAY_BUFFER, _this.positionBuffer);

			//create bufferData big enough to hold whatever vertices we'll throw at it
			mapGL.bufferData(mapGL.ARRAY_BUFFER, 100000000, mapGL.DYNAMIC_DRAW);

			mapGL.enableVertexAttribArray(_this.positionLocation);
			mapGL.vertexAttribPointer(_this.positionLocation, size, type, normalize, stride, offset);
		};

		const updateFrameBuffer = function () {
			//update target texture for framebuffer
			const targetTextureWidth = mapGL.drawingBufferWidth;

			const targetTextureHeight = mapGL.drawingBufferHeight;
			const targetTexture = mapGL.createTexture();
			mapGL.activeTexture(gl.TEXTURE0);
			mapGL.bindTexture(mapGL.TEXTURE_2D, targetTexture);

			// define size and format of level 0
			const level = 0;

			const internalFormat = mapGL.RGBA;
			const border = 0;
			const format = mapGL.RGBA;
			const type = mapGL.UNSIGNED_BYTE;
			const data = null;
			mapGL.texImage2D(mapGL.TEXTURE_2D, level, internalFormat, targetTextureWidth, targetTextureHeight, border, format, type, data);

			// set the filtering so we don't need mips
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_MIN_FILTER, mapGL.NEAREST);

			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_S, mapGL.CLAMP_TO_EDGE);
			mapGL.texParameteri(mapGL.TEXTURE_2D, mapGL.TEXTURE_WRAP_T, mapGL.CLAMP_TO_EDGE);
			mapGL.bindFramebuffer(mapGL.FRAMEBUFFER, fb);

			// attach the texture as the first color attachment
			const attachmentPoint = mapGL.COLOR_ATTACHMENT0;

			mapGL.framebufferTexture2D(mapGL.FRAMEBUFFER, attachmentPoint, mapGL.TEXTURE_2D, targetTexture, level);
		};

		createFrameBuffer();
		createSubBuffer();
		updateOpacity();

		return {
			render,
			updateData,
			createFrameBuffer,
			fb,
			gl: mapGL,
			setType,
			clear,
			attachColor,
			updateOpacity,
			updateFrameBuffer,
			updateColorTable
		};
	}

	function addLayer(layertype, vertexSource, fragmentSource, fragmentSourceFramebuffer) {
		return {
			id: "custom-layer",
			type: "custom",
			minzoom: 0,
			maxzoom: 18,
			onAdd(map, gl) {
				let returned; //end onAdd

				//CHANGE weak right now. It creates a texture that is later changed once
				//loaded from indexeddb
				if (layertype === REFLECTIVITY && $colorTables != 'unsupported') {
					returned = createLocalTexture(gl, $colorTables.br.all[$colorTables.br.current]);
				} else {
					returned = createTexture(gl, layertype);
				}

				imageData[layertype] = returned.imageData;
				textures[layertype] = returned.texture;
				var ext = gl.getExtension('OES_element_index_uint');
				var vertexShader = gl.createShader(gl.VERTEX_SHADER);
				gl.shaderSource(vertexShader, vertexSource);
				gl.compileShader(vertexShader);

				//var compilationLog = gl.getShaderInfoLog(vertexShader);
				//console.log('Shader compiler log: ' + compilationLog);
				var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);

				gl.shaderSource(fragmentShader, fragmentSource);
				gl.compileShader(fragmentShader);
				var fragmentShaderFramebuffer = gl.createShader(gl.FRAGMENT_SHADER);
				gl.shaderSource(fragmentShaderFramebuffer, fragmentSourceFramebuffer);
				gl.compileShader(fragmentShaderFramebuffer);

				//var compilationLog = gl.getShaderInfoLog(fragmentShaderFramebuffer);
				//console.log('Shader compiler log: ' + compilationLog);
				this.program = gl.createProgram();

				gl.attachShader(this.program, vertexShader);
				gl.attachShader(this.program, fragmentShader);
				gl.linkProgram(this.program);
				this.matrixLocation = gl.getUniformLocation(this.program, "u_matrix");
				this.minLocation = gl.getUniformLocation(this.program, "u_min");
				this.maxLocation = gl.getUniformLocation(this.program, "u_max");
				this.positionLocation = gl.getAttribLocation(this.program, "aPosition");
				this.colorLocation = gl.getAttribLocation(this.program, "aColor");
				this.textureLocation = gl.getUniformLocation(this.program, "u_texture");
				this.opacityLocation = gl.getUniformLocation(this.program, "u_opacity");
				this.programFramebuffer = gl.createProgram();
				gl.attachShader(this.programFramebuffer, vertexShader);
				gl.attachShader(this.programFramebuffer, fragmentShaderFramebuffer);
				gl.linkProgram(this.programFramebuffer);
				this.matrixLocationFramebuffer = gl.getUniformLocation(this.programFramebuffer, "u_matrix");
				this.minLocationFramebuffer = gl.getUniformLocation(this.programFramebuffer, "u_min");
				this.maxLocationFramebuffer = gl.getUniformLocation(this.programFramebuffer, "u_max");

				//data buffers
				this.positionBuffer = gl.createBuffer();

				this.indexBuffer = gl.createBuffer();
				this.colorBuffer = gl.createBuffer();
				mapGL = glFactory.apply(this, [gl, layertype]);
				mapGL.attachColor();
			}, //end onAdd
			render(gl, matrix) {
				mapGL.render.apply(this, [matrix]); //end render
			}, //end render
			
		};
	}

	function addSourcesAndLayers() {
		//const source = $map[idx].getSource("lightning");
		//console.log($map[idx].getStyle().layers)
		const layers = $map[idx].getStyle().layers;

		// Find the index of the first symbol layer in the map style.
		let firstSymbolId;

		for (const layer of layers) {
			if (layer.type === 'symbol') {
				firstSymbolId = layer.id;
				break;
			}
		}

		if (!beforeLayer || $map[idx].getLayer(beforeLayer) === undefined) beforeLayer = firstSymbolId;
		set_store_value(STYLE, $STYLE.layer = beforeLayer, $STYLE);
		const layerList = $map[idx].getStyle().layers.map(x => x.id);

		//console.log("oayerlsit", layerList)
		//add source for box drawing
		if (!$map[idx].getSource("box")) {
			$map[idx].addSource('box', {
				'type': 'geojson',
				'data': {
					'type': 'Feature',
					'geometry': { 'type': 'Polygon', 'coordinates': [] }
				}
			});
		}

		if (!$map[idx].getSource("line")) {
			$map[idx].addSource('line', {
				'type': 'geojson',
				'data': {
					'type': 'Feature',
					'geometry': { 'type': 'LineString', 'coordinates': [] }
				}
			});
		}

		//add radar layer
		if ($map[idx].getLayer('custom-layer') === undefined) {
			//console.log("adding custom-layer", style)
			$map[idx].addLayer(addLayer($map[idx].field, shaders.vsSource, shaders.fsSource, shaders.fsSourceFramebuffer), beforeLayer);
		}

		if (layerList.indexOf('box') < 0) {
			$map[idx].addLayer({
				'id': 'box',
				'type': 'fill',
				'source': 'box',
				'layout': {},
				'paint': {
					'fill-color': "#fff",
					'fill-opacity': 0.075
				}
			});
		}

		if (layerList.indexOf('box-outline') < 0) {
			$map[idx].addLayer({
				'id': 'box-outline',
				'type': 'line',
				'source': 'box',
				'layout': {},
				'paint': { 'line-color': "#fff", 'line-width': 2 }
			});
		}

		if (layerList.indexOf('cross-line') < 0) {
			$map[idx].addLayer({
				'id': 'cross-line',
				'type': 'line',
				'source': 'line',
				'layout': {},
				'paint': { 'line-color': '#0080ff', 'line-width': 2 }
			});
		}
	}

	let resizeTimeout;

	function initializeOverlay() {
		let ctx;
		let sscale;

		function resize() {
			const logicalWidth = container.offsetWidth;
			const logicalHeight = container.offsetHeight;
			$$invalidate(6, overlayCanvas.width = logicalWidth, overlayCanvas);
			$$invalidate(6, overlayCanvas.height = logicalHeight, overlayCanvas);
			ctx = overlayCanvas.getContext('2d');
			sscale = window.devicePixelRatio || 1;
			$$invalidate(6, overlayCanvas.width = overlayCanvas.width * sscale, overlayCanvas);
			$$invalidate(6, overlayCanvas.height = overlayCanvas.height * sscale, overlayCanvas);
			$$invalidate(6, overlayCanvas.style.width = `${logicalWidth}px`, overlayCanvas);
			$$invalidate(6, overlayCanvas.style.height = `${logicalHeight}px`, overlayCanvas);
			ctx.scale(sscale, sscale);
			ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
		}

		function drawCircle(e) {
			clear();
			ctx.lineWidth = 3;
			ctx.strokeStyle = "black";
			ctx.beginPath();
			ctx.arc(e[0] / sscale, e[1] / sscale, 6, 0, 2 * Math.PI);
			ctx.stroke();
			ctx.lineWidth = 2;
			ctx.strokeStyle = "white";
			ctx.beginPath();
			ctx.arc(e[0] / sscale, e[1] / sscale, 6, 0, 2 * Math.PI);
			ctx.stroke();
		}

		function clear() {
			ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
		}

		resize();
		return { drawCircle, clear, resize };
	}

	function handleResize(mapGL) {
		clearTimeout(resizeTimeout);

		resizeTimeout = setTimeout(
			function () {
				if (mapGL) mapGL.updateFrameBuffer();
				OverlayCanvas?.resize();
				repaint();
			},
			300
		);
	}

	onMount(async () => {
		let masterCenter;
		let masterZoom;

		if ($map[0] && $map[0]._loaded) {
			//make sure second map is at same position as first
			masterZoom = $map[0].getZoom();

			masterCenter = $map[0].getCenter();
		} else {
			masterZoom = 3.5;
			masterCenter = [-95, 37.5];
		}

		set_store_value(
			map,
			$map[idx] = new mapboxgl.Map({
					container,
					style,
					center: masterCenter,
					zoom: masterZoom,
					maxZoom: 18,
					projection: 'mercator'
				}),
			$map
		);

		//add a function to set loading state on map object
		set_store_value(map, $map[idx].setLoading = setLoading, $map);

		set_store_value(map, $map[idx].updateField = updateField, $map);

		//framebuffer needs to resize when map resizes
		$map[idx].on('resize', function () {
			if (mapGL) handleResize(mapGL);
		});

		$map[idx].on('load', async function () {
			await promise;

			//default field shown on each map
			if (idx === 0) {
				updateField(REFLECTIVITY);
			} else {
				if ($DEALIAS_STATE === DEALIASED) {
					updateField(VELOCITY_DEALIASED);
				} else {
					updateField(VELOCITY);
				}
			}

			$map[idx].keyboard.disable();
			addSourcesAndLayers();

			//trigger load data if a scan has been selected
			if ($selections[0].scan) updateInstance.logStore($selections[0].scan);

			OverlayCanvas = initializeOverlay();

			if ($map[0]._loaded && $map[1] && $map[1]._loaded) {
				syncMaps($map[0], $map[1]);
			}

			const textA = document.createElement('div');
			textA.className = 'crosslabel';
			textA.textContent = "A";
			const textB = document.createElement('div');
			textB.className = 'crosslabel';
			textB.textContent = "B";
			markers['A'] = new mapboxgl.Marker(textA);
			markers['B'] = new mapboxgl.Marker(textB);
			const field = $map[idx].field;

			if (field && mapGL) {
				mapGL.setType(field);
			}

			if ($selections[idx] && $vertexData[$selections[idx].file] && mapGL) {
				mapGL.updateData();
			}

			toggleInteraction();
		});

		//have this for switching styles
		$map[idx].on('style.load', async function () {
			await promise;

			//only those functions that need the style
			//this loads before the other load event
			const radarSelected = createIcon("selected");

			const radarUnselected = createIcon("unselected");
			$map[idx].addImage("selected", radarSelected);
			$map[idx].addImage("unselected", radarUnselected);

			//only execute this on later style changes
			if (changingStyle) {
				const field = $map[idx].field;

				if (field && mapGL) {
					mapGL.setType(field);
				}

				addSourcesAndLayers();

				if ($selections[idx] && $vertexData[$selections[idx].file] && mapGL) {
					mapGL.updateData();
				}

				toggleInteraction();
				changingStyle = false;
			}
		});

		$map[idx].on('mousemove', onMouseMove);

		container.addEventListener("mouseleave", event => {
			$$invalidate(3, over = 0);

			//send event to other map
			dispatch('message', { value: "showHide", idx, over: 0 });
		});

		container.addEventListener("mouseenter", event => {
			if ($INT_STATE === READING && !$ANIMATING) {
				$$invalidate(3, over = 1);

				//don't show circle on map mousing over
				if (OverlayCanvas) OverlayCanvas.clear();

				//send event to other map
				dispatch('message', { value: "showHide", idx, over: 1 });
			}
		});
	});

	function decode(val, extent) {
		let min, max;

		if ($map[idx].field === VELOCITY_DEALIASED) {
			min = extent[0] * 2.0;
			max = extent[1] * 2.0;
		} else {
			min = extent[0];
			max = extent[1];
		}

		const scaled = val[0] / 255.0 + val[1] / 65025.0 + val[2] / 16581375.0;

		//clear color for framebuffer is 0,0,0,0, so 255 means value set
		if (val[3] === 255) {
			return scaled * (max - min) + min;
		} else {
			return undefined;
		}
	}

	function updateCanvas(val) {
		const d = decode(val, extentsDecode[$map[idx].field]);

		if (d === undefined) {
			$$invalidate(7, decoded = undefined);
			return;
		}

		if ($map[idx].field === CC) {
			$$invalidate(7, decoded = d.toFixed(2));
		} else if ($map[idx].field === PHI || $map[idx].field === ZDR) {
			$$invalidate(7, decoded = d.toFixed(1));
		} else {
			$$invalidate(7, decoded = (Math.round(d * 2) / 2).toFixed(1));
		}
	}

	function updateField(field) {
		if ((!(field in textures) || !textures[field]) && mapGL) {
			//const returned = createTexture(mapGL.gl, field);
			let returned;

			if (field === REFLECTIVITY && $colorTables != 'unsupported') {
				returned = createLocalTexture(mapGL.gl, $colorTables.br.all[$colorTables.br.current]);
			} else {
				returned = createTexture(mapGL.gl, field);
			}

			imageData[field] = returned.imageData;
			textures[field] = returned.texture;
		}

		set_store_value(map, $map[idx].field = field, $map);
		$$invalidate(8, selectedField = field);

		if (mapGL) {
			mapGL.setType(field);
		}
	}

	function changeStyle() {
		if ($STYLE.styleid != style) {
			changingStyle = true;
			const toSet = $STYLE.styleid;

			if (toSet != style) {
				beforeLayer = $STYLE.layer;
				style = toSet;
				$map[idx].setStyle(toSet);
			}
		}
	}

	function div0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			container = $$value;
			$$invalidate(4, container);
		});
	}

	function canvas0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			overlayCanvas = $$value;
			$$invalidate(6, overlayCanvas);
		});
	}

	function canvas1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			colorCanvas = $$value;
			$$invalidate(5, colorCanvas);
		});
	}

	$$self.$$set = $$props => {
		if ('show' in $$props) $$invalidate(0, show = $$props.show);
		if ('idx' in $$props) $$invalidate(1, idx = $$props.idx);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$alerts*/ 67108864) {
			$: ($alerts, updateAlerts());
		}

		if ($$self.$$.dirty[0] & /*$lightning*/ 268435456) {
			$: ($lightning, updateLightning());
		}

		if ($$self.$$.dirty[0] & /*$trendingData*/ 134217728) {
			$: ($trendingData, updateTrending());
		}

		if ($$self.$$.dirty[0] & /*$MAP_STATE*/ 33554432) {
			$: ($MAP_STATE, toggleShow());
		}

		if ($$self.$$.dirty[0] & /*$vertexData*/ 65536) {
			$: ($vertexData, repaint("vertex data updated"));
		}

		if ($$self.$$.dirty[0] & /*$selections*/ 32768) {
			$: ($selections, repaint("selections updated"));
		}

		if ($$self.$$.dirty[0] & /*$radarMetadata*/ 16777216) {
			$: ($radarMetadata, flyTo());
		}

		if ($$self.$$.dirty[0] & /*$INT_STATE*/ 16384) {
			$: ($INT_STATE, toggleInteraction());
		}

		if ($$self.$$.dirty[0] & /*$SITES*/ 8388608) {
			$: ($SITES, showHide('sites'));
		}

		if ($$self.$$.dirty[0] & /*$ALERTS*/ 4194304) {
			$: ($ALERTS, showHide('alerts'));
		}

		if ($$self.$$.dirty[0] & /*$STATEMENTS*/ 2097152) {
			$: ($STATEMENTS, showHide('sws'));
		}

		if ($$self.$$.dirty[0] & /*$TRENDING*/ 1048576) {
			$: ($TRENDING, showHide('trending'));
		}

		if ($$self.$$.dirty[0] & /*$LIGHTNING*/ 524288) {
			$: ($LIGHTNING, showHide('lightning'));
		}

		if ($$self.$$.dirty[0] & /*$STYLE*/ 4096) {
			$: ($STYLE, changeStyle());
		}

		if ($$self.$$.dirty[0] & /*$dataSettings*/ 131072) {
			$: ($dataSettings, updateOpacity());
		}

		if ($$self.$$.dirty[0] & /*$colorTables*/ 8192) {
			$: ($colorTables, updateColorTable());
		}

		if ($$self.$$.dirty[0] & /*$SPLIT_STATE*/ 262144) {
			$: ($SPLIT_STATE, splitResize());
		}
	};

	return [
		show,
		idx,
		handleMessage,
		over,
		container,
		colorCanvas,
		overlayCanvas,
		decoded,
		selectedField,
		loading,
		height,
		canvases,
		$STYLE,
		$colorTables,
		$INT_STATE,
		$selections,
		$vertexData,
		$dataSettings,
		$SPLIT_STATE,
		$LIGHTNING,
		$TRENDING,
		$STATEMENTS,
		$ALERTS,
		$SITES,
		$radarMetadata,
		$MAP_STATE,
		$alerts,
		$trendingData,
		$lightning,
		div0_binding,
		canvas0_binding,
		canvas1_binding
	];
}

class Map extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { show: 0, idx: 1, handleMessage: 2 }, null, [-1, -1, -1, -1]);
	}

	get handleMessage() {
		return this.$$.ctx[2];
	}
}

export default Map;