import TinyQueue from '../_snowpack/pkg/tinyqueue.js';
import * as pako from '../_snowpack/pkg/pako.js';
import { get } from '../_snowpack/pkg/svelte/store.js';
import { error, worker, scans } from './store.js';

//instance to hold all file work, historical and live
function Files() {

    //queue to hold files to load, fifo comparator
    let queue = new TinyQueue([], function(a,b) {
        return b.time - a.time;
    });

    let count = 0;
    let numLoaded = 0;
    let b;
    let hovering;

    function emptyQueue() {
       
        while (queue.length) {
            const reader = new FileReader();
            let file = queue.pop();
           
            reader.onload = function(event) {
               
                const buffer = event.target.result;
                let firstBytes = new Uint8Array(buffer);

                try {
                    const result = pako.inflate(firstBytes);
                    firstBytes = result;
                } catch (err) {
                    //don't set if didn't work
                }

                let toMatch = '';
                for (let i=0; i<6; i++) {
                    toMatch = toMatch+String.fromCharCode(firstBytes[i])
                }
                
                if (toMatch != 'AR2V00' && toMatch != "ARCHIV") {
                    count -= 1;
                    if (toMatch === "ARCHIV") {
                        
                        error.update(d => {
                            d.error = true;
                            d.message = 'The application cannot yet read files in an old format like '+file.name+'.';
                            d.header = 'Old format';
                            return d;
                        })

                    } else {

                        error.update(d => {
                            d.error = true;
                            d.message = 'The file '+file.name+' could not be read due to an unexpected format.';
                            d.header = 'Unexpected format';
                            return d;
                        })
                    }
                    
                    return;
                }
              
                // Transfer the underlying buffer to a worker
                get(worker).postMessage({
                    "message":"initialize",
                    fileName:file.name,
                    buffer:firstBytes.buffer
                }, [firstBytes.buffer]);
            }

            reader.readAsArrayBuffer(file);
        }
    }

    function handleDragEnter(e) {
        e.preventDefault();
        console.log(hovering)
        hovering = true;
        console.log(hovering)
        b.style.pointerEvents = 'none';
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleDragLeave(e) {
        e.preventDefault();
        hovering = false;
        b.style.pointerEvents = '';
    }

    function handleDragDrop(e) {
        b.style.pointerEvents = '';
        e.preventDefault();
        hovering=false;
        
        if (e.dataTransfer.items) {

            let toPass = [];
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                if (e.dataTransfer.items[i].kind === 'file') {
                    let file = e.dataTransfer.items[i].getAsFile();
                    file.time = Date.now();
                    toPass.push(file);
                }
            }

            fileWork(toPass);

        }

    }

    function fileWork(files) {
        let filesLoaded = 0;
        for (let i=0; i<get(scans).length; i++) {
            if (get(scans)[i]) {
                filesLoaded++
            }
        }
        const loadedNames = get(scans).filter(x=>x!=undefined).map(x=>x.fileName);
        let forQueue = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            if (loadedNames.indexOf(file.name) >= 0) {
               
                error.update(d => {
                    d.error = true;
                    d.message = 'A file already exists with the name '+file.name;
                    d.header = 'Duplicate file';
                    return d;
                })

                return;
            };
            forQueue.push(file);
        }

        //don't even add to queue if too many files
        if (count + files.length > 5 || files.length + filesLoaded > 5) {

            error.update(d => {
                d.error = true;
                d.message = 'The application cannot load more than 5 files. Remove a file using the File view before loading another.';
                d.header = 'Too many files';
                return d;
            })

            return;
        }
        
        //add to queue
        for (let i = 0; i < forQueue.length; i++) {
            queue.push(forQueue[i]);
        }
        count = count + forQueue.length;
        //empty queue
        emptyQueue();

    }

    function updatePercent() {
        if (!count) return;
        if (get(scans).finished === 1) {
            percent = 1;
            numLoaded += 1;

        }
        if (numLoaded === count) {
           
                percent = -1;
             
                numLoaded = 0;
                count = 0;
          
        } else {
            if (get(scans)[get(scans).loading]) {
                
                let localLoading = get(scans).loading;
               
                let localPercent =  get(scans)[localLoading].percent;
               
                if (localPercent != undefined) percent = localPercent;
                    
            } 
        }     
    }

    function handleFiles() {
        const fileList = this.files;
       
        let toPass = [];
        for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
            const file = fileList[i];
            file.time = Date.now();
            toPass.push(file);
        }
        fileWork(toPass);
    }

    return {
        set b(value) {
            b = value
        },
        get b() {
            return b
        },
        set hovering(value) {
            hovering = value
        },
        get hovering() {
            return hovering
        },
        set count(value) {
            count = value
        },
        get count() {
            return count
        },
        set numLoaded(value) {
            numLoaded = value
        },
        get numLoaded() {
            return numLoaded
        },
        handleDragDrop,
        handleDragEnter,
        handleDragLeave,
        handleDragOver,
        updatePercent,
        handleFiles
    }

}

export { Files }