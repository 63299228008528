import './Bar.svelte.css';
/* src/Bar.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	selections,
	scans,
	DEALIAS_STATE,
	INT_STATE,
	MAP_STATE,
	LOADING_3D,
	texture_3d,
	currentFile,
	MODE_STATE,
	socket,
	fileClass,
	ALERTS,
	SITES,
	TRENDING,
	trending,
	auth,
	LOGGED_IN,
	modal,
	queue,
	STATEMENTS,
	SETTINGS_STATE,
	LIGHTNING
} from './store.js';

import {
	SINGLE,
	DOUBLE,
	SINGLE_HIDDEN,
	DEALIAS_OBJECT,
	INT_OBJECT,
	MODE_OBJECT
} from './enums.js';

import { createEventDispatcher } from '../_snowpack/pkg/svelte.js';
import About from './About.svelte.js';
import Appearance from './Appearance.svelte.js';

function create_if_block_6(ctx) {
	let button0;
	let t1;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			button0 = element("button");
			button0.textContent = "Alerts";
			t1 = space();
			button1 = element("button");
			button1.textContent = "Statements";
			attr(button0, "class", "layer-button svelte-1jufatp");
			toggle_class(button0, "layer-active", /*$ALERTS*/ ctx[7] ? true : false);
			attr(button1, "class", "layer-button svelte-1jufatp");
			toggle_class(button1, "layer-active", /*$STATEMENTS*/ ctx[6] ? true : false);
		},
		m(target, anchor) {
			insert(target, button0, anchor);
			insert(target, t1, anchor);
			insert(target, button1, anchor);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*handleAlerts*/ ctx[27]),
					listen(button1, "click", /*handleStatements*/ ctx[28])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$ALERTS*/ 128) {
				toggle_class(button0, "layer-active", /*$ALERTS*/ ctx[7] ? true : false);
			}

			if (dirty[0] & /*$STATEMENTS*/ 64) {
				toggle_class(button1, "layer-active", /*$STATEMENTS*/ ctx[6] ? true : false);
			}
		},
		d(detaching) {
			if (detaching) detach(button0);
			if (detaching) detach(t1);
			if (detaching) detach(button1);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (336:12) {#if $TRENDING}
function create_if_block_5(ctx) {
	let div4;

	return {
		c() {
			div4 = element("div");

			div4.innerHTML = `<div class="entry svelte-1jufatp" id="entry1"><span>Viewers</span> 
                    <div class="circle1 circle-transform svelte-1jufatp"></div></div> 
                <div class="entry svelte-1jufatp" id="entry2"><span>Trending</span> 
                    <div class="circle2 circle-transform svelte-1jufatp"></div></div>`;

			attr(div4, "class", "trending-container svelte-1jufatp");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
		},
		d(detaching) {
			if (detaching) detach(div4);
		}
	};
}

// (357:7) {#if !($LOGGED_IN)}
function create_if_block_4(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Log in";
			attr(button, "class", "main account svelte-1jufatp");
			toggle_class(button, "active", /*$SETTINGS_STATE*/ ctx[5] === "open" ? true : false);
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*handleLogin*/ ctx[31]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$SETTINGS_STATE*/ 32) {
				toggle_class(button, "active", /*$SETTINGS_STATE*/ ctx[5] === "open" ? true : false);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (362:8) {#if $LOGGED_IN}
function create_if_block_3(ctx) {
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Account";
			attr(button, "class", "main account svelte-1jufatp");
			toggle_class(button, "active", /*$SETTINGS_STATE*/ ctx[5] === "open" ? true : false);
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (!mounted) {
				dispose = listen(button, "click", /*handleSettings*/ ctx[30]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$SETTINGS_STATE*/ 32) {
				toggle_class(button, "active", /*$SETTINGS_STATE*/ ctx[5] === "open" ? true : false);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (367:8) {#if $SETTINGS_STATE === "open"}
function create_if_block_1(ctx) {
	let div2;
	let div0;
	let span0;
	let t0_value = (/*user*/ ctx[3] ? /*user*/ ctx[3].nickname : '') + "";
	let t0;
	let t1;
	let span1;
	let t2_value = (/*user*/ ctx[3] ? /*user*/ ctx[3].email : '') + "";
	let t2;
	let t3;
	let div1;
	let button0;
	let t5;
	let button1;
	let t7;
	let mounted;
	let dispose;
	let if_block = /*confirm*/ ctx[4] && create_if_block_2(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			span0 = element("span");
			t0 = text(t0_value);
			t1 = space();
			span1 = element("span");
			t2 = text(t2_value);
			t3 = space();
			div1 = element("div");
			button0 = element("button");
			button0.textContent = "Log Out";
			t5 = space();
			button1 = element("button");
			button1.textContent = "Delete Account";
			t7 = space();
			if (if_block) if_block.c();
			attr(span0, "class", "bold svelte-1jufatp");
			attr(span1, "class", "secondary svelte-1jufatp");
			attr(div0, "class", "row svelte-1jufatp");
			attr(button0, "class", "logout svelte-1jufatp");
			attr(button1, "class", "subtle svelte-1jufatp");
			attr(div1, "class", "acontainer svelte-1jufatp");
			attr(div2, "class", "settings-container svelte-1jufatp");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, span0);
			append(span0, t0);
			append(div0, t1);
			append(div0, span1);
			append(span1, t2);
			append(div2, t3);
			append(div2, div1);
			append(div1, button0);
			append(div1, t5);
			append(div1, button1);
			append(div2, t7);
			if (if_block) if_block.m(div2, null);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*handleLogout*/ ctx[29]),
					listen(button1, "click", /*handleConfirm*/ ctx[32])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*user*/ 8 && t0_value !== (t0_value = (/*user*/ ctx[3] ? /*user*/ ctx[3].nickname : '') + "")) set_data(t0, t0_value);
			if (dirty[0] & /*user*/ 8 && t2_value !== (t2_value = (/*user*/ ctx[3] ? /*user*/ ctx[3].email : '') + "")) set_data(t2, t2_value);

			if (/*confirm*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_2(ctx);
					if_block.c();
					if_block.m(div2, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (378:16) {#if confirm}
function create_if_block_2(ctx) {
	let div;
	let p;
	let t1;
	let button0;
	let t3;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			p = element("p");
			p.textContent = "Are you sure you want to delete your account?";
			t1 = space();
			button0 = element("button");
			button0.textContent = "Cancel";
			t3 = space();
			button1 = element("button");
			button1.textContent = "Delete";
			attr(p, "class", "suretext svelte-1jufatp");
			attr(button0, "class", "logout svelte-1jufatp");
			attr(button1, "class", "delete svelte-1jufatp");
			attr(div, "class", "sure svelte-1jufatp");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(div, t1);
			append(div, button0);
			append(div, t3);
			append(div, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*handleCancel*/ ctx[33]),
					listen(button1, "click", /*handleDelete*/ ctx[34])
				];

				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (390:8) {#if appearance}
function create_if_block(ctx) {
	let appearance_1;
	let current;
	appearance_1 = new Appearance({});

	return {
		c() {
			create_component(appearance_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(appearance_1, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(appearance_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(appearance_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(appearance_1, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let div1;
	let button0;
	let t1;
	let button1;
	let t3;
	let button2;
	let t5;
	let button3;
	let t7;
	let button4;
	let t9;
	let div0;
	let t10;
	let button5;
	let t12;
	let button6;
	let t14;
	let t15;
	let div2;
	let button7;
	let t17;
	let button8;
	let t19;
	let t20;
	let t21;
	let t22;
	let about_1;
	let t23;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$MODE_STATE*/ ctx[10] === /*LIVE*/ ctx[17] && create_if_block_6(ctx);
	let if_block1 = /*$TRENDING*/ ctx[8] && create_if_block_5(ctx);
	let if_block2 = !/*$LOGGED_IN*/ ctx[0] && create_if_block_4(ctx);
	let if_block3 = /*$LOGGED_IN*/ ctx[0] && create_if_block_3(ctx);
	let if_block4 = /*$SETTINGS_STATE*/ ctx[5] === "open" && create_if_block_1(ctx);
	about_1 = new About({ props: { show: /*about*/ ctx[1] } });
	let if_block5 = /*appearance*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			div3 = element("div");
			div1 = element("div");
			button0 = element("button");
			button0.textContent = "Dual Map";
			t1 = space();
			button1 = element("button");
			button1.textContent = "3D View";
			t3 = space();
			button2 = element("button");
			button2.textContent = "Cross Section";
			t5 = space();
			button3 = element("button");
			button3.textContent = "Dealias";
			t7 = space();
			button4 = element("button");
			button4.textContent = "Live Radar";
			t9 = space();
			div0 = element("div");
			if (if_block0) if_block0.c();
			t10 = space();
			button5 = element("button");
			button5.textContent = "Sites";
			t12 = space();
			button6 = element("button");
			button6.textContent = "Trending";
			t14 = space();
			if (if_block1) if_block1.c();
			t15 = space();
			div2 = element("div");
			button7 = element("button");
			button7.textContent = "Appearance";
			t17 = space();
			button8 = element("button");
			button8.textContent = "About";
			t19 = space();
			if (if_block2) if_block2.c();
			t20 = space();
			if (if_block3) if_block3.c();
			t21 = space();
			if (if_block4) if_block4.c();
			t22 = space();
			create_component(about_1.$$.fragment);
			t23 = space();
			if (if_block5) if_block5.c();
			attr(button0, "class", "main svelte-1jufatp");
			toggle_class(button0, "active", /*$MAP_STATE*/ ctx[13] === DOUBLE ? true : false);
			attr(button1, "class", "main svelte-1jufatp");

			toggle_class(button1, "active", /*$INT_STATE*/ ctx[12] === /*MODE3D*/ ctx[15]
			? true
			: false);

			attr(button2, "class", "main svelte-1jufatp");

			toggle_class(button2, "active", /*$INT_STATE*/ ctx[12] === /*CROSS*/ ctx[16]
			? true
			: false);

			attr(button3, "class", "main svelte-1jufatp");

			toggle_class(button3, "active", /*$DEALIAS_STATE*/ ctx[11] === /*DEALIASED*/ ctx[14]
			? true
			: false);

			attr(button4, "class", "main svelte-1jufatp");

			toggle_class(button4, "active", /*$MODE_STATE*/ ctx[10] === /*LIVE*/ ctx[17]
			? true
			: false);

			attr(button5, "class", "layer-button svelte-1jufatp");
			toggle_class(button5, "layer-active", /*$SITES*/ ctx[9] ? true : false);
			attr(button6, "class", "layer-button svelte-1jufatp");
			toggle_class(button6, "layer-active", /*$TRENDING*/ ctx[8] ? true : false);
			attr(div0, "class", "layer-container svelte-1jufatp");
			attr(div1, "class", "leftgroup");
			attr(button7, "class", "main svelte-1jufatp");
			toggle_class(button7, "active", /*appearance*/ ctx[2] ? true : false);
			attr(button8, "class", "main svelte-1jufatp");
			toggle_class(button8, "active", /*about*/ ctx[1] ? true : false);
			attr(div2, "class", "rightgroup");
			attr(div3, "class", "barContainer svelte-1jufatp");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div1);
			append(div1, button0);
			append(div1, t1);
			append(div1, button1);
			append(div1, t3);
			append(div1, button2);
			append(div1, t5);
			append(div1, button3);
			append(div1, t7);
			append(div1, button4);
			append(div1, t9);
			append(div1, div0);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t10);
			append(div0, button5);
			append(div0, t12);
			append(div0, button6);
			append(div0, t14);
			if (if_block1) if_block1.m(div0, null);
			append(div3, t15);
			append(div3, div2);
			append(div2, button7);
			append(div2, t17);
			append(div2, button8);
			append(div2, t19);
			if (if_block2) if_block2.m(div2, null);
			append(div2, t20);
			if (if_block3) if_block3.m(div2, null);
			append(div2, t21);
			if (if_block4) if_block4.m(div2, null);
			append(div2, t22);
			mount_component(about_1, div2, null);
			append(div2, t23);
			if (if_block5) if_block5.m(div2, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(button0, "click", /*handleClick*/ ctx[18]),
					listen(button1, "click", /*handle3d*/ ctx[19]),
					listen(button2, "click", /*handleCross*/ ctx[20]),
					listen(button3, "click", /*handleDealias*/ ctx[21]),
					listen(button4, "click", /*handleMode*/ ctx[24]),
					listen(button5, "click", /*handleSites*/ ctx[25]),
					listen(button6, "click", /*handleTrending*/ ctx[26]),
					listen(button7, "click", /*handleAppearance*/ ctx[23]),
					listen(button8, "click", /*handleAbout*/ ctx[22])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$MAP_STATE*/ 8192) {
				toggle_class(button0, "active", /*$MAP_STATE*/ ctx[13] === DOUBLE ? true : false);
			}

			if (dirty[0] & /*$INT_STATE, MODE3D*/ 36864) {
				toggle_class(button1, "active", /*$INT_STATE*/ ctx[12] === /*MODE3D*/ ctx[15]
				? true
				: false);
			}

			if (dirty[0] & /*$INT_STATE, CROSS*/ 69632) {
				toggle_class(button2, "active", /*$INT_STATE*/ ctx[12] === /*CROSS*/ ctx[16]
				? true
				: false);
			}

			if (dirty[0] & /*$DEALIAS_STATE, DEALIASED*/ 18432) {
				toggle_class(button3, "active", /*$DEALIAS_STATE*/ ctx[11] === /*DEALIASED*/ ctx[14]
				? true
				: false);
			}

			if (dirty[0] & /*$MODE_STATE, LIVE*/ 132096) {
				toggle_class(button4, "active", /*$MODE_STATE*/ ctx[10] === /*LIVE*/ ctx[17]
				? true
				: false);
			}

			if (/*$MODE_STATE*/ ctx[10] === /*LIVE*/ ctx[17]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_6(ctx);
					if_block0.c();
					if_block0.m(div0, t10);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty[0] & /*$SITES*/ 512) {
				toggle_class(button5, "layer-active", /*$SITES*/ ctx[9] ? true : false);
			}

			if (dirty[0] & /*$TRENDING*/ 256) {
				toggle_class(button6, "layer-active", /*$TRENDING*/ ctx[8] ? true : false);
			}

			if (/*$TRENDING*/ ctx[8]) {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block_5(ctx);
					if_block1.c();
					if_block1.m(div0, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty[0] & /*appearance*/ 4) {
				toggle_class(button7, "active", /*appearance*/ ctx[2] ? true : false);
			}

			if (dirty[0] & /*about*/ 2) {
				toggle_class(button8, "active", /*about*/ ctx[1] ? true : false);
			}

			if (!/*$LOGGED_IN*/ ctx[0]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_4(ctx);
					if_block2.c();
					if_block2.m(div2, t20);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*$LOGGED_IN*/ ctx[0]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_3(ctx);
					if_block3.c();
					if_block3.m(div2, t21);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (/*$SETTINGS_STATE*/ ctx[5] === "open") {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_1(ctx);
					if_block4.c();
					if_block4.m(div2, t22);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			const about_1_changes = {};
			if (dirty[0] & /*about*/ 2) about_1_changes.show = /*about*/ ctx[1];
			about_1.$set(about_1_changes);

			if (/*appearance*/ ctx[2]) {
				if (if_block5) {
					if (dirty[0] & /*appearance*/ 4) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(div2, null);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(about_1.$$.fragment, local);
			transition_in(if_block5);
			current = true;
		},
		o(local) {
			transition_out(about_1.$$.fragment, local);
			transition_out(if_block5);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			destroy_component(about_1);
			if (if_block5) if_block5.d();
			mounted = false;
			run_all(dispose);
		}
	};
}

let loggedIn = false;
let showing = false;

function instance($$self, $$props, $$invalidate) {
	let $LOGGED_IN;
	let $SETTINGS_STATE;
	let $LIGHTNING;
	let $STATEMENTS;
	let $ALERTS;
	let $TRENDING;
	let $SITES;
	let $MODE_STATE;
	let $DEALIAS_STATE;
	let $LOADING_3D;
	let $scans;
	let $selections;
	let $texture_3d;
	let $INT_STATE;
	let $currentFile;
	let $MAP_STATE;
	component_subscribe($$self, LOGGED_IN, $$value => $$invalidate(0, $LOGGED_IN = $$value));
	component_subscribe($$self, SETTINGS_STATE, $$value => $$invalidate(5, $SETTINGS_STATE = $$value));
	component_subscribe($$self, LIGHTNING, $$value => $$invalidate(35, $LIGHTNING = $$value));
	component_subscribe($$self, STATEMENTS, $$value => $$invalidate(6, $STATEMENTS = $$value));
	component_subscribe($$self, ALERTS, $$value => $$invalidate(7, $ALERTS = $$value));
	component_subscribe($$self, TRENDING, $$value => $$invalidate(8, $TRENDING = $$value));
	component_subscribe($$self, SITES, $$value => $$invalidate(9, $SITES = $$value));
	component_subscribe($$self, MODE_STATE, $$value => $$invalidate(10, $MODE_STATE = $$value));
	component_subscribe($$self, DEALIAS_STATE, $$value => $$invalidate(11, $DEALIAS_STATE = $$value));
	component_subscribe($$self, LOADING_3D, $$value => $$invalidate(36, $LOADING_3D = $$value));
	component_subscribe($$self, scans, $$value => $$invalidate(37, $scans = $$value));
	component_subscribe($$self, selections, $$value => $$invalidate(38, $selections = $$value));
	component_subscribe($$self, texture_3d, $$value => $$invalidate(39, $texture_3d = $$value));
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(12, $INT_STATE = $$value));
	component_subscribe($$self, currentFile, $$value => $$invalidate(40, $currentFile = $$value));
	component_subscribe($$self, MAP_STATE, $$value => $$invalidate(13, $MAP_STATE = $$value));
	const dispatch = createEventDispatcher();
	const { DEALIASED, RAW } = DEALIAS_OBJECT;
	const { READING, MODE3D, CROSS } = INT_OBJECT;
	const { HISTORICAL, LIVE } = MODE_OBJECT;
	let about = 0;
	let appearance = 0;
	let ws;
	let user;
	let confirm = false;

	function handleClick() {
		if ($MAP_STATE === SINGLE) {
			set_store_value(MAP_STATE, $MAP_STATE = DOUBLE, $MAP_STATE);
		} else if ($MAP_STATE === DOUBLE) {
			set_store_value(MAP_STATE, $MAP_STATE = SINGLE_HIDDEN, $MAP_STATE);
		} else if ($MAP_STATE === SINGLE_HIDDEN) {
			set_store_value(MAP_STATE, $MAP_STATE = DOUBLE, $MAP_STATE); //also need to update

			//dispatch message to update old map
			dispatch('message', { value: "activateMap", idx: 1 });
		}
	}

	function handle3d() {
		if ($currentFile === null || $scans[$currentFile] === undefined) {
			set_store_value(INT_STATE, $INT_STATE = READING, $INT_STATE);
			return;
		}

		set_store_value(INT_STATE, $INT_STATE = $INT_STATE === MODE3D ? READING : MODE3D, $INT_STATE);

		if ($INT_STATE === MODE3D) {
			//check if needs loading
			if ($texture_3d[$selections[0].file] === undefined) {
				const status = queue.enqueue({
					fileNum: $selections[0].file,
					field: 254,
					fileName: $selections[0].scan.fileName,
					idx: 255,
					selections: $selections,
					scans: $scans
				});

				set_store_value(LOADING_3D, $LOADING_3D = true, $LOADING_3D);
			} else {
				//dispatch event that will propagate to MenuCanvas to load texture
				dispatch('message', { value: "loadTexture" });
			}
		} else {
			//remove box and line
			dispatch('message', { value: 'removeBox' });

			dispatch('message', { value: 'removeLine' });
		}
	}

	function handleCross() {
		if ($currentFile === null || $scans[$currentFile] === undefined) {
			set_store_value(INT_STATE, $INT_STATE = READING, $INT_STATE);
			return;
		}

		set_store_value(INT_STATE, $INT_STATE = $INT_STATE === CROSS ? READING : CROSS, $INT_STATE);

		if ($INT_STATE === CROSS) {
			//check if needs loading
			if ($texture_3d[$selections[0].file] === undefined) {
				const status = queue.enqueue({
					fileNum: $selections[0].file,
					field: 254,
					fileName: $selections[0].scan.fileName,
					idx: 255,
					selections: $selections,
					scans: $scans
				});

				set_store_value(LOADING_3D, $LOADING_3D = true, $LOADING_3D);
			} else {
				//dispatch event that will propagate to MenuCanvas to load texture
				dispatch('message', { value: "loadTexture" });
			}
		} else {
			//remove line and box
			dispatch('message', { value: 'removeLine' });

			dispatch('message', { value: 'removeBox' });
		}
	}

	function handleDealias() {
		set_store_value(DEALIAS_STATE, $DEALIAS_STATE = $DEALIAS_STATE === DEALIASED ? RAW : DEALIASED, $DEALIAS_STATE);
		dispatch("message", { value: "setDealiasState" });
	}

	function handleAbout() {
		$$invalidate(1, about = about ? 0 : 1);
	}

	function handleAppearance() {
		$$invalidate(2, appearance = appearance ? 0 : 1);
	}

	async function handleMode() {
		if ($MODE_STATE === HISTORICAL) {
			//if not logged in, show modal and return
			if (!await auth.isAuthenticated()) {
				modal.update(d => {
					d.error = true;
					d.message = "An account is needed to view live radar. Create a free account at";
					d.header = "Not logged in";
					return d;
				});

				return;
			}
		}

		set_store_value(MODE_STATE, $MODE_STATE = $MODE_STATE === HISTORICAL ? LIVE : HISTORICAL, $MODE_STATE);

		if ($MODE_STATE === LIVE) {
			set_store_value(ALERTS, $ALERTS = true, $ALERTS);
			set_store_value(SITES, $SITES = true, $SITES);

			//$STATEMENTS = false;
			//ws.send('KJGX');
			if (socket.ws && socket.ws.isAlive) {
				console.log("alive");
			} else {
				socket.connect().then(function (ws) {
					dispatch("message", { value: "showSites" });
					dispatch("message", { value: "showAlerts" });
					dispatch("message", { value: "showStatements" });
					dispatch("message", { value: "showLightning" });
				}).catch(function (err) {
					console.log("ws connect failed", err);
				});
			}
		} else {
			set_store_value(ALERTS, $ALERTS = false, $ALERTS);

			//$SITES = false;
			set_store_value(STATEMENTS, $STATEMENTS = false, $STATEMENTS);

			set_store_value(LIGHTNING, $LIGHTNING = false, $LIGHTNING);
			socket.ws.close();
		}
	}

	function handleSites() {
		if ($SITES) {
			set_store_value(SITES, $SITES = false, $SITES);
		} else {
			set_store_value(SITES, $SITES = true, $SITES);
			dispatch("message", { value: "showSites" });
		}
	}

	function handleTrending() {
		if ($TRENDING) {
			set_store_value(TRENDING, $TRENDING = false, $TRENDING);
			trending.clear();
		} else {
			set_store_value(TRENDING, $TRENDING = true, $TRENDING);
			trending.start();
		}
	}

	function handleAlerts() {
		if ($ALERTS) {
			set_store_value(ALERTS, $ALERTS = false, $ALERTS);
		} else {
			set_store_value(ALERTS, $ALERTS = true, $ALERTS);
		}
	}

	function handleStatements() {
		if ($STATEMENTS) {
			set_store_value(STATEMENTS, $STATEMENTS = false, $STATEMENTS);
		} else {
			set_store_value(STATEMENTS, $STATEMENTS = true, $STATEMENTS);
		}
	}

	function handleLightning() {
		if ($LIGHTNING) {
			set_store_value(LIGHTNING, $LIGHTNING = false, $LIGHTNING);
		} else {
			set_store_value(LIGHTNING, $LIGHTNING = true, $LIGHTNING);
		}
	}

	async function handleLogout() {
		auth.logout();
	}

	function handleSettings() {
		if ($SETTINGS_STATE === "open") {
			set_store_value(SETTINGS_STATE, $SETTINGS_STATE = "closed", $SETTINGS_STATE);
			$$invalidate(4, confirm = false);
		} else {
			set_store_value(SETTINGS_STATE, $SETTINGS_STATE = "open", $SETTINGS_STATE);
		}
	}

	async function handleLogin() {
		const authenticated = await auth.isAuthenticated();

		if (!authenticated) {
			await auth.login();
		}
	}

	async function getUser() {
		if ($LOGGED_IN && auth) {
			$$invalidate(3, user = await auth.getUser());
		}
	}

	async function handleConfirm() {
		$$invalidate(4, confirm = true);
	}

	function handleCancel() {
		$$invalidate(4, confirm = false);
	}

	async function handleDelete() {
		const token = await auth.getToken();

		fetch(`https://radar.quadweather.com/socket/delete`, {
			headers: { Authorization: `Bearer ${token}` }
		}).then(d => d.json()).then(d => {
			if (d.message === "deleted") {
				handleLogout();
			}
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$LOGGED_IN*/ 1) {
			$: ($LOGGED_IN, getUser());
		}
	};

	return [
		$LOGGED_IN,
		about,
		appearance,
		user,
		confirm,
		$SETTINGS_STATE,
		$STATEMENTS,
		$ALERTS,
		$TRENDING,
		$SITES,
		$MODE_STATE,
		$DEALIAS_STATE,
		$INT_STATE,
		$MAP_STATE,
		DEALIASED,
		MODE3D,
		CROSS,
		LIVE,
		handleClick,
		handle3d,
		handleCross,
		handleDealias,
		handleAbout,
		handleAppearance,
		handleMode,
		handleSites,
		handleTrending,
		handleAlerts,
		handleStatements,
		handleLogout,
		handleSettings,
		handleLogin,
		handleConfirm,
		handleCancel,
		handleDelete
	];
}

class Bar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, null, [-1, -1]);
	}
}

export default Bar;