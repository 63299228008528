import './Menu.svelte.css';
/* src/Menu.svelte generated by Svelte v3.44.3 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	bubble,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_store_value,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import {
	INT_STATE,
	VIEW_STATE,
	MODE_STATE,
	socket,
	LOADING_LIVE
} from './store.js';

import FileDrop from './FileDrop.svelte.js';
import MenuCanvas from './MenuCanvas.svelte.js';
import FileView from './FileView.svelte.js';
import ElevationView from './ElevationView.svelte.js';
import { INT_OBJECT, VIEW_OBJECT, MODE_OBJECT } from './enums.js';

function create_if_block_2(ctx) {
	let div0;
	let t0;
	let div1;

	return {
		c() {
			div0 = element("div");
			t0 = space();
			div1 = element("div");
			div1.textContent = "Loading live";
			attr(div0, "class", "lds-dual-ring svelte-7qu9tz");
			attr(div1, "class", "of svelte-7qu9tz");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
		},
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
		}
	};
}

// (55:36) 
function create_if_block_1(ctx) {
	let fileview;
	let current;
	fileview = new FileView({});

	return {
		c() {
			create_component(fileview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(fileview, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(fileview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(fileview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(fileview, detaching);
		}
	};
}

// (53:4) {#if $VIEW_STATE === ELEVATION}
function create_if_block(ctx) {
	let elevationview;
	let current;
	let elevationview_props = {};
	elevationview = new ElevationView({ props: elevationview_props });
	/*elevationview_binding*/ ctx[17](elevationview);

	return {
		c() {
			create_component(elevationview.$$.fragment);
		},
		m(target, anchor) {
			mount_component(elevationview, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const elevationview_changes = {};
			elevationview.$set(elevationview_changes);
		},
		i(local) {
			if (current) return;
			transition_in(elevationview.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(elevationview.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			/*elevationview_binding*/ ctx[17](null);
			destroy_component(elevationview, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div5;
	let div3;
	let div0;
	let label0;
	let input0;
	let input0_value_value;
	let t0;
	let t1;
	let label1;
	let input1;
	let input1_value_value;
	let t2;
	let t3;
	let div2;
	let t4;
	let div1;
	let filedrop;
	let t5;
	let current_block_type_index;
	let if_block1;
	let t6;
	let div4;
	let menucanvas;
	let current;
	let mounted;
	let dispose;
	let if_block0 = /*$LOADING_LIVE*/ ctx[4] && create_if_block_2(ctx);
	filedrop = new FileDrop({});
	const if_block_creators = [create_if_block, create_if_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$VIEW_STATE*/ ctx[3] === /*ELEVATION*/ ctx[10]) return 0;
		if (/*$VIEW_STATE*/ ctx[3] === /*FILE*/ ctx[9]) return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	let menucanvas_props = {};
	menucanvas = new MenuCanvas({ props: menucanvas_props });
	/*menucanvas_binding*/ ctx[18](menucanvas);

	return {
		c() {
			div5 = element("div");
			div3 = element("div");
			div0 = element("div");
			label0 = element("label");
			input0 = element("input");
			t0 = text("\n                Elevation View");
			t1 = space();
			label1 = element("label");
			input1 = element("input");
			t2 = text("\n                    File View");
			t3 = space();
			div2 = element("div");
			if (if_block0) if_block0.c();
			t4 = space();
			div1 = element("div");
			create_component(filedrop.$$.fragment);
			t5 = space();
			if (if_block1) if_block1.c();
			t6 = space();
			div4 = element("div");
			create_component(menucanvas.$$.fragment);
			attr(input0, "type", "radio");
			input0.__value = input0_value_value = 1;
			input0.value = input0.__value;
			attr(input0, "class", "svelte-7qu9tz");
			/*$$binding_groups*/ ctx[15][0].push(input0);
			attr(label0, "class", "svelte-7qu9tz");
			attr(input1, "type", "radio");
			input1.__value = input1_value_value = 2;
			input1.value = input1.__value;
			attr(input1, "class", "svelte-7qu9tz");
			/*$$binding_groups*/ ctx[15][0].push(input1);
			attr(label1, "class", "svelte-7qu9tz");
			attr(div0, "class", "nested svelte-7qu9tz");
			attr(div1, "class", "fddiv svelte-7qu9tz");

			toggle_class(div1, "hidden", /*$MODE_STATE*/ ctx[5] === /*LIVE*/ ctx[11]
			? true
			: false);

			attr(div2, "class", "right-nested svelte-7qu9tz");
			attr(div3, "class", "radios svelte-7qu9tz");
			attr(div4, "class", "svelte-7qu9tz");

			toggle_class(div4, "hidden", /*$INT_STATE*/ ctx[6] === /*MODE3D*/ ctx[7] || /*$INT_STATE*/ ctx[6] === /*CROSS*/ ctx[8]
			? false
			: true);

			attr(div5, "class", "menu svelte-7qu9tz");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div3);
			append(div3, div0);
			append(div0, label0);
			append(label0, input0);
			input0.checked = input0.__value === /*viewState*/ ctx[2];
			append(label0, t0);
			append(div0, t1);
			append(div0, label1);
			append(label1, input1);
			input1.checked = input1.__value === /*viewState*/ ctx[2];
			append(label1, t2);
			append(div3, t3);
			append(div3, div2);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t4);
			append(div2, div1);
			mount_component(filedrop, div1, null);
			append(div5, t5);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(div5, null);
			}

			append(div5, t6);
			append(div5, div4);
			mount_component(menucanvas, div4, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input0, "keydown", prevent_default(/*keydown_handler*/ ctx[13])),
					listen(input0, "change", /*input0_change_handler*/ ctx[14]),
					listen(input1, "keydown", prevent_default(/*keydown_handler_1*/ ctx[12])),
					listen(input1, "change", /*input1_change_handler*/ ctx[16])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*viewState*/ 4) {
				input0.checked = input0.__value === /*viewState*/ ctx[2];
			}

			if (dirty & /*viewState*/ 4) {
				input1.checked = input1.__value === /*viewState*/ ctx[2];
			}

			if (/*$LOADING_LIVE*/ ctx[4]) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div2, t4);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*$MODE_STATE, LIVE*/ 2080) {
				toggle_class(div1, "hidden", /*$MODE_STATE*/ ctx[5] === /*LIVE*/ ctx[11]
				? true
				: false);
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block1) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block1 = if_blocks[current_block_type_index];

					if (!if_block1) {
						if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block1.c();
					} else {
						if_block1.p(ctx, dirty);
					}

					transition_in(if_block1, 1);
					if_block1.m(div5, t6);
				} else {
					if_block1 = null;
				}
			}

			const menucanvas_changes = {};
			menucanvas.$set(menucanvas_changes);

			if (dirty & /*$INT_STATE, MODE3D, CROSS*/ 448) {
				toggle_class(div4, "hidden", /*$INT_STATE*/ ctx[6] === /*MODE3D*/ ctx[7] || /*$INT_STATE*/ ctx[6] === /*CROSS*/ ctx[8]
				? false
				: true);
			}
		},
		i(local) {
			if (current) return;
			transition_in(filedrop.$$.fragment, local);
			transition_in(if_block1);
			transition_in(menucanvas.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(filedrop.$$.fragment, local);
			transition_out(if_block1);
			transition_out(menucanvas.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			/*$$binding_groups*/ ctx[15][0].splice(/*$$binding_groups*/ ctx[15][0].indexOf(input0), 1);
			/*$$binding_groups*/ ctx[15][0].splice(/*$$binding_groups*/ ctx[15][0].indexOf(input1), 1);
			if (if_block0) if_block0.d();
			destroy_component(filedrop);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d();
			}

			/*menucanvas_binding*/ ctx[18](null);
			destroy_component(menucanvas);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $VIEW_STATE;
	let $LOADING_LIVE;
	let $MODE_STATE;
	let $INT_STATE;
	component_subscribe($$self, VIEW_STATE, $$value => $$invalidate(3, $VIEW_STATE = $$value));
	component_subscribe($$self, LOADING_LIVE, $$value => $$invalidate(4, $LOADING_LIVE = $$value));
	component_subscribe($$self, MODE_STATE, $$value => $$invalidate(5, $MODE_STATE = $$value));
	component_subscribe($$self, INT_STATE, $$value => $$invalidate(6, $INT_STATE = $$value));
	const { MODE3D, CROSS } = INT_OBJECT;
	const { FILE, ELEVATION } = VIEW_OBJECT;
	const { HISTORICAL, LIVE } = MODE_OBJECT;
	let viewState = 1;
	let { canvas = undefined } = $$props;
	let { elevationFunctions = undefined } = $$props;
	const viewMap = { 1: ELEVATION, 2: FILE };
	const $$binding_groups = [[]];

	function keydown_handler_1(event) {
		bubble.call(this, $$self, event);
	}

	function keydown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function input0_change_handler() {
		viewState = this.__value;
		$$invalidate(2, viewState);
	}

	function input1_change_handler() {
		viewState = this.__value;
		$$invalidate(2, viewState);
	}

	function elevationview_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			elevationFunctions = $$value;
			$$invalidate(1, elevationFunctions);
		});
	}

	function menucanvas_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			canvas = $$value;
			$$invalidate(0, canvas);
		});
	}

	$$self.$$set = $$props => {
		if ('canvas' in $$props) $$invalidate(0, canvas = $$props.canvas);
		if ('elevationFunctions' in $$props) $$invalidate(1, elevationFunctions = $$props.elevationFunctions);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*viewState*/ 4) {
			$: set_store_value(VIEW_STATE, $VIEW_STATE = viewMap[viewState], $VIEW_STATE);
		}
	};

	return [
		canvas,
		elevationFunctions,
		viewState,
		$VIEW_STATE,
		$LOADING_LIVE,
		$MODE_STATE,
		$INT_STATE,
		MODE3D,
		CROSS,
		FILE,
		ELEVATION,
		LIVE,
		keydown_handler_1,
		keydown_handler,
		input0_change_handler,
		$$binding_groups,
		input1_change_handler,
		elevationview_binding,
		menucanvas_binding
	];
}

class Menu extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { canvas: 0, elevationFunctions: 1 });
	}
}

export default Menu;